import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ManageQuestion from "./Components/ManageQuestion";
import ModalEditQuestion from "./Components/ModalEditQuestion";
import { POST, ADDFAQTYPE, ADDFAQ, FAQBYID, DELETEFAQ, EDITFAQTYPE, EDITFAQ, DELETEFAQTYPE } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

class SettingQuestionMange extends Component {
     constructor(props) {
          super(props);
          this.state = {
               dataArray: [],
               index: 0,
               modalEdit: false,
               loading: false,
          };
     }
     componentDidMount() {
          if (this.props.match.params.id != undefined) {
               this.getFaqById();
          }
     }
     getFaqById = async () => {
          try {
               this.setState({ loading: true });
               let faqID = await POST(FAQBYID, { faq_id: this.props.match.params.id });
               if (faqID.success) {
                    let result = faqID.result;
                    let faq_question = JSON.parse(result.faq_question);
                    let faq_answer = JSON.parse(result.faq_answer);
                    this.setState({
                         loading: false,
                         faq_question_th: faq_question.th,
                         faq_question_en: faq_question.en,
                         faq_answer_th: faq_answer.th,
                         faq_answer_en: faq_answer.en,
                    });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               console.log(`error`, error);
               this.setState({ loading: false });
          }
     };
     onClickaddDataQuestion = () => {
          let { dataArray, index } = this.state;
          index++;
          dataArray.push({ question_en: "question topic", answer_en: "answer", question_th: "หัวข้อคำถาม", answer_th: "คำตอบ", id: index });
          this.setState({ index, dataArray });
     };
     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     openEdit = (id, question) => {
          let { dataArray } = this.state;
          if (question !== "หัวข้อคำถาม") {
               dataArray.map((el) => {
                    if (el.id === id) {
                         this.setState({ question_en: el.question_en, answer_en: el.answer_en, question_th: el.question_th, answer_th: el.answer_th });
                    }
               });
          }
          this.setState({ modalEdit: true, id: id });
     };
     deleteQuestion = async (id, faq_id) => {
          let { dataArray } = this.state;
          if (faq_id) {
               try {
                    let delete_faq = await POST(DELETEFAQ, { faq_id });
                    if (delete_faq.success) {
                         swal.fire({ title: "สำเร็จ", text: "ลบ Faq สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    } else {
                         swal.fire({ title: "แจ้งเตือน", text: delete_faq.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } catch (e) {
                    swal.fire({ title: "แจ้งเตือน", text: e, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          }
          dataArray.splice(
               dataArray.findIndex((el) => el.id === id),
               1
          );
          this.setState({ id: id, dataArray });
     };
     handleClose = () => {
          this.setState({ modalEdit: false, question_th: null, answer_th: null, question_en: null, answer_en: null });
     };
     onClickEditQuestion = () => {
          const { id, dataArray, question_en, answer_en, question_th, answer_th } = this.state;
          dataArray.find((el) => {
               if (el.id === id) {
                    el.question_en = question_en;
                    el.answer_en = answer_en;
                    el.question_th = question_th;
                    el.answer_th = answer_th;
               }
          });
          this.setState({ dataArray, modalEdit: false, question_en: null, answer_en: null, question_th: null, answer_th: null });
     };
     onClickAddQuestion = async () => {
          const { faq_question_th, faq_question_en, faq_answer_th, faq_answer_en } = this.state;
          try {
               if (faq_question_th && faq_question_en && faq_answer_th && faq_answer_en) {
                    const addFaq = await POST(ADDFAQ, {
                         faq_question_th,
                         faq_question_en,
                         faq_answer_th,
                         faq_answer_en,
                    });
                    if (addFaq.success) {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "เพิ่ม Faq สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         window.location.assign("/setting_question");
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: addFaq.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: "Please fill complete", icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickEditQuestionAPI = async () => {
          const { faq_question_th, faq_question_en, faq_answer_th, faq_answer_en } = this.state;
          try {
               if (faq_question_th && faq_question_en && faq_answer_th && faq_answer_en) {
                    let editfaq = await POST(EDITFAQ, {
                         faq_question_th,
                         faq_question_en,
                         faq_answer_th,
                         faq_answer_en,
                         faq_id: this.props.match.params.id,
                    });
                    if (editfaq.success) {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไข Faq สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         window.location.reload();
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: editfaq.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: "Please fill complete", icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickDeleteFAQ = async () => {
          const { faq_type_id } = this.state;
          try {
               this.setState({ loading: true });
               let delete_faq = await POST(DELETEFAQTYPE, { faq_type_id });
               if (delete_faq.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "ลบ Faq สำเร็จ", icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    window.location.assign("/setting_question");
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: delete_faq.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onChangeTexteditEN = (e) => {
          this.setState({ faq_answer_en: e });
     };
     onChangeTexteditTH = (e) => {
          this.setState({ faq_answer_th: e });
     };
     render() {
          const path = window.location.pathname;
          const { modalEdit, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <ManageQuestion
                         handleChangeText={this.handleChangeText}
                         state={this.state}
                         onClickadd={this.onClickaddDataQuestion}
                         openEdit={this.openEdit}
                         deleteQuestion={this.deleteQuestion}
                         onClickAddQuestion={this.onClickAddQuestion}
                         onClickEditQuestionAPI={this.onClickEditQuestionAPI}
                         onClickDeleteFAQ={this.onClickDeleteFAQ}
                         path={path}
                         onChangeTexteditEN={(e) => this.onChangeTexteditEN(e)}
                         onChangeTexteditTH={(e) => this.onChangeTexteditTH(e)}
                    />
                    <ModalEditQuestion
                         open={modalEdit}
                         handleClose={this.handleClose}
                         state={this.state}
                         handleChangeText={this.handleChangeText}
                         onClickEditQuestion={this.onClickEditQuestion}
                    />
               </Container>
          );
     }
}

export default withRouter(SettingQuestionMange);
