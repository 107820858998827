import { Card, CardContent, Grid, Button, Typography, Stack, Tooltip, Chip } from "@material-ui/core";
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';

const ListAccountType = (props) => {

     return props.data.map((el, i) => {
          return (
               <Card sx={{ maxWidth: "100%", marginBottom: 2, padding: 1 }}>
                    <Grid container direction="row">
                         {/* <Grid item xs={4} sx={{ justifyContent: "center", alignSelf: "center", padding: 2 }}>
                              <CardMedia component="img" height="100%" width="auto" image={el.img} alt={el.subject} />
                         </Grid> */}
                         <Grid item container xs={12} display="flex" alignItems="center">
                              <Grid item xs={12} sm={4}>
                                   <CardContent>
                                        <Grid container direction="row" xs={12}>
                                             <Grid item xs={12}>
                                                  <Typography variant="body1" color="text.primary">
                                                       {el.account_type_name} {el.is_cent ? "(CENT)" : ""}
                                                  </Typography>
                                                  {el.user_team?.map((item, index) => {
                                                       return (
                                                            el.expand === 0 ?
                                                                 (index < 2 &&
                                                                      <Stack my={1}>
                                                                           <Typography>
                                                                                • {`${item.name} ${item.lastname}`}
                                                                           </Typography>
                                                                           <Typography>
                                                                                &nbsp;({item.email})
                                                                           </Typography>
                                                                      </Stack>
                                                                 )
                                                                 :
                                                                 (
                                                                      <Stack my={1}>
                                                                           <Typography>
                                                                                • {`${item.name} ${item.lastname}`}
                                                                           </Typography>
                                                                           <Typography>
                                                                                &nbsp;({item.email})
                                                                           </Typography>
                                                                      </Stack>
                                                                 )
                                                       )
                                                  })}
                                                  {el.user_team?.length > 2 &&
                                                       <Chip
                                                            deleteIcon={<MoreHorizRoundedIcon />}
                                                            onClick={() => props.updateDataList(el.account_type_id, el.expand)}
                                                            onDelete={() => props.updateDataList(el.account_type_id, el.expand)}
                                                            label={el.expand === 0 ? "more" : "less"}
                                                       />
                                                  }
                                             </Grid>
                                        </Grid>
                                   </CardContent>
                              </Grid>
                              <Grid container item direction="row" justifyContent="flex-end" xs={12} sm={8}>
                                   <Button
                                        variant={el.status === 1 ? "contained" : "outlined"}
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={() => props.onClickUpdateStatus(el.account_type_id)}
                                   >
                                        {el.status === 1 ? "Disabled" : "Enabled"}
                                   </Button>
                                   <Button
                                        variant="contained"
                                        // color="error"
                                        style={{ backgroundColor: "#4589C6" }}
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={() => props.onClickEdit(el.account_type_id)}
                                   >
                                        Edit
                                   </Button>
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={() => props.onClickDelete(el.account_type_id)}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </Grid>
                    </Grid>
               </Card>
          );
     });
};

export default ListAccountType;
