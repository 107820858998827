import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ListPolicy from "./Components/ListPolicy";
import { POST, POLICYAPI, EDITPOLICY, GET, UPLOADPDFPOLICY } from "service";
import Loading from "components/Loading/Loading";
import swal from "sweetalert2";

class SettingPolicy extends Component {
     constructor(props) {
          super(props);
          this.state = {
               policy: [],
               loading: false,
               policy_en: "",
               policy_th: "",
               files: {},
          };
     }

     componentDidMount() {
          this.getPolicy();
     }

     async getPolicy() {
          try {
               this.setState({ loading: true });
               let policy = await GET(POLICYAPI);
               if (policy.success) {
                    const result = policy.result[0];
                    let policy_en = JSON.parse(result.policy).en;
                    let policy_th = JSON.parse(result.policy).th;
                    this.setState({
                         policy_en: policy_en,
                         policy_th: policy_th,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               console.log(error);
               this.setState({ loading: false });
          }
     }

     onChangeTexteditEN = (e) => {
          this.setState({ policy_en: e });
     };
     onChangeTexteditTH = (e) => {
          this.setState({ policy_th: e });
     };

     onClickSubmit = async () => {
          const { policy_en, policy_th, file_en, file_th, files } = this.state;
          // console.log("files", files);
          try {
               if (policy_en && policy_th) {
                    let edit_policy = await POST(EDITPOLICY, { policy_en, policy_th });
                    if (edit_policy.success) {
                         if (file_en || file_th) {
                              let formData = new FormData();
                              if (file_en) {
                                   formData.append("en", file_en);
                              }
                              if (file_th) {
                                   formData.append("th", file_th);
                              }

                              try {
                                   let upload_PDF = await POST(UPLOADPDFPOLICY, formData, true);
                                   if (upload_PDF.success) {
                                        this.setState({ loading: false });
                                        swal.fire({ title: "สำเร็จ", text: "แก้ไข Policy สำเร็จ", icon: "success", 
                                        // timer: 6000, 
                                        showConfirmButton: true });
                                        this.getPolicy();
                                   } else {
                                        this.setState({ loading: false });
                                        swal.fire({ title: "แจ้งเตือน", text: upload_PDF.message, icon: "warning", 
                                        // timer: 6000, 
                                        showConfirmButton: true });
                                   }
                              } catch (error) {
                                   this.setState({ loading: false });
                                   swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
                                   // timer: 6000, 
                                   showConfirmButton: true });
                              }
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "แจ้งเตือน", text: "กรุณาอัพโหลดเอกสาร", icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: edit_policy.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    swal.fire({ title: "แจ้งเตือน", text: "กรุณากรอกให้ครบ", icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onChangePDFEN = (e) => {
          let { files } = this.state;
          let result = e.target.files[0];
          files.en = result;
          this.setState({ file_name_en: result.name, file_en: result, files });
     };

     onChangePDFTH = (e) => {
          let { files } = this.state;
          let result = e.target.files[0];
          files.th = result;
          this.setState({ file_name_th: result.name, file_th: result, files });
     };

     render() {
          const { policy, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <ListPolicy
                         data={policy}
                         onClickEdit={this.onClickEdit}
                         state={this.state}
                         onChangeTexteditEN={this.onChangeTexteditEN}
                         onChangeTexteditTH={this.onChangeTexteditTH}
                         onClickSubmit={this.onClickSubmit}
                         onChangePDFEN={this.onChangePDFEN}
                         onChangePDFTH={this.onChangePDFTH}
                    />
               </Container>
          );
     }
}

export default withRouter(SettingPolicy);
