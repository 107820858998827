import React from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";

const DetailInbox = (props) => {
     //  const classes = useStyles();
     const { data, language, page, handleChangePage, handleSearch, state, submitSearch } = props;
     const count = Math.ceil(data.length > 0 && data.length / 10);
     const setPage = page - 1;
     const dataList = data.slice(setPage * 10, page * 10);
     return (
          <Grid container rowSpacing={3} justifyContent="center" alignItems="center">
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Inbox Message</Typography>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                         <Grid container sx={{ width: "40vw", mr: 2 }}>
                              <GridTextField
                                   data={textFiled.search_inbox}
                                   state={state}
                                   handleChange={handleSearch}
                                   onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                             submitSearch();
                                        }
                                   }}
                              />
                         </Grid>
                         <Button
                              variant="contained"
                              // color="error"
                              style={{ backgroundColor: "#4589C6" }} 
                              //  className="btn-style"
                              sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                              onClick={submitSearch}
                         >
                              {language === "th" ? "ค้นหา" : "Search"}
                         </Button>
                    </Stack>
               </Grid>
               <Grid item xs={12} container columnSpacing={2}>
                    <Grid item xs="auto">
                         <FormControlLabel control={<Checkbox defaultChecked />} label="all" />
                    </Grid>
                    <Grid item xs="auto">
                         <FormControlLabel control={<Checkbox />} label="Transaction" />
                    </Grid>
                    <Grid item xs="auto">
                         <FormControlLabel control={<Checkbox />} label="Promotion" />
                    </Grid>
                    <Grid item xs="auto">
                         <FormControlLabel control={<Checkbox />} label="Seminar Course" />
                    </Grid>
                    <Grid item xs="auto">
                         <FormControlLabel control={<Checkbox />} label="Unread" />
                    </Grid>
                    <Grid item xs="auto">
                         <FormControlLabel control={<Checkbox />} label="Read" />
                    </Grid>
               </Grid>
               <Grid item xs={12}>
                    <GridTable head={headerTable.inbox} body={dataList} count={count} page={page} handleChangePage={handleChangePage} />
               </Grid>
          </Grid>
     );
};
// const useStyles = makeStyles((theme) => ({
//      imgStyle: {
//           height: 230,
//           [theme.breakpoints.up("sm")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//           [theme.breakpoints.up("md")]: {
//                objectFit: "cover",
//                height: 340,
//           },
//           [theme.breakpoints.up("lg")]: {
//                objectFit: "contain",
//                height: 340,
//           },
//      },
//      borderSolid: {
//           width: "100%",
//           border: "solid 1px #fc0316",
//           background: "#fc0316",
//           [theme.breakpoints.up("md")]: {
//                width: 100,
//           },
//      },
//      fontStyleTitle: {
//           fontSize: 24,
//           color: "#000",
//           marginTop: 15,
//           fontWeight: 600,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                marginTop: 0,
//           },
//      },
//      fontStyleDetail: {
//           fontSize: 13,
//           color: "#000",
//           textAlign: "justify",
//           marginTop: 24,
//           paddingLeft: 16,
//           paddingRight: 16,
//           [theme.breakpoints.up("md")]: {
//                paddingLeft: 0,
//                paddingRight: 0,
//                fontSize: 18,
//           },
//      },
//      btnStyle: {
//           textAlign: "right",
//           marginTop: 3,
//           [theme.breakpoints.up("md")]: {
//                marginTop: 16,
//                textAlign: "left",
//           },
//      },
// }));
export default withRouter(DetailInbox);
