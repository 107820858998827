import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import Sidebar from "pages/Setting/Components/Sidebar";
import { Editor } from "react-draft-wysiwyg";

const EditEmail = (props) => {

    const {
        data_select,
        select_type,
        editorState_header,
        editorState_footer,
        select_img,
        handleChangeSelect,
        handleChangeEditorHeader,
        handleChangeEditorFooter,
        onImageChange,
        onLoadImageChange,
        handleUpdateEmail,
    } = props;

    const classes = useStyles();

    return (
        <Grid container spacing={2} width={"100%"}>
            <Grid item xs={3}>
                <Sidebar title={"Edit email"} pageName={"EditEmail"} />
            </Grid>
            <Grid container spacing={2} item xs={9} alignContent={"start"}>
                <Grid item xs={12}>
                    <Grid >
                        <HeaderTypography title="Edit email" />
                    </Grid>
                    <Grid container spacing={5} >
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel >เลือกประเภท</InputLabel>
                                <Select
                                    id="type"
                                    value={select_type}
                                    label="เลือกชื่อ IB"
                                    onChange={handleChangeSelect}
                                >
                                    <MenuItem value="" disabled>เลือกประเภท E-mail</MenuItem>
                                    {data_select.map((e, i) =>
                                        <MenuItem key={i} value={e.id} >{e.type}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container mb={2}>
                                <Grid item xs={6} display="flex" justifyContent="flex-start" alignItems="center">
                                    <Typography className={classes.fontStyleTitle}>Header E-mail</Typography>
                                </Grid>
                                <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                                    <Stack spacing={3} display="flex" justifyContent="center" alignItems="center">
                                        <label htmlFor="file-upload-header" >
                                            <Box className="box-add-img">
                                                เพิ่มรูปภาพ
                                                {select_img &&
                                                    <img
                                                        onLoad={onLoadImageChange}
                                                        src={select_img}
                                                        width="230px"
                                                        alt="อัพโหลดไฟล์"
                                                        style={{ display: "none" }}
                                                    />
                                                }
                                            </Box>
                                        </label>
                                        <input id="file-upload-header" type="file" accept="image/*" onChange={(e) => onImageChange(e, 'editorState_header')} />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Box className={classes.boxEditor}>
                                <Editor
                                    defaultEditorState={editorState_header}
                                    editorState={editorState_header}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={handleChangeEditorHeader}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container mb={2}>
                                <Grid item xs={6} display="flex" justifyContent="flex-start" alignItems="center">
                                    <Typography className={classes.fontStyleTitle}>Footer E-mail</Typography>
                                </Grid>
                                <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                                    <Stack spacing={3} display="flex" justifyContent="center" alignItems="center">
                                        <label htmlFor="file-upload-footer" >
                                            <Box className="box-add-img">
                                                เพิ่มรูปภาพ
                                                {select_img &&
                                                    <img
                                                        onLoad={onLoadImageChange}
                                                        src={select_img}
                                                        width="230px"
                                                        alt="อัพโหลดไฟล์"
                                                        style={{ display: "none" }}
                                                    />
                                                }
                                            </Box>
                                        </label>
                                        <input id="file-upload-footer" type="file" accept="image/*" onChange={(e) => onImageChange(e, 'editorState_footer')} />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Box className={classes.boxEditor}>
                                <Editor
                                    defaultEditorState={editorState_footer}
                                    editorState={editorState_footer}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={handleChangeEditorFooter}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                            <Button variant="outlined" onClick={handleUpdateEmail}>
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    fontStyleTitle: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#000",
        marginBottom: 2,
    },
    boxEditor: {
        borderStyle: "solid",
        borderColor: "#EFEFEF"
    }
}));

export default EditEmail;
