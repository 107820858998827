import { Button, Grid, Typography } from "@material-ui/core";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import { headerTable } from "functions/Static";
import RebateTable from "./RebateTable";
import { POST, ACCOUNTTYPEAPI } from "service";
import { useEffect,useState } from "react";

// const head = [{ label: "Type" }, { label: "Forex" }, { label: "Gold" }, { label: "Crypto" }, { label: "Index" }, { label: "Oil" }];
const head = [{ label: "Forex" ,color:"#4C8EC9" }, { label: "Gold" ,color:"#4589C6" }, { label: "Crypto",color:"#4C8EC9" }, { label: "Index",color:"#4589C6" }, { label: "Oil",color:"#4C8EC9" }];

const RebateCard = (props) => {
     const { state, handleChangeTextRebate, onClickAddRebate, onClickSaveRebate } = props;
     // console.log("userID",props.userId);
     useEffect(() => {
          getAccountType()
     },[]);

     const [accountType, setAccountType] = useState()
     
     // console.log("ssssss",state.rebate);
     const getAccountType = async () => {
          try {
               let account = await POST(ACCOUNTTYPEAPI, {});
               // let account = await POST(ACCOUNTTYPEAPI, {user_id:props.userId});
               if (account.success) {
                    setAccountType({accountType:account.result})
               } else {
                    // this.setState({ loading: false });
               }
          } catch (error) {
               // this.setState({ loading: false });
               console.log(`error`, error);
          }
     }
     // console.log("accountType",accountType);
     return (
          <Grid container justifyContent={"center"} spacing={2}>
               {state.rebate.map((el, i) => (
                    <>
                         <Grid item container justifyContent={"space-between"}>
                              <Typography variant="h6">
                                   {el.name} {el.lastname}
                              </Typography>
                              {/* <Button
                                   variant="contained"
                                   // color="error"
                                   style={{ backgroundColor: "#4589C6" }}
                                   sx={{ borderRadius: 5, width: 100, fontSize: 16 }}
                                   onClick={() => onClickAddRebate(el.user_id)}
                              >
                                   Add
                              </Button> */}
                         </Grid>
                         <Grid item xs={12} key={i}>
                              {/* <RebateTable data={el.rebate} head={head} state={state} handleChangeTextRebate={handleChangeTextRebate} user_id={el.user_id} accountType={accountType} /> */}
                         </Grid>
                         {/* <Button
                              variant="contained"
                              // color="error"
                              style={{ backgroundColor: "#4589C6" }}
                              sx={{ borderRadius: 5, width: 100, fontSize: 16 }}
                              onClick={() => onClickSaveRebate(el)}
                         >
                              Save
                         </Button> */}
                    </>
               ))}
          </Grid>
     );
};
export default RebateCard;

