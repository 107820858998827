import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination, Link, CardMedia, Button, Box } from "@material-ui/core";
// import moment from "moment";
import { withRouter } from "react-router-dom";
import logo from "../../../assets/images/what.png";
import ModalApproveWithdraw from "./ModalApproveWithdraw";
import moment from "moment";

const GridTable = (props) => {
     const classes = useStyles();
     const { language, onChange, stateFinance, select_withdraw, setValue } = props;
     //-------------------------------------------------//
     const [state, setState] = React.useState({
          open: false,
          data_modal: {},
     });
     const handleOpen = (data) => {
          setState({ ...state, open: true, data_modal: data });
          if (data.bank_path) {
               props.getImageBank(data.bank_path);
          }
          if (data.status === 2) {
               if (data.withdraw_type === 3) {
                    setValue({ withdraw_type: data.withdraw_type });
                    select_withdraw[0].disabled = true;
               } else {
                    setValue({ withdraw_type: data.withdraw_type });
                    select_withdraw[0].disabled = false;
               }
          } else {
               select_withdraw[0].disabled = true;
          }
     };
     const handleClose = () => {
          setState({ ...state, open: false });
          stateFinance.note_withdraw = null;
          state.withdraw_type = "null";
     };

     const role = localStorage.getItem("role");

     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.head.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.transaction_id}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.name} {el.lastname}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.email}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {moment(el.created_at).format("DD/MM/YYYY")}
                                                  </Typography>
                                             </TableCell>
                                             {/* <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.ip}
                                                  </Typography>
                                             </TableCell> */}
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       $ {el.money}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.withdraw_type === 1 ? '฿' : el.withdraw_type === 3 ? '$' : '฿'} {Math.round(Number(el.receive) * 100) / 100}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  {el.status === 1 ?
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.withdraw_type === 1 ? "ผ่าน Gateway" : el.withdraw_type === 2 ? "โอนเอง" : "USDT"}
                                                       </Typography>
                                                       :
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.withdraw_type === 1 ? 'ผ่าน Gateway' : el.withdraw_type === 3 ? 'USDT' : '-'}
                                                       </Typography>
                                                  }
                                             </TableCell>
                                             <TableCell>
                                                  {/* <Button
                                                       variant="contained"
                                                       noWrap
                                                       sx={{
                                                            maxWidth: 80,
                                                            width: "100%",
                                                            borderRadius: 2,
                                                            height: 30,
                                                            fontSize: 12,
                                                            backgroundColor: el.status === 1 ? "#5CE153" : el.status === 2 ? "#FF9747" : "#FC0316",
                                                       }}
                                                  >
                                                       {el.status == 1 ? "Confirm" : el.status == 2 ? "Pending" : el.status == 3 ? "Reject" : "error"}
                                                  </Button> */}
                                                  <Box
                                                       className={classes.box}
                                                       sx={{
                                                            backgroundColor: el.status === 1 ? "#5CE153" : el.status === 2 ? "#FF9747" : "#FC0316",
                                                       }}
                                                  >
                                                       {el.status == 1 ? "Confirm" : el.status == 2 ? "Pending" : el.status == 3 ? "Reject" : "error"}
                                                  </Box>
                                             </TableCell>
                                             {role === "admin" && (
                                                  <TableCell align="center">
                                                       <Link
                                                            component="button"
                                                            sx={{ fontSize: 16, color: "#184B8E" }}
                                                            onClick={() => {
                                                                 handleOpen(el);
                                                            }}
                                                       >
                                                            View slip
                                                       </Link>
                                                  </TableCell>
                                             )}

                                             {/* <TableCell align="center">
                                                  <Link
                                                       component="button"
                                                       sx={{ fontSize: 16, color: "#FF0000" }}
                                                       onClick={() => {
                                                            // openEdit(i, ind);
                                                       }}
                                                  >
                                                       Delete
                                                  </Link>
                                             </TableCell> */}
                                             {/* <TableCell align="center">
                                                  <CardMedia component="img" sx={{ height: 15, width: 15 }} alt="What" image={logo} />
                                             </TableCell> */}
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
               <ModalApproveWithdraw
                    open={state.open}
                    handleClose={handleClose}
                    language={language}
                    data={state.data_modal}
                    state={stateFinance}
                    approveRejectWithdraw={props.approveRejectWithdraw}
                    onChange={onChange}
                    note_withdraw={stateFinance.note_withdraw}
                    stateFinance={stateFinance}
                    select_withdraw={select_withdraw}
               />
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     box: {
          maxWidth: 80,
          width: "100%",
          border: 1,
          borderRadius: "8px",
          height: 30,
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          textTransform: "uppercase",
          boxShadow: "0 3px 2px -2px gray",
          padding: "2px",
     },
});
export default withRouter(GridTable);
