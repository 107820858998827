import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FAQAPI, POST } from "service";
import ListQuestion from "./Components/ListQuestion";

class SettingQuestion extends Component {
     constructor(props) {
          super(props);
          this.state = {
               faq: [],
               loading: false,
          };
     }

     componentDidMount() {
          this.getFAQ();
     }

     async getFAQ() {
          try {
               this.setState({ loading: true });
               let faq = await POST(FAQAPI, {});
               if (faq.success) {
                    this.setState({
                         faq: faq.result,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     }
     onClickEdit = (id) => {
          this.props.history.push(`/setting_manage_question/edit/${id}`);
     };
     onClickDelete = (id) => {
          console.log(`delete id:`, id);
     };
     render() {
          return (
               <Container maxWidth="xl">
                    <Loading open={this.state.loading} />
                    <ListQuestion data={this.state.faq} onClickEdit={this.onClickEdit} onClickDelete={this.onClickDelete} />
               </Container>
          );
     }
}

export default withRouter(SettingQuestion);
