import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import { withRouter } from "react-router-dom";
import TableWalletControl from "./Components/TableWalletControl";
import SearchTable from "./Components/SearchTable";
import { GET, WALLETUSER } from "service";
import Sidebar from "./Components/Sidebar";
import Loading from "components/Loading/Loading";

const dataTable = [
     {
          id: 1,
          date: "2021-03-25 20:49:33",
          deposit: 10000,
          create_by: "Name Lastname",
     },
     {
          id: 2,
          date: "2021-03-25 20:49:33",
          deposit: 10000,
          create_by: "Name Lastname",
     },
     {
          id: 3,
          date: "2021-03-25 20:49:33",
          deposit: 10000,
          create_by: "Name Lastname",
     },
     {
          id: 4,
          date: "2021-03-25 20:49:33",
          deposit: 10000,
          create_by: "Name Lastname",
     },
     {
          id: 5,
          date: "2021-03-25 20:49:33",
          deposit: 10000,
          create_by: "Name Lastname",
     },
     {
          id: 6,
          date: "2021-03-25 20:49:33",
          deposit: 10000,
          create_by: "Name Lastname",
     },
];

class SettingWalletControl extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               page: 1,
               search_text: "",
               wallet: [],
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getWalletUser();
     };
     getWalletUser = async () => {
          try {
               this.setState({ loading: true });
               let wallet = await GET(WALLETUSER);
               if (wallet.success) {
                    this.setState({ wallet: wallet.result, data: wallet.result, loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
          }
     };
     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };
     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     submitSearch = () => {
          let { search_text, wallet } = this.state;
          let data = wallet.filter(
               (el) =>
                    (search_text ? `${el.name} ${el.lastname}`.toString().toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.name.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.lastname.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.email.toLowerCase().includes(search_text.toLowerCase()) : el)
          );
          this.setState({ data, page: 1 });
     };
     render() {
          const language = localStorage.getItem("language");
          const { page, data, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title={"Menu"} pageName={"Wallet Control"} />
                         </Grid>
                         <Grid item xs={9}>
                              <HeaderTypography title={"Transaction Wallet Control"} />
                              <SearchTable
                                   language={language}
                                   data={data}
                                   handleSearch={this.handleSearch}
                                   state={this.state}
                                   submitSearch={this.submitSearch}
                              />
                              <TableWalletControl data={data} handleChangePage={this.handleChangePage} page={page} />
                         </Grid>
                    </Grid>
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(SettingWalletControl);
