import { Button, Grid } from "@material-ui/core";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";

const ResetPassCustomerCard = (props) => {
     const { state, handleChange, onClickResetPassword } = props;
     return (
          <Grid container spacing={2} justifyContent={"center"}>
               <Grid item xs={6} container spacing={2}>
                    <GridTextField data={textFiled.reset_password} state={state} handleChange={handleChange} />
               </Grid>
               <Grid item container justifyContent={"center"} mt={5}>
                    <Button
                         variant="contained"
                         style={{backgroundColor:"#4589C6"}}
                         sx={{ maxWidth: "200px", fontSize: 12, width: "100%", borderRadius: 5, height: 40 }}
                         component="span"
                         onClick={onClickResetPassword}
                    >
                         Submit
                    </Button>
               </Grid>
          </Grid>
     );
};
export default ResetPassCustomerCard;
