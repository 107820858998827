import { Button, Container, Grid, InputAdornment, Switch, TextField, Typography } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import Sidebar from "./Components/Sidebar";
import { CONFIGWITHDRAWAUTO, GET, GETWITHDRAWAUTO, POST } from "service";
import Swal from "sweetalert2";

class SetupAutoWithdraw extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            money: null,
            time: null,
            status: false,
            errorTime: false,
            token_line: null,
        };
    }
    componentDidMount = () => {
        this.getWithdrawAuto();
    };

    getWithdrawAuto = async () => {
        try {
            this.setState({ loading: true });
            let withdraw = await GET(GETWITHDRAWAUTO);
            if (withdraw.success) {
                let { money, time, status, token_line } = withdraw.result;
                this.setState({ money, time, status, token_line });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            console.log(`error`, error);
        }
    };


    submitConfig = async () => {
        const { money, time, status, errorTime, token_line } = this.state;
        try {
            if (!errorTime) {
                this.setState({ loading: true });
                let res = await POST(CONFIGWITHDRAWAUTO, { money, time, status, token_line });
                if (res.success) {
                    this.setState({ loading: false });
                    Swal.fire({
                        title: "สำเร็จ", text: "แก้ไขสำเร็จ", icon: "success",
                        // timer: 6000, 
                        showConfirmButton: true
                    });
                    this.getWithdrawAuto();
                } else {
                    this.setState({ loading: false });
                    Swal.fire({
                        title: "ไม่สำเร็จ", text: res.message, icon: "error",
                        // timer: 6000, 
                        showConfirmButton: true
                    });
                }
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    title: "ไม่สำเร็จ", text: "กรุณาระบุเวลาภายใน 60 นาที", icon: "error",
                    // timer: 6000, 
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            Swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "error",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    handleChangeText = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'time') {
            const maxLength = 2;
            const regex = /(?<![.\d])\d+(?![.\d])/;
            value = e.target.value.replace(/[^0-9]/g, '').substring(0, maxLength);
            if (value !== undefined && value.match(regex) && value > 0 && value <= 60) {
                this.setState({ errorTime: false })
                return value;
            } else {
                this.setState({ errorTime: true })
            }
        }
        return value;
    }

    render() {
        const language = localStorage.getItem("language");
        const { loading, money, time, status, errorTime, token_line } = this.state;
        return (
            <Container maxWidth="xl">
                <Loading open={loading} />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Sidebar title="Setup Auto Withdraw" pageName="SetupAutoWithdraw" />
                    </Grid>
                    <Grid item xs={9}>
                        <HeaderTypography title={"Setup Auto Withdraw"} />

                        <Grid item md={12} xs={12} container spacing={2} alignContent="flex-start">
                            <Grid item container xs={10} spacing={2}>
                                <Grid item className="typography-profile pt-0" xs={6}>
                                    <Typography>{language === "th" ? "เวลา" : "Time"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">นาที</InputAdornment>,
                                        }}
                                        fullWidth
                                        size="small"
                                        type="text"
                                        placeholder="0"
                                        name="time"
                                        error={errorTime}
                                        onChange={(e) => {
                                            this.setState({ time: this.handleChangeText(e) });
                                        }}
                                        value={time}
                                    />
                                </Grid>

                                <Grid item className="typography-profile pt-0" xs={6}>
                                    <Typography>{language === "th" ? "ยอดไม่เกิน" : "Not exceeding"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                        }}
                                        fullWidth
                                        size="small"
                                        type="number"
                                        placeholder="0.00"
                                        name="money"
                                        onChange={(e) => {
                                            this.setState({ money: e.target.value });
                                        }}
                                        value={money}
                                    />
                                </Grid>

                                <Grid item className="typography-profile pt-0" xs={6}>
                                    <Typography>{language === "th" ? "เปิดใช้งาน" : "Open"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Switch
                                        checked={status}
                                        onChange={(e) => {
                                            this.setState({ status: !status });
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>

                                <Grid item className="typography-profile pt-0" xs={6}>
                                    <Typography>Token Line</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        placeholder="Token Line"
                                        name="token_line"
                                        onChange={(e) => {
                                            this.setState({ token_line: e.target.value });
                                        }}
                                        value={token_line}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container justifyContent="center" alignContent="flex-end" marginTop={3}>
                                <Button variant="contained" style={{ backgroundColor: "#4589C6" }} className="btn-style" sx={{ maxWidth: 260 }} onClick={this.submitConfig}>
                                    {language === "th" ? "บันทึก" : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withRouter(SetupAutoWithdraw);
