import React from "react";
import { Typography, Grid, TextField, MenuItem, TextareaAutosize, CardMedia } from "@material-ui/core";
import UploadBtn from "../../components/UploadBtn/UploadBtn";
import { Image } from "@material-ui/icons";
const GridTextField = (props) => {
     return props.data.map((e, i) => (
          <Grid
               key={i}
               item
               xs={e.xs || false}
               sm={e.sm || false}
               md={e.md || false}
               className={e.className ? (e.className === props.state[e.name] ? e.classNameGrid + " fonts-color" : e.classNameGrid) : e.classNameGrid || ""}
               container
          >
               {e.title && e.typeincon !== "image" && (
                    <Grid item sx={{ alignSelf: "left" }}>
                         <Typography fontSize={{ xs: 14, sm: 16, md: 16 }} xs={12}>{e.title}</Typography>
                    </Grid>
               )}
               <Grid item container justifyContent="center" alignItems="center">
                    {e.typography && (
                         <Grid item sm={4} textAlign="left" sx={{ display: { xs: "none", sm: "block" } }}>
                              <Typography>{e.typography || ""}</Typography>
                         </Grid>
                    )}
                    {e.type === "textarea" ? (
                         <Grid item xs={12}>
                              <TextareaAutosize
                                   minRows={e.minRows || 5}
                                   name={e.name || ""}
                                   value={props.state[e.name] || ""}
                                   onChange={props.handleChange}
                                   placeholder={e.placeholder ? e.placeholder : ""}
                                   style={{
                                        borderColor: props.check && props.check[e.name] && props.check[e.name].err ? "red" : "transparent",
                                   }}
                              />
                         </Grid>
                    ) : e.typeincon === "image" ? (
                         <Grid container item xs={12} sm={e.typography ? 8 : false} textAlign="center" direction="row" alignItems="center" spacing={2}>
                              <Grid item xs={3}>
                                   {props.image ? (
                                        <CardMedia component={"img"} height={"auto"} width="100%" image={props.image} alt={e.name} />
                                   ) : (
                                        <Image style={{ fontSize: 100 }} />
                                   )}
                              </Grid>
                              <Grid item xs={7} textAlign="left">
                                   <Grid item>
                                        <Typography xs={12}>{e.title}</Typography>
                                   </Grid>
                                   <TextField
                                        // disabled={e.disable ? e.disable : props.disabled}
                                        error={props.check && props.check[e.name] ? props.check[e.name].err : false}
                                        helperText={props.check && props.check[e.name] && props.check[e.name].err ? props.check[e.name].label : ""}
                                        placeholder={e.placeholder ? e.placeholder : ""}
                                        className={e.classNameText || ""}
                                        label={e.label || ""}
                                        name={e.name || ""}
                                        type={e.type || ""}
                                        value={props.state[e.name] || ""}
                                        onChange={props.handleChange}
                                        select={e.select}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onKeyDown={props.onKeyDown}
                                        autoComplete="off"
                                        InputProps={
                                             e.icon
                                                  ? {
                                                       startAdornment: (
                                                            <CardMedia
                                                                 sx={{ height: 20, width: "auto", objectFit: "contain", mr: 2, mt: -0.25 }}
                                                                 component="img"
                                                                 alt="Logo"
                                                                 image={e.icon}
                                                            />
                                                       ),
                                                  }
                                                  : {}
                                        }
                                   />
                              </Grid>
                              <Grid item xs={2} marginLeft={-10} marginBottom={-3}>
                                   <UploadBtn onChange={(event) => props.onChangeFile(event)} />
                              </Grid>
                         </Grid>
                    ) : (
                         <Grid item xs={12} sm={e.typography ? 8 : false}>
                              <TextField
                                   disabled={e.disabled ? e.disabled : false}
                                   error={props.check && props.check[e.name] ? props.check[e.name].err : false}
                                   helperText={props.check && props.check[e.name] && props.check[e.name].err ? props.check[e.name].label : ""}
                                   placeholder={e.placeholder ? e.placeholder : ""}
                                   className={e.classNameText || ""}
                                   label={e.label || ""}
                                   name={e.name || ""}
                                   type={e.type || ""}
                                   value={props.state[e.name] || ""}
                                   onChange={props.handleChange}
                                   select={e.select}
                                   fullWidth
                                   variant="outlined"
                                   size="Normal"
                                   onKeyDown={props.onKeyDown}
                                   autoComplete="off"
                                   InputProps={
                                        e.icon
                                             ? {
                                                  startAdornment: (
                                                       <CardMedia
                                                            sx={{ height: 20, width: "auto", objectFit: "contain", mr: 2, mt: -0.25 }}
                                                            component="img"
                                                            alt="Logo"
                                                            image={e.icon}
                                                       />
                                                  ),
                                             }
                                             : {}
                                   }
                              >
                                   {e.select &&
                                        props[e.selectname].map((el) => (
                                             <MenuItem key={el.value} value={el.value} hidden={el.hidden ? el.hidden : false} disabled={el.disabled}>
                                                  {el.label}
                                             </MenuItem>
                                        ))}
                              </TextField>
                         </Grid>
                    )}
               </Grid>
          </Grid>
     ));
};

export default GridTextField;
