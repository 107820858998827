import React, { forwardRef } from "react";
import { NavLink as RoutLink } from "react-router-dom";
import { Grid, Typography, Link, AccordionDetails } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
// import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import HeaderTypography from "../../../components/HeaderTypography/HeaderTypography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StringSet } from "functions/Static";

const CustomRouterLink = forwardRef((props, ref) => (
     <Grid ref={ref} style={{ flexGrow: 1 }}>
          <RoutLink {...props} />
     </Grid>
));

const Accordion = withStyles({
     root: {
          boxShadow: "none",
          "&:not(:last-child)": {
               borderBottom: 0,
          },
          "&:before": {
               display: "none",
          },
          "&$expanded": {
               margin: "auto",
          },
     },
     expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
     root: {
          borderBottom: "solid 1px #EBEBEB",
          minHeight: 56,
          "&$expanded": {
               minHeight: 56,
          },
     },
     content: {
          "&$expanded": {
               margin: "12px 0",
          },
          justifyContent: "space-between",
     },
     expanded: {},
})(MuiAccordionSummary);

// const AccordionDetails = withStyles((theme) => ({
//      root: {
//           padding: theme.spacing(2),
//      },
// }))(MuiAccordionDetails);

const Sidebar = (props) => {
     const { title, data } = props;
     const [expanded, setExpanded] = React.useState("");

     const path = window.location.pathname;
     const handleChange = (panel, link) => (event, newExpanded) => {
          setExpanded(newExpanded ? panel : false);
          if (props.onChangePath) {
               props.onChangePath(link);
          }
     };
     return (
          <Grid>
               <HeaderTypography title={"Manu"} />
               {StringSet.sideBar.map((el, i) => {
                    return (
                         <>
                              <Accordion square expanded={expanded === `panel${i + 1}`} onChange={handleChange(`panel${i + 1}`, el.link)}>
                                   <Link className="link-head-minor-setting" component={CustomRouterLink} to={el.link}>
                                        <AccordionSummary expandIcon={el.submanu_title ? <ExpandMoreIcon /> : null} aria-controls="panel1a-content" id="panel1a-header">
                                             <Typography fontSize={path === el.link ? { xs: 14, sm: 18, md: 18 } : { xs: 14, sm: 18, md: 18 }} sx={path === el.link ? { fontWeight: 600, color: "#000" } : { color: "#000" }}>
                                                  {el.manuTitle}
                                             </Typography>
                                        </AccordionSummary>
                                   </Link>
                                   {el.submanu_title && (
                                        <Link className="link-head-minor-setting" component={CustomRouterLink} to={el.sub_link}>
                                             <AccordionDetails expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                  <Typography fontSize={path === el.link ? { xs: 14, sm: 18, md: 18 } : { xs: 14, sm: 18, md: 18 }} sx={path === el.sub_link ? { fontWeight: 600, color: "#000" } : { color: "#000" }}>
                                                       {el.submanu_title}
                                                  </Typography>
                                             </AccordionDetails>
                                        </Link>
                                   )}
                              </Accordion>
                         </>
                    );
               })}
          </Grid >
     );
};

export default withRouter(Sidebar);
