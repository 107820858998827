import { Modal, Backdrop, Fade, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
import CloseIcon from "@material-ui/icons/Close";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const ModalManagePolicy = (props) => {
     const classes = useStyles();
     const { open, handleClose, state, handleChangeText, onClickEditQuestion } = props;
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               // onClose={handleClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container justifyContent="flex-end" xs={12}>
                              <Button onClick={() => handleClose()} className={classes.buttonClose}>
                                   <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                              </Button>
                         </Grid>
                         <Grid container justifyContent="center" spacing={2}>
                              <GridTextField state={state} data={textFiled.policy_modal} handleChange={handleChangeText} />
                              <Button className={classes.btnStyle} variant="contained" style={{backgroundColor:"#4589C6"}} onClick={() => onClickEditQuestion()}>
                                   Edit
                              </Button>
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};
const useStyles = makeStyles(() => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "10px 18px 25px",
          borderRadius: "8px",
     },
     btnStyle: { maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40, marginTop: 10 },
     btnStyleClose: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "right",
          },
     },
     btnStyleWallet: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "left",
          },
     },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
}));

export default ModalManagePolicy;
