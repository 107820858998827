import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ASSINGUSERTOSALE, GET, GETADMIN, GETUSERMANAGE, IBTOMANAGER, POST } from "service";
import ManageUserCard from "./ManageUser/ManageUserCard";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";
import moment from "moment";

class ManageUser extends Component {
     constructor(props) {
          super(props);

          this.state = {
               language: localStorage.getItem("language"),
               data: [],
               member: [],
               loading: false,
               page: 1,
               item_sale: [{ label: "Select Sale", value: "null", disabled: true }],
               sale: "null",
               open: false,
               role: localStorage.getItem("role"),
          };
     }

     componentDidMount = () => {
          this.getAllMember();
          this.getAdmin();
     };

     getAllMember = async () => {
          try {
               this.setState({ loading: true });
               let member = await GET(GETUSERMANAGE);
               if (member.success) {
                    this.setState({ member: member.result, data: member.result });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: member.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (e) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: e, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     getAdmin = async () => {
          const { item_sale } = this.state;
          try {
               this.setState({ loading: true });
               let getAdmin = await GET(GETADMIN);
               if (getAdmin.success) {
                    let result = getAdmin.result;
                    result.map((el) => {
                         if (el.role === "sale") {
                              item_sale.push({ label: `${el.name} ${el.lastname}`, value: el.admin_id });
                         }
                    });
                    this.setState({ item_sale: item_sale });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: getAdmin.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickAssingUser = async () => {
          const { sale, user_id, language } = this.state;
          try {
               if (sale !== "null" && sale) {
                    this.setState({ loading: true });
                    let assing = await POST(ASSINGUSERTOSALE, { user_id, admin_id: Number(sale) });
                    if (assing.success) {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "เลือก Sale สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         this.getAllMember();
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "Warning", text: assing.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "Warning",
                         text: `${language === "th" ? "กรุณาเลือก Sale" : "Please Select Sale"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "Warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     submitSearch = () => {
          const { member, search_text } = this.state;
          let data = member.filter(
               (el) =>
                    (search_text ? el.name.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.lastname.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? (el.phone ? el.phone.toLowerCase().includes(search_text.toLowerCase()) : el.phone) : el) ||
                    (search_text ? el.email.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? moment(el.created_at).format("DD/MM/YYYY").includes(search_text.toLowerCase()) : el)
          );
          this.setState({ data });
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     onClickOpen = (user_id) => {
          this.setState({ open: true, user_id, sale: "null" });
     };
     onClickClose = () => {
          this.setState({ open: false });
     };

     onClickChangeToManager = (user_id) => {
          const { language } = this.state;
          swal.fire({
               text: `${language === "th" ? "ต้องการเปลี่ยนเป็น IB หรือไม่" : "Do you want change to ib?"}`,
               icon: "question",
               showCancelButton: true,
               confirmButtonText: "Submit",
               cancelButtonText: "Cancel",
          }).then(async (result) => {
               if (result.isConfirmed) {
                    try {
                         let user_tomanager = await POST(IBTOMANAGER, { user_id });
                         if (user_tomanager.success) {
                              this.getAllMember();
                              swal.fire({ title: "สำเร็จ", text: "Change to manager success", icon: "success", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         } else {
                              swal.fire({ title: "Warning", text: user_tomanager.message, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } catch (error) {
                         swal.fire({ title: "Warning", text: error, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               }
          });
     };

     render() {
          const { language, data, loading, page, item_sale, open, role } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <ManageUserCard
                         state={this.state}
                         language={language}
                         submitSearch={this.submitSearch}
                         data_table={data}
                         handleChangeText={this.handleChangeText}
                         page={page}
                         handleChangePage={this.handleChangePage}
                         item_sale={item_sale}
                         onClickOpen={this.onClickOpen}
                         onClickClose={this.onClickClose}
                         open={open}
                         onClickAssingUser={this.onClickAssingUser}
                         role={role}
                         onClickChangeToManager={this.onClickChangeToManager}
                    />
               </Container>
          );
     }
}

export default withRouter(ManageUser);
