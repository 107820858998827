import { Container, Grid } from "@material-ui/core";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import Loading from "components/Loading/Loading";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { POST, GETBORKERTRANSACTION } from "service";
import swal from "sweetalert2";
import GridTable from "./Component/GridTable";
import SearchGrid from "./Component/SearchGrid";

class BrokerIncome extends Component {
     constructor(props) {
          super(props);

          this.state = {
               date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
               date_end: new Date(),
               broker_income: [],
               loading: false,
               page: 1,
          };
     }

     componentDidMount = () => {
          this.getBrokerIncome();
     };

     getBrokerIncome = async () => {
          const { date_start, date_end } = this.state;
          try {
               this.setState({ loading: true });
               let getBrokerIncome = await POST(GETBORKERTRANSACTION, { date_start, date_end });
               if (getBrokerIncome.success) {
                    this.setState({ broker_income: getBrokerIncome.result });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: getBrokerIncome.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };
     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };
     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };

     onClickSearch = () => {
          this.getBrokerIncome();
     };
     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     render() {
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl">
                    <Grid>
                         <HeaderTypography title={language === "th" ? "รายได้ Broker" : "Broker Income"} />
                         <SearchGrid
                              language={language}
                              handleDateStartChange={this.handleDateStartChange}
                              handleDateEndChange={this.handleDateEndChange}
                              state={this.state}
                              onClickSearch={this.onClickSearch}
                         />
                         <GridTable body={this.state.broker_income} page={this.state.page} handleChangePage={this.handleChangePage} />
                    </Grid>
                    <Loading open={this.state.loading} />
               </Container>
          );
     }
}

export default withRouter(BrokerIncome);
