import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import { withRouter } from "react-router-dom";
import TableDeposit from "./Components/TableDeposit";
import SearchTable from "./Components/SearchTable";
import ModalEditExchange from "./Components/ModalEditExchange";
import { POST, GET, DEPOSITCONFIG, DEPOSITEDIT } from "service";
import swal from "sweetalert2";
import Sidebar from "./Components/Sidebar";
import Loading from "components/Loading/Loading";
class SettingDeposit extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               page: 1,
               search_text: "",
               open: false,
               deposit: [],
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getDepositConfig();
     };
     getDepositConfig = async () => {
          try {
               this.setState({ loading: true });
               let deposit = await GET(DEPOSITCONFIG);
               if (deposit.success) {
                    let deposit_array = [];
                    deposit_array.push(deposit.result);
                    this.setState({ deposit: deposit_array, data: deposit_array, loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
          }
     };
     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };
     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     submitSearch = () => {
          let { search_text, deposit } = this.state;
          let data = deposit.filter(
               (el) =>
                    (search_text ? el.min.toString().toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.max.toString().toLowerCase().includes(search_text.toLowerCase()) : el)
          );
          this.setState({ data, page: 1 });
     };
     onClickOpenModal = (id, min, max) => {
          this.setState({ open: true, deposit_id: id, min, max });
     };
     onClickCloseModal = () => {
          this.setState({ open: false });
     };
     handleChangeText = (e) => {
          this.setState({ [e.target.name]: Number(e.target.value) });
     };
     onClickEdit = async () => {
          const { min, max, deposit_id } = this.state;
          try {
               if (min && max && deposit_id) {
                    this.setState({ loading: true });
                    let edit = await POST(DEPOSITEDIT, { min, max, deposit_id });
                    if (edit.success) {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไขสำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         this.getDepositConfig();
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: edit.message, icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: "กรุณากรอกให้ครบ", icon: "error", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "error", 
               // timer: 6000, 
               showConfirmButton: true });
          }
          this.setState({ open: false });
     };
     render() {
          const language = localStorage.getItem("language");
          const { page, data, open, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title="Setup Deposit" pageName="SetupDeposit" />
                         </Grid>
                         <Grid item xs={9}>
                              <Grid>
                                   <HeaderTypography title={"Setup Deposit"} />
                                   <SearchTable
                                        language={language}
                                        data={data}
                                        page={page}
                                        handleChangePage={this.handleChangePage}
                                        handleSearch={this.handleSearch}
                                        state={this.state}
                                        submitSearch={this.submitSearch}
                                   />
                                   <TableDeposit data={data} onClickOpenModal={this.onClickOpenModal} open={open} />
                                   <ModalEditExchange
                                        open={open}
                                        state={this.state}
                                        onClickCloseModal={this.onClickCloseModal}
                                        handleChangeText={this.handleChangeText}
                                        onClickEdit={this.onClickEdit}
                                        title={"Deposit"}
                                   />
                              </Grid>
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(SettingDeposit);
