import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import TableGrid from "./Components/TableGrid";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import ModalEdit from "./Components/Modal/ModalEdit";
import { POST, GET, PAYMENT, EDITEXCHANGE } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

const dataService = [
     {
          id: 1,
          exchange: "PayPal",
          spdeposit: "1",
          spwitdraw: "1",
          deposit: "33.5",
          withdrawal: "31.5",
     },
     {
          id: 2,
          exchange: "Internet Banking",
          spdeposit: "1",
          spwitdraw: "1",
          deposit: "33.5",
          withdrawal: "31.5",
     },
     {
          id: 3,
          exchange: "Bitcoin",
          spdeposit: "1",
          spwitdraw: "1",
          deposit: "33.5",
          withdrawal: "31.5",
     },
     {
          id: 4,
          exchange: "Bitcoin",
          spdeposit: "1",
          spwitdraw: "1",
          deposit: "33.5",
          withdrawal: "31.5",
     },
];
export class ExchangeRate extends Component {
     constructor(props) {
          super(props);

          this.state = {
               open_modal: false,
               data: [],
               loading: false,
               page: 1,
          };
     }

     componentDidMount = () => {
          this.getPayment();
     };

     getPayment = async () => {
          try {
               this.setState({ loading: true });
               let payment = await GET(PAYMENT);
               if (payment.success) {
                    this.setState({
                         data: payment.result,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: payment.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     onClickOpenModal = (id, payment, deposit, withdrawal) => {
          this.setState({ payment_gateway_id: id, open_modal: true, payment_gateway: payment, deposit: deposit, withdrawal: withdrawal });
     };
     onClickCloseModal = () => {
          this.setState({ open_modal: false });
     };
     onClickEdit = async () => {
          try {
               const { payment_gateway_id, deposit, withdrawal, payment_gateway } = this.state;
               this.setState({ loading: true });
               let edit = await POST(EDITEXCHANGE, { payment_gateway_id, deposit, withdraw: withdrawal });
               if (edit.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: `แก้ไข ${payment_gateway} สำเร็จ`, icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    this.setState({ open_modal: false });
                    this.getPayment();
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: edit.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     render() {
          const { data, loading, page } = this.state;
          return (
               <Container maxWidth="xl">
                    <Grid>
                         <HeaderTypography title="Exchange Rate" />
                         <TableGrid body={data} onClickOpenModal={this.onClickOpenModal} page={page} handleChangePage={this.handleChangePage} />
                         <ModalEdit
                              open={this.state.open_modal}
                              state={this.state}
                              handleChangeText={this.handleChangeText}
                              onClickCloseModal={this.onClickCloseModal}
                              onClickEdit={this.onClickEdit}
                         />
                    </Grid>
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(ExchangeRate);
