import { Button, Grid, Typography } from "@material-ui/core";

const CardDetailSupport = (props) => {
     const { language, onClickChangeStatus, data_poblem } = props;
     if (data_poblem) {
          const title = data_poblem.problem;
     }
     return (
          <Grid container spacing={2} width={"100%"}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                         {language === "th" ? "รายละเอียดของปัญหา" : "Problem Detail"}
                    </Typography>
               </Grid>
               {data_poblem && (
                    <>
                         <Grid item xs={12}>
                              <Typography sx={{ fontWeight: 600, fontSize: 18 }}>{data_poblem.name_ticket || "-"}</Typography>
                         </Grid>
                         <Grid item xs={12}>
                              <div dangerouslySetInnerHTML={{ __html: data_poblem.problem }} />
                         </Grid>
                         {Number(data_poblem.status) !== 1 && (
                              <Grid item xs={12} container justifyContent={"center"}>
                                   <Button
                                        variant="contained"
                                        // color="error"
                                        style={{ backgroundColor: "#4589C6" }} 
                                        sx={{ maxWidth: "150px", fontSize: 12, width: "100%", borderRadius: 40, height: 40 }}
                                        component="span"
                                        onClick={() => onClickChangeStatus(Number(data_poblem.status) === 3 ? "Received" : Number(data_poblem.status) === 2 ? "Success" : "")}
                                   >
                                        {Number(data_poblem.status) === 3 ? "Received" : Number(data_poblem.status) === 2 ? "Success" : ""}
                                   </Button>
                              </Grid>
                         )}
                    </>
               )}
          </Grid>
     );
};

export default CardDetailSupport;
