import { Card, CardContent, CardMedia, Grid, Typography, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { ip } from "service";

const ListMarket = (props) => {
     return props.data.map((el, i) => {
          return (
               <Card sx={{ maxWidth: "100%", marginBottom: 2, padding: 1 }}>
                    <Grid container direction="row">
                         <Grid item xs={2} sx={{ justifyContent: "center", alignSelf: "center", border: "1px solid #E2E2E2", borderRadius: 5, padding: 2 }}>
                              <CardMedia component="img" height="100%" width="auto" image={ip + el.forex_img} alt={el.forex_name} />
                         </Grid>
                         <Grid item container xs={10}>
                              <Grid item xs={12}>
                                   <CardContent>
                                        <Typography variant="body1" color="text.primary">
                                             {el.forex_name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                             {el.forex_detail}
                                        </Typography>
                                   </CardContent>
                              </Grid>
                              <Grid container item direction="row" justifyContent="flex-end" xs={12}>
                                   <Button
                                        variant="contained"
                                        // color="error"
                                        style={{ backgroundColor: "#4589C6" }} 
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={() => props.onClickEdit(el.forex_id)}
                                   >
                                        Edit
                                   </Button>
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={() => props.onClickDelete(el.forex_id)}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </Grid>
                    </Grid>
               </Card>
          );
     });
};

export default withRouter(ListMarket);
