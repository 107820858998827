import { Container, Grid, Typography } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { ALLMEMBER, CANCELSUBIB, CHANGNETWORK, EDITDOC, EDITPROFILE, GET, GETIMAGE, GETIMAGEPROFILE, POST, POSTIMAGE, PROFILEBYUSER, PROFILEINVITEBYUSER, REGISSUBIB, UPDATEPROFILEDOB, UPLOADBANKBOOKIMAGE, VERIFYDOC, WELCOMEBONUS } from "service";
import swal from "sweetalert2";
import picture2 from "../../assets/images/iconavata1.png";
import DetailTabs from "./Profile/DetailTabs";
import NetworkById from "./Profile/NetworkById";
import Profile from "./Profile/Profile";
import ProfileBank from "./Profile/ProfileBank";
import ProfileDetailCard from "./Profile/ProfileDetailCard";
import ProfileDocument from "./Profile/ProfileDocument";
import TapCrypto from "./Profile/TapCrypto";
class ProfileDetail extends Component {
     constructor(props) {
          super(props);

          this.state = {
               tap_value: 1,
               profile: {},
               textfield_profile: [
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Name",
                         typography_th: "ชื่อนามสกุล",
                         placeholder: "Name",
                         placeholder_th: "ชื่อนามสกุล",
                         name: "name",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Last Name",
                         typography_th: "นามสกุล",
                         placeholder: "Last Name",
                         placeholder_th: "นามสกุล",
                         name: "lastname",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Mobile",
                         typography_th: "โทรศัพท์",
                         placeholder: "Mobile",
                         placeholder_th: "โทรศัพท์",
                         name: "phone",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Email",
                         typography_th: "อีเมลล์",
                         placeholder: "Email",
                         placeholder_th: "อีเมลล์",
                         name: "email",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Identity",
                         typography_th: "หมายเลขประจำตัว",
                         placeholder: "Identity",
                         placeholder_th: "หมายเลขประจำตัว",
                         name: "identity",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Address",
                         typography_th: "ที่อยู่",
                         placeholder: "Address",
                         placeholder_th: "ที่อยู่",
                         name: "address2",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Country",
                         typography_th: "ประเทศ",
                         placeholder: "Country",
                         placeholder_th: "ประเทศ",
                         name: "address",
                         disabled: true,
                    },
               ],
               upload_bookbank_select: [
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Bank",
                         typography_th: "ธนาคาร",
                         placeholder: "Bank",
                         placeholder_th: "ธนาคาร",
                         name: "bank_code",
                         disabled: true,
                         select: true,
                         selectname: "menuItem",
                    },
               ],
               upload_bookbank: [
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Account Number",
                         typography_th: "หมายเลขบัญชี",
                         placeholder: "Account Number",
                         placeholder_th: "หมายเลขบัญชี",
                         name: "bank_account",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Account Name",
                         typography_th: "ชื่อบัญชี",
                         placeholder: "Account Name",
                         placeholder_th: "ชื่อบัญชี",
                         name: "account_name",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Branch Name",
                         typography_th: "ชื่อบัญชี",
                         placeholder: "Branch Name",
                         placeholder_th: "ชื่อบัญชี",
                         name: "branch_name",
                         disabled: true,
                    },
               ],
               dataCard: [
                    { title: "Wallet", amount: 0 },
                    { title: "MT4/MT5 Balance", amount: 0 },
                    { title: "IB", amount: 0 },
                    { title: "Credit", amount: 0 },
                    // { title: "Reward", amount: 0 },
               ],
               disabled_doc: true,
               disabled_bank: true,
               disabled_textfield: true,
               loading: false,
               role: localStorage.getItem("role"),
               token: localStorage.getItem("token"),
               connect_user: "null",
               menuItem: [{ label: "Select User", value: "null", disabled: true }],
               email_invite: null,
               bankbook_file: null,
               crypto_type: null,
               address_usdt: null,
               dob: null,
               dob_disable: true,
          };
     }

     componentDidMount() {
          this.getProfile();
          // this.getAllMember();
     }

     getProfile = async () => {
          let { dataCard } = this.state;
          try {
               this.setState({ loading: true });
               let profile = await POST(PROFILEBYUSER, { user_id: this.props.match.params.id });
               if (profile.success) {
                    let result = profile.result;
                    // console.log("res", result);
                    dataCard[0].amount = result.wallet;
                    dataCard[1].amount = result.mt4_balance;
                    dataCard[2].amount = result.ib_wallet;
                    dataCard[3].amount = result.avaliable_credit;

                    let bank_code = null;
                    if (result.bank_code) {
                         bank_code = result.bank_code;
                    } else {
                         bank_code = "เลือกธนาคาร";
                    }
                    if (result.bank_path) {
                         this.getImageBank(result.bank_path);
                    }
                    if (result.document_path) {
                         if (JSON.parse(result.document_path).length > 1) {
                              this.getImageDoc1(JSON.parse(result.document_path)[0]);
                              this.getImageDoc2(JSON.parse(result.document_path)[1]);
                         } else {
                              this.getImageDoc1(JSON.parse(result.document_path)[0]);
                         }
                    }
                    if (result.profile_image) {
                         this.getImageProfile(result.profile_image);
                    }
                    if (result.invite_id) {
                         this.getProfileInvite(result.invite_id);
                    }
                    this.setState({
                         profile: result,
                         bank_code: bank_code,
                         bank_account: result.bank_account,
                         account_name: result.account_name,
                         fullname: `${result.name} ${result.lastname}`,
                         phone: result.phone,
                         email: result.email,
                         address: result.address,
                         address2: result.address2,
                         identity: result.identity,
                         name: result.name,
                         lastname: result.lastname,
                         loading: false,
                         branch_name: result.branch_name,
                         verify_bank: result.verify_bank,
                         crypto_type: result.crypto_type,
                         address_usdt: result.address_usdt,
                         dob: result.dob ? moment(result.dob).format('YYYY-MM-DD') : null,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: profile.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getProfileInvite = async (invite_id) => {
          try {
               let get_email_user = await POST(PROFILEINVITEBYUSER, { invite_id });
               if (get_email_user.success) {
                    this.setState({ email_invite: get_email_user.result.email });
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     getAllMember = async () => {
          let { menuItem } = this.state;
          try {
               let get_user = await GET(ALLMEMBER);
               if (get_user.success) {
                    get_user.result.map((el) => {
                         menuItem.push({ label: `${el.name} ${el.lastname}`, value: el.user_id });
                    });
                    this.setState({ menuItem });
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     getImageProfile = async (path_image) => {
          const { token } = this.state;
          try {
               const res = await GETIMAGE(GETIMAGEPROFILE, { token, path_image });
               const imageBlob = await res.blob();
               const image_profile = URL.createObjectURL(imageBlob);
               this.setState({ image_profile });
          } catch (error) {
               console.log("error", error);
          }
     };

     getImageBank = async (path_image) => {
          const { token } = this.state;
          try {
               const res = await GETIMAGE(GETIMAGEPROFILE, { token, path_image });
               const imageBlob = await res.blob();
               const image_bank = URL.createObjectURL(imageBlob);

               this.setState({ image_bank });
          } catch (error) {
               console.log("error", error);
          }
     };

     getImageDoc1 = async (path_image) => {
          const { token } = this.state;
          try {
               const res = await GETIMAGE(GETIMAGEPROFILE, { token, path_image });
               const imageBlob = await res.blob();
               const image_doc1 = URL.createObjectURL(imageBlob);
               this.setState({ image_doc1 });
          } catch (error) {
               console.log("error", error);
          }
     };

     getImageDoc2 = async (path_image) => {
          const { token } = this.state;
          try {
               const res = await GETIMAGE(GETIMAGEPROFILE, { token, path_image });
               const imageBlob = await res.blob();
               const image_doc2 = URL.createObjectURL(imageBlob);
               this.setState({ image_doc2 });
          } catch (error) {
               console.log("error", error);
          }
     };

     onClickConfirm = async (verify) => {
          //verify = 1 == comfirm
          //verify = 3 == reject
          try {
               this.setState({ loading: true });
               let verifyDoc = await POST(VERIFYDOC, { user_id: this.props.match.params.id, verify });
               if (verifyDoc.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.getProfile();
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: verifyDoc.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickConfirmBank = async (verify) => {
          //verify = 1 == comfirm
          //verify = 3 == reject
          try {
               this.setState({ loading: true });
               let verifyDoc = await POST(EDITDOC, { user_id: this.props.match.params.id, verify_bank: verify });
               if (verifyDoc.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.getProfile();
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: verifyDoc.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     submitEdit = () => {
          let { textfield_profile } = this.state;
          let disabled_textfield = false;
          textfield_profile[0].disabled = false;
          textfield_profile[1].disabled = false;
          textfield_profile[2].disabled = false;
          textfield_profile[4].disabled = false;
          textfield_profile[5].disabled = false;
          textfield_profile[6].disabled = false;

          this.setState({ disabled_textfield, textfield_profile });
     };

     submitEditDob = () => {
          const { dob_disable } = this.state;
          this.setState({ dob_disable: !dob_disable });
     };

     submitSave = async () => {
          let { textfield_profile, disabled_textfield, identity, address, phone, name, lastname, address2 } = this.state;
          let update_profile = await POST(EDITPROFILE, { identity, address, phone, name, lastname, address2, user_id: this.props.match.params.id });
          try {
               this.setState({ loading: true });
               if (update_profile.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "อัพเดตโปรไฟล์สำเร็จ", icon: "success", timer: 1800, showConfirmButton: false });
                    disabled_textfield = true;
                    textfield_profile[0].disabled = true;
                    textfield_profile[1].disabled = true;
                    textfield_profile[2].disabled = true;
                    textfield_profile[4].disabled = true;
                    textfield_profile[5].disabled = true;
                    textfield_profile[6].disabled = true;
                    this.setState({ disabled_textfield, textfield_profile });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: update_profile.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     onChangeSelect = (e) => {
          let label = menuItemBank.find((el) => el.value === e.target.value).label;
          this.setState({ [e.target.name]: e.target.value, bank_name: label });
     };

     submitEditDoc = () => {
          let { upload_bookbank, upload_bookbank_select } = this.state;
          let disabled_doc = false;
          // upload_bookbank_select[0].disabled = false;
          // upload_bookbank[0].disabled = false;
          // upload_bookbank[1].disabled = false;
          // upload_bookbank[2].disabled = false;
          this.setState({ disabled_doc });
     };

     submitEditBank = () => {
          let { upload_bookbank, upload_bookbank_select } = this.state;
          let disabled_bank = false;
          upload_bookbank_select[0].disabled = false;
          upload_bookbank[0].disabled = false;
          upload_bookbank[1].disabled = false;
          upload_bookbank[2].disabled = false;
          this.setState({ disabled_bank });
     };

     submitSaveDoc = async () => {
          let { upload_bookbank, upload_bookbank_select, bank_name, account_name, bank_account, bank_code, branch_name } = this.state;
          try {
               this.setState({ loading: true });
               let update_doc = await POST(EDITDOC, { bank_name, account_name, bank_account, user_id: this.props.match.params.id, bank_code, branch_name });
               if (update_doc.success) {
                    console.log("สำเร็จสำเร็จสำเร็จ");
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "อัพเดตโปรไฟล์สำเร็จ", icon: "success", timer: 1800, showConfirmButton: false });
                    let disabled_doc = true;
                    upload_bookbank_select[0].disabled = false;
                    upload_bookbank[0].disabled = true;
                    upload_bookbank[1].disabled = true;
                    upload_bookbank[2].disabled = true;
                    this.setState({ disabled_doc });
               } else {
                    console.log("ไม่สำเร็จไม่สำเร็จไม่สำเร็จ");
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: update_doc.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     submitSaveBank = async () => {
          let { upload_bookbank, upload_bookbank_select, bank_name, account_name, bank_account, bank_code, branch_name, verify_bank, bankbook_file } = this.state;
          // console.log("bankbook_file", bankbook_file);
          if (bankbook_file !== null) {
               this.updateBankbook(bankbook_file);
          }
          try {
               this.setState({ loading: true });
               let update_doc = await POST(EDITDOC, { bank_name, account_name, bank_account, user_id: this.props.match.params.id, bank_code, branch_name, verify_bank });
               if (update_doc.success) {
                    this.setState({ loading: false });

                    let disabled_doc = true;
                    upload_bookbank_select[0].disabled = false;
                    upload_bookbank[0].disabled = true;
                    upload_bookbank[1].disabled = true;
                    upload_bookbank[2].disabled = true;
                    this.setState({ disabled_doc });

                    swal.fire({ title: "Success", text: `อัพเดตโปรไฟล์สำเร็จ`, icon: "success", showConfirmButton: true }).then((result) => {
                         if (result.isConfirmed) {
                              window.location.reload();
                              // return true;
                         }
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: update_doc.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     updateBankbook = async (file) => {
          const { token } = this.state
          // console.log('file', file);
          if (file) {
               let formData = new FormData();
               formData.append("user_id", this.props.match.params.id);
               formData.append("bank", file);

               try {
                    let res = await POSTIMAGE(UPLOADBANKBOOKIMAGE, formData, true, token);
                    if (res.success) {
                         this.setState({ bankbook_file: null });
                         // swal.fire({ title: "Success", text: `${res.result}`, icon: "success", showConfirmButton: true }).then((result) => {
                         //      if (result.isConfirmed) {
                         //           window.location.reload();
                         //      }
                         // });
                    }

               } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: error, icon: "warning", showConfirmButton: true });
               }
          }
     };

     updateDob = async () => {
          let { dob } = this.state;
          let res = await POST(UPDATEPROFILEDOB, { dob, user_id: this.props.match.params.id });
          try {
               this.setState({ loading: true });
               if (res.success) {
                    this.setState({ loading: false, dob_disable: true });
                    this.getProfile();
                    swal.fire({ title: "สำเร็จ", text: "อัพเดตโปรไฟล์สำเร็จ", icon: "success", timer: 1800, showConfirmButton: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     // onClickUpLoadFrontDoc = (file) => {
     //      alert(`upload fornt ${file}`);
     // };

     // onClickUpLoadBackDoc = (file) => {
     //      alert(`upload back ${file}`);
     // };

     onClickUpLoadBankDoc = (file) => {
          // alert(`upload bank ${file}`);
          this.setState({ bankbook_file: file });
     };

     onClickRegisterSubIb = async () => {
          try {
               let regis = await POST(REGISSUBIB, { user_id: this.props.match.params.id });
               if (regis.success) {
                    swal.fire({
                         title: "สำเร็จ", text: "สมัคร Sub IB สำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: regis.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickCancleSubIb = async () => {
          try {
               let cancle = await POST(CANCELSUBIB, { user_id: this.props.match.params.id });
               if (cancle.success) {
                    swal.fire({
                         title: "สำเร็จ", text: "ยกเลิก Sub IB สำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    }).then((result) => {
                         if (result.isConfirmed) {
                              window.location.reload();
                         }
                    })
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: cancle.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickChangeIB = async () => {
          const { connect_user } = this.state;
          try {
               let change_network = await POST(CHANGNETWORK, { user_id: this.props.match.params.id, connect_user });
               if (change_network.success) {
                    swal.fire({
                         title: "สำเร็จ", text: "เปลี่ยนสายงานสำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: change_network.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickWelcombonus = async () => {
          try {
               this.setState({ loading: true });
               let welcome = await POST(WELCOMEBONUS, { user_id: this.props.match.params.id });
               if (welcome.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", text: welcome.result, icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: welcome.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     handleDateChange = (date) => {
          this.setState({ dob: date });
     };

     render() {
          const { tap_value, profile, disabled_textfield, disabled_doc, dataCard, loading, role, image_bank, image_doc1, image_doc2, image_profile, menuItem, disabled_bank, email_invite, textfield_crypto, menuCrypto } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Loading open={loading} />
                    <Grid container xs={12} spacing={2} justifyContent="center" alignItems="center">
                         <Grid item xs={12}>
                              <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                                   Profile Detail : {profile.name} {profile.lastname}
                              </Typography>
                         </Grid>
                         <Profile dataCard={dataCard} />
                         {role !== "sale" && <DetailTabs setTap={(e) => this.setState({ tap_value: e })} />}
                         {tap_value === 1 ? (
                              <ProfileDetailCard
                                   img={picture2}
                                   data={profile}
                                   state={this.state}
                                   language={language}
                                   submitEdit={this.submitEdit}
                                   submitSave={this.submitSave}
                                   onChangeText={this.onChangeText}
                                   disabled_textfield={disabled_textfield}
                                   role={role}
                                   image_profile={image_profile}
                                   onClickRegisterSubIb={this.onClickRegisterSubIb}
                                   onClickCancleSubIb={this.onClickCancleSubIb}
                                   menuItem={menuItem}
                                   onClickChangeIB={this.onClickChangeIB}
                                   onClickWelcombonus={this.onClickWelcombonus}
                                   email_invite={email_invite}
                                   handleDateChange={this.handleDateChange}
                                   submitEditDob={this.submitEditDob}
                                   updateDob={this.updateDob}
                              />
                         ) : tap_value === 2 ? (
                              <ProfileDocument
                                   data={profile}
                                   onClickConfirm={this.onClickConfirm}
                                   state={this.state}
                                   language={language}
                                   submitEditDoc={this.submitEditDoc}
                                   submitSaveDoc={this.submitSaveDoc}
                                   disabled_doc={disabled_doc}
                                   onChangeText={this.onChangeText}
                                   onChangeSelect={this.onChangeSelect}
                                   menuItem={menuItemBank}
                                   onClickUpLoadFrontDoc={this.onClickUpLoadFrontDoc}
                                   onClickUpLoadBackDoc={this.onClickUpLoadBackDoc}
                                   onClickUpLoadBankDoc={this.onClickUpLoadBankDoc}
                                   role={role}
                                   image_bank={image_bank}
                                   image_doc1={image_doc1}
                                   image_doc2={image_doc2}
                              />
                         ) : tap_value === 3 ? (
                              // <ProfileIBNetwork data={MockDataIB} />
                              <ProfileBank
                                   data={profile}
                                   onClickConfirm={this.onClickConfirmBank}
                                   state={this.state}
                                   language={language}
                                   submitEditBank={this.submitEditBank}
                                   submitSaveBank={this.submitSaveBank}
                                   disabled_bank={disabled_bank}
                                   onChangeText={this.onChangeText}
                                   onChangeSelect={this.onChangeSelect}
                                   menuItem={menuItemBank}
                                   onClickUpLoadFrontDoc={this.onClickUpLoadFrontDoc}
                                   onClickUpLoadBackDoc={this.onClickUpLoadBackDoc}
                                   onClickUpLoadBankDoc={this.onClickUpLoadBankDoc}
                                   role={role}
                                   image_bank={image_bank}
                                   image_doc1={image_doc1}
                                   image_doc2={image_doc2}
                              />
                         )
                              : tap_value === 4 ? (
                                   <NetworkById dataId={this.props.match.params.id} />

                              ) : tap_value === 5 ? (
                                   <TapCrypto
                                        language={language}
                                        data={this.state}
                                   />
                              ) :
                                   null}
                    </Grid>
               </Container>
          );
     }
}

const MockDataIB = [
     { type: "Manager", dataarray: [{ account: "0000000000", name: "Name Lastname" }], dividend: "50" },
     {
          type: "Sub IBs 1st",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
     {
          type: "Sub IBs 2nd",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
     {
          type: "Sub IBs 3th",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
     {
          type: "Sub IBs 4th",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
     {
          type: "Sub IBs 5th",
          dataarray: [
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
               { account: "0000000000", name: "Name Lastname" },
          ],
          dividend: "10",
     },
];

const menuItemBank = [
     { label: "เลือกธนาคาร", value: "เลือกธนาคาร", disabled: true },
     { label: "ธนาคารกรุงเทพ", value: "BBL" },
     { label: "ธนาคารกรุงไทย", value: "KTB" },
     { label: "ธนาคารกรุงศรีอยุธยา", value: "BAY" },
     { label: "ธนาคารกสิกรไทย", value: "KBANK" },
     { label: "ธนาคารเกียรตินาคินภัทร", value: "KKP" },
     { label: "ซิตี้แบงก์ประเทศไทย", value: "CITI" },
     { label: "ธนาคารซีไอเอ็มบีไทย", value: "CIMBT" },
     { label: "ธนาคารทหารไทยธนชาต", value: "TTB" },
     { label: "ธนาคารไทยพาณิชย์", value: "SCB" },
     { label: "ธนาคารทิสโก้", value: "TISCO" },
     { label: "ธนาคารไทยเครดิตเพื่อรายย่อย", value: "TCD" },
     { label: "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย", value: "SME" },
     { label: "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย", value: "EXIM" },
     { label: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", value: "BAAC" },
     { label: "ธนาคารยูโอบี", value: "UOBT" },
     { label: "ธนาคารอิสลามแห่งประเทศไทย", value: "ISBT" },
     { label: "ธนาคารออมสิน", value: "GSB" },
     { label: "ธนาคารอาคารสงเคราะห์", value: "GHB" },
     { label: "ธนาคารแลนด์ แอนด์ เฮ้าส์", value: "LH" },
];

export default withRouter(ProfileDetail);
