import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, CardMedia, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();
// import { ip } from "../../../service";
// import Pagination from "@material-ui/lab/Pagination";

const CardHead = (props) => {
     const classes = useStyles();
     const { dataCard } = props;
     return (
          <Grid container justifyContent="center" alignItems="center" columnSpacing={2} rowSpacing={2}>
               {dataCard.map((el, i) => (
                    <Grid item xs={12} sm={6} md={3} key={i}>
                         <Card sx={{ py: 2, px: 3 }}>
                              <Grid sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                   <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={el.icon} />
                                   <Grid>
                                        <Typography className={classes.titleStyle}>{el.name}</Typography>
                                        <Typography className={classes.moneyStyle}>{el.money}</Typography>
                                   </Grid>
                              </Grid>
                         </Card>
                    </Grid>
               ))}
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     titleStyle: {
          fontSize: 16,
          color: "#000",
          fontWeight: 500,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     moneyStyle: {
          fontSize: 22,
          color: "#4589C6",
          fontWeight: 600,
          // [theme.breakpoints.up("sm")]: {
          //      fontSize: 28,
          // },
     },
     imgStyle: {
          height: 50,
          objectFit: "contain",
          width: "auto",
          marginRight: 8,
          // [theme.breakpoints.up("sm")]: {
          //      height: 80,
          // },
     },
     fontStyleDetail: {
          fontSize: 12,
          color: "#676767",
          textAlign: "justify",
          marginTop: 24,
          [theme.breakpoints.up("sm")]: {
               fontSize: 18,
          },
     },
     fontStyleBodyTitle: {
          fontSize: 12,
          marginTop: 24,
          fontWeight: 600,
          [theme.breakpoints.up("sm")]: {
               fontSize: 18,
          },
     },
     fontStyleBodyDetail: {
          fontSize: 12,
          paddingLeft: 8,
          [theme.breakpoints.up("sm")]: {
               fontSize: 14,
          },
     },
}));
export default withRouter(CardHead);
