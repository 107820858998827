import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import clsx from "clsx";
// import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  CardMedia,
  Container,
  Box,
  Avatar,
  Badge,
  CardActionArea,
  Popover,
  Backdrop,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// import { ip } from "../../service/service";
// import swal from "sweetalert2";
// import moment from "moment";
import logo_web from "../../assets/logo/LOGOQrs.png";
import th from "../../assets/logo/ThailandFlag.png";
import en from "../../assets/logo/ENFlag.png";
import avatar from "../../assets/logo/avatar@2x.png";
import bell from "../../assets/logo/bell.png";
import loadingSvg from "../../assets/images/loadingSvg.svg";

const MainHeader = (props) => {
  const { language } = props;
  const changeLangauge = () => {
    handleClosePop();
    handleOpenLoading();
    let data = localStorage.getItem("language");
    localStorage.setItem("language", data === "th" ? "en" : "th");
    setTimeout(() => {
      // handleCloseLoading();
      window.location.reload();
    }, 3000);
  };
  //---------------------------------------------------------------//
  //---------------------------------------------------------------//
  const [anchorEl, setOpenPop] = React.useState(null);
  const handleOpenPop = (event) => {
    setOpenPop(event.currentTarget);
  };
  const handleClosePop = () => {
    setOpenPop(null);
  };
  const openpop = Boolean(anchorEl);
  const id = openpop ? "simple-popover" : undefined;
  //---------------------------------------------------------------//
  //---------------------------------------------------------------//
  const [openLoading, setOpenLoading] = React.useState(false);
  const handleOpenLoading = () => {
    setOpenLoading(!openLoading);
  };

  const [openMenu, setOpenMenu] = React.useState(null);
  const open = Boolean(openMenu);
  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };
  const handleClose = () => {
    setOpenMenu(null);
  };

  const onClickChangePassword = () => {
    props.history.push("/change_password");
  };

  return (
    <AppBar
      position="absolute"
      sx={{
        boxShadow: "none",
        background: "rgba(0, 0, 0, 0.18)",
      }}
    >
      <Container maxWidth="xl" className="main-header">
        <Toolbar>
          <RouterLink to="/dashboard">
            <CardMedia
              component="img"
              sx={{ height: 40, width: "auto" }}
              alt="Logo"
              image={logo_web}
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* <Grid item xs="auto">
                                        <Avatar alt="Admin" src={avatar} />
                                   </Grid> */}
              <Grid item xs="auto">
                <Button
                  sx={{ my: 2, display: "block", color: "#ffffff" }}
                  onClick={handleClick}
                >
                  <Typography className="title-menu">
                    {props.data.name} {props.data.lastname} ({props.data.role})
                  </Typography>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={openMenu}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onClickChangePassword();
                    }}
                  >
                    Change Password
                  </MenuItem>
                </Menu>
              </Grid>
              {/* <Grid item xs="auto">
                                        <CardActionArea>
                                             <Badge variant="dot" color="error">
                                                  <CardMedia component="img" sx={{ height: 20, width: 20 }} alt="Bell" image={bell} />
                                             </Badge>
                                        </CardActionArea>
                                   </Grid> */}
              <Grid
                item
                xs="auto"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid sx={{ height: 25, borderLeft: "solid 1px #989494" }} />
                {language === "th" ? (
                  <CardActionArea
                    aria-describedby={id}
                    onClick={handleOpenPop}
                    sx={{ display: "flex", alignItems: "center", ml: 2 }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ height: 14, width: "auto", mr: 1 }}
                      alt="Logo"
                      image={th}
                    />
                    <Typography className="title-menu">ภาษาไทย</Typography>
                  </CardActionArea>
                ) : (
                  <CardActionArea
                    aria-describedby={id}
                    onClick={handleOpenPop}
                    sx={{ display: "flex", alignItems: "center", ml: 2 }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ height: 14, width: "auto", mr: 1 }}
                      alt="Logo"
                      image={en}
                    />
                    <Typography className="title-menu">English</Typography>
                  </CardActionArea>
                )}
                <Popover
                  id={id}
                  open={openpop}
                  anchorEl={anchorEl}
                  onClose={handleClosePop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Typography py={1} className="main-pop">
                    {language !== "th" ? (
                      <CardActionArea
                        onClick={changeLangauge}
                        sx={{
                          pl: 2,
                          pr: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CardMedia
                          component="img"
                          sx={{ height: 14, width: "auto", mr: 1 }}
                          alt="Logo"
                          image={th}
                        />
                        <Typography className="title-menu">ภาษาไทย</Typography>
                      </CardActionArea>
                    ) : (
                      <CardActionArea
                        onClick={changeLangauge}
                        sx={{
                          pl: 2,
                          pr: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CardMedia
                          component="img"
                          sx={{ height: 14, width: "auto", mr: 1 }}
                          alt="Logo"
                          image={en}
                        />
                        <Typography className="title-menu">English</Typography>
                      </CardActionArea>
                    )}
                  </Typography>
                </Popover>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </Container>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <Grid className="loading-animation">
          <CardMedia
            component="img"
            className="loadingSvg"
            alt="loadingSvg"
            image={loadingSvg}
          />
        </Grid>
      </Backdrop>
    </AppBar>
  );
};

export default withRouter(MainHeader);
