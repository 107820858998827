import { Container, Grid } from "@material-ui/core";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import { StringSet } from "functions/Static";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ListService from "./Components/ListService";
import Sidebar from "./Components/Sidebar";
import { POST, SERVICEAPI } from "service";
import Loading from "components/Loading/Loading";

class SettingService extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               loading: false,
          };
     }

     componentDidMount() {
          this.getService();
     }

     async getService() {
          try {
               this.setState({ loading: true });
               let service = await POST(SERVICEAPI, {});
               if (service.success) {
                    this.setState({ data: service.result, loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
          }
     }

     onClickEdit = (id) => {
          this.props.history.push(`/setting_manage_service/edit/${id}`);
     };

     render() {
          const { data, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title="Menu" pageName="service" data={StringSet.service.sidebar} />
                         </Grid>
                         <Grid item container spacing={2} direction="row" xs={9}>
                              <Grid item xs={12}>
                                   <HeaderTypography title={"Service"} />
                                   <ListService data={data} onClickDelete={this.onClickDelete} onClickEdit={this.onClickEdit} />
                              </Grid>
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(SettingService);
