import { Grid, Stack, Typography, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { GridTextField } from "../../../components";
import { textFiled } from "../../../functions/Static";

const SearchTable = (props) => {
     const { language, handleSearch, state, submitSearch } = props;
     return (
          <Grid container display="flex" alignItems="center" columnSpacing={1} rowSpacing={2} marginBottom={2}>
               <Grid item xs={12} sm={8} md={8}>
                    <Stack direction="row" alignItems="center" my={2}>
                         <Typography fontSize={{ xs: 18, sm: 20, md: 20 }} sx={{ fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                         <Grid container sx={{ width: "40vw", mr: 2 }}>
                              <GridTextField
                                   data={textFiled.search_text}
                                   state={state}
                                   handleChange={handleSearch}
                                   onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                             submitSearch();
                                        }
                                   }}
                              />
                         </Grid>
                    </Stack>
               </Grid>
               <Grid item xs={12} sm={4} md={4}>
                    <Grid item width={{ xs: "100%", sm: 120, md: 120 }}>
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              //  className="btn-style"
                              sx={{ fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                              onClick={submitSearch}
                         >
                              {language === "th" ? "ค้นหา" : "Search"}
                         </Button>
                    </Grid>
               </Grid>
          </Grid>
          // <Grid item xs={12}>
          //      <Stack direction="row" alignItems="center" marginBottom={2} mt={2}>
          //           <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
          //           <Grid container sx={{ width: "40vw", mr: 2 }}>
          //                <GridTextField
          //                     data={textFiled.search_text}
          //                     state={state}
          //                     handleChange={handleSearch}
          //                     onKeyDown={(e) => {
          //                          if (e.key === "Enter") {
          //                               submitSearch();
          //                          }
          //                     }}
          //                />
          //           </Grid>
          // <Button
          //      variant="contained"
          //      style={{backgroundColor:"#4589C6"}}
          //      //  className="btn-style"
          //      sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
          //      onClick={submitSearch}
          // >
          //      {language === "th" ? "ค้นหา" : "Search"}
          // </Button>
          //      </Stack>
          // </Grid>
     );
};

export default withRouter(SearchTable);
