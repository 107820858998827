import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const Loading = (props) => {
     const classes = useStyles();
     return (
          <Backdrop className={classes.backdrop} open={props.open}>
               <CircularProgress color="inherit" />
          </Backdrop>
     );
};

const useStyles = makeStyles(() => ({
     backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: "#4589C6",
     },
}));

export default Loading;
