import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import CardHead from "./CardHead/CardHead";
import CardFooter from "./CardFooter/CardFooter";
import wallet1 from "../../assets/logo/wallet1.png";
import wallet2 from "../../assets/logo/total-member.png";
import broker from "../../assets/logo/total-trading.png";
import reward from "../../assets/logo/total-order.png";
import { GET, ALLMEMBER, GETHEADERINFO, GETUSERSALE, POST, UPDATEREMARK, EDITACCOUNTCOUNT, EDITCASHBACK, GETCASHBACK, ACCOUNTTYPEAPI, UPDATEACCOUNTDEMO, ALLDATAMEMBER, GETDATAALLSALE } from "service";
import moment from "moment";
import Loading from "components/Loading/Loading";
import swal from "sweetalert2";
import ModalAddRemark from "./Profile/ModalAddRemark";
import ModalLimitAccount from "./Profile/ModalLimitAccount";
import ModalCashback from "./Profile/ModalCashback";
import ModalAccountDemo from "./Profile/ModalAccountDemo";

export class Dashboard extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               page: 1,
               count_page: 0,
               search_text: "",
               member: [],
               dataCard: [
                    {
                         name: "Total Wallet Control",
                         money: "$0.00",
                         icon: wallet1,
                    },
                    {
                         name: "Total Membership",
                         money: "0",
                         icon: wallet2,
                    },
                    {
                         name: "Total Trading Account",
                         money: "0",
                         icon: broker,
                    },
                    {
                         name: "Total MT4",
                         money: "$0",
                         icon: reward,
                    },
                    {
                         name: "Total MT5",
                         money: "$0",
                         icon: reward,
                    },
               ],
               loading: false,
               select_search_text: 99,
               role: localStorage.getItem("role"),
               open: false,
               openLimit: false,
               openCashback: false,
               update_limitAcc: null,
               active: true,

               openAccDemo: false,
               update_AccDemo: null,

               data_get_cashback: [],
               header_cashback: [],

               data_csv: [],
          };
     }

     componentDidMount = () => {
          const { role } = this.state;
          if (role === "admin") {
               this.getDataAll();
               // this.getAllMember();
               this.getHeaderInfo();
               this.getAccountType();
          } else if (role === "normal") {
               this.getDataAll();
               // this.getAllMember();
          } else {
               // this.getAllMemberSale();
               this.getDataSaleAll();
          }
     };

     getDataAll = async (value) => {
          const { page, search_text, select_search_text } = this.state;
          try {
               this.setState({ loading: true });
               let res = await POST(ALLDATAMEMBER, { page: value ? value : page, status: select_search_text, text_search: search_text });
               if (res.success) {
                    this.setState({
                         member: res.result.user_data, data: res.result.user_data, count_page: res.result.user_count
                    });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (e) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: e, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getDataSaleAll = async (value) => {
          const { page, search_text, select_search_text } = this.state;
          try {
               this.setState({ loading: true });
               let res = await POST(GETDATAALLSALE, { page: value ? value : page, status: select_search_text, text_search: search_text });
               if (res.success) {
                    this.setState({
                         member: res.result.user_data, data: res.result.user_data, count_page: res.result.user_count
                    });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (e) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: e, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getAllMember = async () => {
          try {
               let member = await GET(ALLMEMBER);
               if (member.success) {
                    return Promise.resolve
                         (
                              Promise.all(member.result.map((el) => {
                                   return {
                                        user_id: el.user_id,
                                        username: `${el.name} ${el.lastname}`,
                                        wallet: Math.round(Number(el.wallet) * 100) / 100,
                                        balance: Math.round(Number(el.balance) * 100) / 100,
                                        ib_wallet: Math.round(Number(el.ib_wallet) * 100) / 100,
                                        phone: el.phone ? '"""' + el.phone + '"""' : "",
                                        email: el.email,
                                        created_at: moment(el.created_at).format("DD/MM/YYYY"),
                                        type: el.type,
                                        detail: el.remark,
                                        user_status: el.user_status === 0 ?
                                             "Ban" : el.verify_doc === 1 ?
                                                  "Active" : el.verify_doc === 2 ?
                                                       "Unverify" : el.verify_doc === 3 ?
                                                            "Unsuccess" : el.verify_doc === 4 ?
                                                                 "Pending" : "-",

                                   }
                              }))
                         )
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: member.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (e) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: e, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getAllMemberSale = async () => {
          try {
               let member = await POST(GETUSERSALE);
               if (member.success) {
                    return Promise.resolve
                         (
                              Promise.all(member.result.map((el) => {
                                   return {
                                        user_id: el.user_id,
                                        username: `${el.name} ${el.lastname}`,
                                        wallet: Math.round(Number(el.wallet) * 100) / 100,
                                        balance: Math.round(Number(el.balance) * 100) / 100,
                                        ib_wallet: Math.round(Number(el.ib_wallet) * 100) / 100,
                                        phone: el.phone ? '"""' + el.phone + '"""' : "",
                                        email: el.email,
                                        created_at: moment(el.created_at).format("DD/MM/YYYY"),
                                        type: el.type,
                                        detail: el.remark,
                                        user_status: el.user_status === 0 ?
                                             "Ban" : el.verify_doc === 1 ?
                                                  "Active" : el.verify_doc === 2 ?
                                                       "Unverify" : el.verify_doc === 3 ?
                                                            "Unsuccess" : el.verify_doc === 4 ?
                                                                 "Pending" : "-",

                                   }
                              }))
                         )
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: member.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (e) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: e, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getHeaderInfo = async () => {
          let { dataCard } = this.state;
          try {
               this.setState({ loading: true });
               let heder_info = await GET(GETHEADERINFO);
               if (heder_info.success) {
                    let result = heder_info.result;
                    dataCard[0].money = "$" + Number(result.all_wallet).toFixed(2);
                    dataCard[1].money = result.member;
                    dataCard[2].money = result.trading_acc;
                    dataCard[3].money = "$" + Number(result.mt4_balance).toFixed(2);
                    dataCard[4].money = "$" + Number(result.mt5_balance).toFixed(2);
                    this.setState({ dataCard, loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: heder_info.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getAccountType = async () => {
          let { data_get_cashback } = this.state;
          try {
               let account = await POST(ACCOUNTTYPEAPI, {});
               if (account.success) {

                    // for (let index = 0; index < account.result.length; index++) {
                    //      const element = account.result[index];
                    account.result.map((element, index) => {
                         data_get_cashback.push({
                              id: index + 1,
                              account_type_id: element.account_type_id,
                              account_type_name: element.account_type_name.replace(/ /g, "_"),//standard
                              account_type_name_gold: `${element.account_type_name.replace(/ /g, "_")}_gold`,
                              value_cb: null,
                              get_cb: null,
                              value_cb_gold: null,
                              get_cb_gold: null,
                         })
                    })
                    // }

                    // console.log("data_get_cashback", data_get_cashback);

                    this.setState({
                         header_cashback: account.result.map((e) => e.account_type_name),
                    });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
          }
     }

     handleChangePage = (e, value) => {
          // console.log(value);
          this.setState({ page: value });
          this.getDataAll(value);
     };
     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     submitSearch = async () => {
          const { search_text, select_search_text, role } = this.state;
          try {
               this.setState({ loading: true });
               let res = await POST(`${(role == "admin" || role == "normal") ? ALLDATAMEMBER : GETDATAALLSALE}`, { page: 1, status: select_search_text, text_search: search_text });
               if (res.success) {
                    this.setState({
                         member: res.result.user_data, data: res.result.user_data, count_page: res.result.user_count, page: 1
                    });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (e) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: e, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     OnclickOpenRemarkModal = (user_id, name, lastname, email, remark) => {
          this.setState({ open: true, user_id, name_modal: name, lastname_modal: lastname, email_modal: email, remark });
     };

     onClickClose = () => {
          this.setState({ open: false, remark: null });
     };

     onClickUpdateRemark = async () => {
          const { remark, user_id } = this.state;
          try {
               this.setState({ loading: true });
               let update_remark = await POST(UPDATEREMARK, { remark, user_id });
               if (update_remark.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", text: "Update remark completed", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    // this.getAllMember();
                    this.getDataAll();
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: update_remark.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleChangeLimitAccount = (e) => {
          let value = e.target.value;
          this.setState({ update_limitAcc: value, active: false });
          if (value === '') {
               this.setState({ active: true });
          }
     };

     OnclickOpenLimitAccountModal = (user_id, account_count) => {
          this.setState({ openLimit: true, user_id, account_count });
     };

     onClickCloseLimit = () => {
          this.setState({ openLimit: false });
     };

     onClickUpdateLimitAccount = async () => {
          const { user_id, account_count, update_limitAcc } = this.state;
          // console.log(`user_id  ${user_id}, account_count ${account_count}`);
          try {
               this.setState({ loading: true });
               let update_limit = await POST(EDITACCOUNTCOUNT, { user_id, acc_count: update_limitAcc });
               if (update_limit.success) {
                    // console.log(update_limit.result);
                    this.setState({ loading: false });
                    swal.fire({

                         title: "สำเร็จ", text: "Update Limit Account completed", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    }).then((result) => {
                         if (result.isConfirmed) {

                              this.setState({ update_limitAcc: null });

                         }
                    })
               } else {
                    // console.log(update_limit.result);
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: update_limit.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    }).then((result) => {
                         if (result.isConfirmed) {

                              this.setState({ update_limitAcc: null });

                         }
                    })
               }
          } catch (error) {
               console.log(error);
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     //////

     onClickUpdateAccountDemo = async () => {
          const { user_id, update_AccDemo } = this.state;
          // console.log(`user_id  ${user_id}, account_count ${account_count}`);
          try {
               this.setState({ loading: true });
               let update_acc_demo = await POST(UPDATEACCOUNTDEMO, { user_id, count: update_AccDemo });
               if (update_acc_demo.success) {
                    // console.log(update_acc_demo.result);
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", text: "Update Account Demo Completed", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    }).then((result) => {
                         if (result.isConfirmed) {
                              this.setState({ update_AccDemo: null });
                              window.location.reload();
                         }
                    })
               } else {
                    // console.log(update_limit.result);
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: update_acc_demo.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    }).then((result) => {
                         if (result.isConfirmed) {
                              this.setState({ update_AccDemo: null });
                         }
                    })
               }
          } catch (error) {
               console.log(error);
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     handleChangeAccountDemo = (e) => {
          let value = e.target.value;
          this.setState({ update_AccDemo: value, active: false });
          if (value === '') {
               this.setState({ active: true });
          }
     };

     OnclickOpenAccountDemoModal = (user_id, account_demo) => {
          // console.log(user_id,account_demo);
          this.setState({ openAccDemo: true, user_id, account_demo });
     };

     onClickCloseAccountDemo = () => {
          this.setState({ openAccDemo: false });
     };

     handleChangeCashback = (e, id, type) => {

          let value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
          let name = e.target.name;
          // console.log(`name: ${e.target.name} || value: ${value} || id:${id}`);

          // this.setState({ [e.target.name]: value, active: false });
          if (type === "standard") {
               this.setState(prevState => ({
                    data_get_cashback: prevState.data_get_cashback.map(
                         obj => (obj.account_type_name === name ? Object.assign(obj, { value_cb: value, }) : obj)
                    )
               }));
          } else {
               this.setState(prevState => ({
                    data_get_cashback: prevState.data_get_cashback.map(
                         obj => (obj.account_type_name_gold === name ? Object.assign(obj, { value_cb_gold: value, }) : obj)
                    )
               }));
          }

          if (value === '') {
               this.setState({ active: true });
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     getDataCashback = async (user_id) => {
          // console.log("user_id", user_id);
          let { data_get_cashback } = this.state;
          if (user_id) {
               try {
                    this.setState({ loading: true });
                    let res = await POST(GETCASHBACK, { user_id });
                    if (res.success) {
                         let data_cashback_all = JSON.parse(res.result.cash_back_array);

                         if (data_cashback_all.length > 0) {
                              // for (let index = 0; index < data_cashback_all.length; index++) {
                              // const element = data_cashback_all[index];
                              data_cashback_all.map((element, index) => {
                                   this.setState(prevState => ({
                                        data_get_cashback: prevState.data_get_cashback.map(
                                             obj => (obj.account_type_id === element.account_type_id ? Object.assign(obj, { get_cb: element.cashback, get_cb_gold: element.cashback_gold !== undefined ? element.cashback_gold : 0 }) : obj)
                                        )
                                   }));
                              })

                              // }
                              this.setState({ loading: false });
                         } else {
                              // console.log("data_get_cashback", data_get_cashback);
                              // for (let index = 0; index < data_get_cashback.length; index++) {
                              //      const element = data_get_cashback[index];
                              // this.setState(prevState => ({
                              //      data_get_cashback: prevState.data_get_cashback.map(
                              //           obj => (obj.id === Number(index + 1) ? Object.assign(obj, { get_cb: 0 }) : obj)
                              //      )
                              // }));
                              // }

                              data_cashback_all.map((element, index) => {
                                   this.setState(prevState => ({
                                        data_get_cashback: prevState.data_get_cashback.map(
                                             obj => (obj.id === Number(index + 1) ? Object.assign(obj, { get_cb: 0, get_cb_gold: 0 }) : obj)
                                        )
                                   }));
                              })

                              this.setState({ loading: false });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                              // timer: 6000, 
                              showConfirmButton: true
                         });
                    }
               } catch (e) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: e, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          }

     };

     OnclickOpenCashbackModal = (user_id, name, lastname) => {
          this.getDataCashback(user_id);
          this.setState({ openCashback: true, user_id, name: `${name} ${lastname}` });
     };

     onClickCloseCashback = () => {
          const { data_get_cashback } = this.state;
          data_get_cashback.map((element, index) => {
               this.setState(prevState => ({
                    data_get_cashback: prevState.data_get_cashback.map(
                         obj => (obj.account_type_id === element.account_type_id ? Object.assign(obj, { get_cb: 0, get_cb_gold: 0 }) : obj)
                    )
               }));
          })
          this.setState({ openCashback: false });
     };

     onClickUpdateCashback = async () => {
          const { user_id, data_get_cashback } = this.state;

          // console.log("data_get_cashback", data_get_cashback);


          let dataCashback = data_get_cashback.map((e) => {
               return {
                    account_type_id: e.account_type_id,
                    cashback: e.value_cb ? Number(e.value_cb) : Number(e.get_cb),
                    cashback_gold: e.value_cb_gold ? Number(e.value_cb_gold) : Number(e.get_cb_gold),
               }
          })

          // console.log("dataCashback", dataCashback);


          let check = true;

          // for (let index = 0; index < dataCashback.length; index++) {
          //      const element = dataCashback[index];

          //      if (element.cashback > 10 || element.cashback_gold > 10) {
          //           check = false;
          //           alert("Cashback มากกว่า 10 กรุณาตรวจสอบข้อมูล")
          //           break;
          //      }

          // }

          if (check) {

               try {
                    this.setState({ loading: true });
                    let update_cashback = await POST(EDITCASHBACK, { cash_back_array: JSON.stringify(dataCashback), user_id });
                    if (update_cashback.success) {
                         this.setState({ loading: false });
                         swal.fire({

                              title: "สำเร็จ", text: "Update cashback completed", icon: "success",
                              // timer: 6000, 
                              showConfirmButton: true
                         }).then((result) => {
                              if (result.isConfirmed) {

                                   window.location.reload();

                              }
                         })
                    } else {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "ไม่สำเร็จ", text: `${update_cashback.message}.`, icon: "warning",
                              // timer: 6000,
                              showConfirmButton: true
                         })
                    }
               } catch (error) {
                    console.log(error);
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: error, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }

          }

     };



     render() {
          const language = localStorage.getItem("language");
          const { page, data, dataCard, loading, role, open, openLimit, name_modal, lastname_modal, email_modal, openCashback, header_cashback, data_csv, openAccDemo, count_page } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    {role === "admin" && (
                         <Grid position={{ sm: "inherit", md: "inherit" }} top={{ sm: -90, md: -90 }} marginBottom={3}>
                              <CardHead language={language} dataCard={dataCard} />
                         </Grid>
                    )
                    }
                    <Grid>
                         <CardFooter
                              data_csv={data_csv}
                              role={role}
                              language={language}
                              data={data}
                              page={page}
                              handleChangePage={this.handleChangePage}
                              handleSearch={this.handleSearch}
                              state={this.state}
                              submitSearch={this.submitSearch}
                              menuItem={menuItem}
                              OnclickOpenRemark={this.OnclickOpenRemarkModal}
                              setIdToState={this.setIdToState}
                              OnclickOpenLimitAccountModal={this.OnclickOpenLimitAccountModal}
                              OnclickOpenAccountDemoModal={this.OnclickOpenAccountDemoModal}
                              OnclickOpenCashbackModal={this.OnclickOpenCashbackModal}
                              count_page={count_page}
                              getAllMember={this.getAllMember}
                              getAllMemberSale={this.getAllMemberSale}
                         />
                    </Grid>
                    <ModalAddRemark
                         open={open}
                         onClickClose={this.onClickClose}
                         state={this.state}
                         name={name_modal}
                         lastname={lastname_modal}
                         email={email_modal}
                         onClickUpdateRemark={this.onClickUpdateRemark}
                         handleChangeText={this.handleChangeText}
                    />
                    <ModalLimitAccount
                         open={openLimit}
                         onClickCloseLimit={this.onClickCloseLimit}
                         state={this.state}
                         onClickUpdateLimitAccount={this.onClickUpdateLimitAccount}
                         handleChangeLimitAccount={this.handleChangeLimitAccount}
                    />
                    <ModalCashback
                         open={openCashback}
                         onClickCloseCashback={this.onClickCloseCashback}
                         state={this.state}
                         onClickUpdateCashback={this.onClickUpdateCashback}
                         handleChangeCashback={this.handleChangeCashback}
                         header_cashback={header_cashback}
                    />
                    <ModalAccountDemo
                         open={openAccDemo}
                         onClickCloseAccountDemo={this.onClickCloseAccountDemo}
                         state={this.state}
                         onClickUpdateAccountDemo={this.onClickUpdateAccountDemo}
                         handleChangeAccountDemo={this.handleChangeAccountDemo}
                    />
                    <Loading open={loading} />
               </Container >
          );
     }
}

const menuItem = [
     { label: "Select Status", value: 99 },
     { label: "Success", value: 1 },
     { label: "Unverify", value: 2 },
     { label: "Unsuccess", value: 3 },
     { label: "Pending", value: 4 },
];
export default withRouter(Dashboard);
