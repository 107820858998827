import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import ListFinancial from "./ListFinancial/ListFinancial";
import { POST, TRANSACTION, APPROVEREJECTDEPOSIT, APPROVEREJECTWITHDRAW, POSTIMAGE, GETIMAGEPROFILE, GET, PAYMENT, MT4TRANSACTION, GETHEADERINFO } from "service";
import swal from "sweetalert2";
import DetailTaps from "./DetailTaps/DetailTaps";
import moment from "moment";
import Loading from "components/Loading/Loading";
import CardHead from "pages/Dashboard/CardHead/CardHead";
import wallet1 from "../../assets/logo/wallet1.png";
import wallet2 from "../../assets/logo/total-member.png";
import broker from "../../assets/logo/total-trading.png";
import reward from "../../assets/logo/total-order.png";

export class Financial extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data_deposit: [],
               data_withdraw: [],
               withdraw: [],
               deposit: [],
               page: 1,
               page_withdraw: 1,
               page_mt4: 1,
               search_text: "",
               open_modal: true,
               tap_value: 1,
               loading: false,
               date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
               date_end: new Date(),
               token: localStorage.getItem("token"),
               withdraw_type: "null",
               role: localStorage.getItem("role"),
               select_withdraw: [
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         placeholder: "Select Account Type",
                         placeholder_th: "เลือกประเภทแอคเคาท์",
                         name: "withdraw_type",
                         type: "text",
                         select: true,
                         selectname: "menuItem",
                         disabled: true,
                    },
               ],
               withdraw_type_search: "all",
               status_search: "all",
               deposit_type_search: "all",
               payment_type: [],
               data_mt4: [],
               data_deposit_csv: [],
               data_withdraw_csv: [],
               data_mt4_csv: [],
               dataCard: [
                    {
                         name: "Total wallet control",
                         money: "$ 0.00",
                         icon: wallet1,
                    },
                    {
                         name: "Total trading balance",
                         money: "$ 0.00",
                         icon: broker,
                    },
                    {
                         name: "Total gateway",
                         money: "฿ 0.00",
                         icon: reward,
                    },
               ],
          };
     }
     componentDidMount = () => {
          this.getTransactionWallet();
          this.getPayment();
          if (this.state.role == "admin") {
               this.getMT4Transaction();
               this.getCardHead();
          }

     };
     getCardHead = async () => {
          let { dataCard } = this.state;
          try {
               let data = await GET(GETHEADERINFO);
               if (data.success) {
                    let result = data.result;
                    dataCard[0].money = `$ ${Number(result.all_wallet).toFixed(2)}`;
                    dataCard[1].money = `$ ${Number(result.mt4_balance).toFixed(2)}`;
                    dataCard[2].money = `฿ ${Number(result.gateWayBalance).toFixed(2)}`;
                    this.setState({ dataCard })
               }
          } catch (error) {
               console.log("error", error);
          }
     };
     getPayment = async () => {
          try {
               let get_payment = await GET(PAYMENT);
               if (get_payment.success) {
                    const result = get_payment.result;
                    let payment_type = [
                         { label: "Select Payment Type", value: "null", disabled: true },
                         { label: "All", value: "all" },
                    ];
                    result.map((el) => payment_type.push({ label: el.payment_name, value: el.payment_gateway_id }));
                    this.setState({ payment_type });
               }
          } catch (error) { }
     };
     getTransactionWallet = async () => {
          const { date_start, date_end, withdraw_type_search, status_search, deposit_type_search } = this.state;
          let format_date_start = moment(date_start).format("YYYY-MM-DD");
          let format_date_end = moment(date_end).format("YYYY-MM-DD");
          try {
               this.setState({ loading: true });
               let depositTransaction = await POST(TRANSACTION, {
                    type: "deposit",
                    date_start: format_date_start,
                    date_end: format_date_end,
                    deposit_type: deposit_type_search,
                    status: status_search,
               });
               let withdrawTransaction = await POST(TRANSACTION, {
                    type: "withdraw",
                    date_start: format_date_start,
                    date_end: format_date_end,
                    withdraw_type: withdraw_type_search,
                    status: status_search,
               });
               if (depositTransaction.success) {
                    let sum = 0;
                    depositTransaction.result.map((el) => {
                         if (el.status === 1) {
                              sum = sum + el.receive;
                         }
                    });
                    let data_csv = [];
                    depositTransaction.result.map((el) => {
                         data_csv.push({
                              transaction_id: el.transaction_id,
                              user_id: el.user_id,
                              name: `${el.name} ${el.lastname}`,
                              email: el.email,
                              create: moment(el.created_at).format("DD/MM/YYYY"),
                              deposit: el.money,
                              receive: el.receive,
                              status: `${el.status === 1 ? "Confirm" : el.status === 2 ? "Pending" : el.status === 3 ? "Reject" : "Error"}`,
                         });
                    });
                    await this.setState({
                         data_deposit: depositTransaction.result,
                         deposit: depositTransaction.result,
                         sum_deposit: sum,
                         data_deposit_csv: data_csv,
                    });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: depositTransaction.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
               if (withdrawTransaction.success) {
                    let sum = 0;
                    withdrawTransaction.result.map((el) => {
                         if (el.status === 1) {
                              sum = sum + el.money;
                         }
                    });
                    let data_csv = [];
                    withdrawTransaction.result.map((el) => {
                         data_csv.push({
                              transaction_id: el.transaction_id,
                              user_id: el.user_id,
                              name: `${el.name} ${el.lastname}`,
                              email: el.email,
                              create: moment(el.created_at).format("DD/MM/YYYY"),
                              withdraw: el.money,
                              receive: el.receive,
                              status: `${el.status === 1 ? "Confirm" : el.status === 2 ? "Pending" : el.status === 3 ? "Reject" : "Error"}`,
                         });
                    });
                    await this.setState({
                         data_withdraw: withdrawTransaction.result,
                         withdraw: withdrawTransaction.result,
                         sum_withdraw: sum,
                         data_withdraw_csv: data_csv,
                    });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: withdrawTransaction.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     getMT4Transaction = async () => {
          const { date_start, date_end } = this.state;
          try {
               let mt4 = await POST(MT4TRANSACTION, { date_start, date_end });
               if (mt4.success) {
                    this.setState({ loading: true });
                    let result = mt4.result;
                    let data_csv = [];
                    result.data.map((el) => {
                         data_csv.push({ LOGIN: el.LOGIN, TICKET: el.TICKET, detail: el.detail, COMMENT: el.COMMENT, PROFIT: el.PROFIT, GROUP: el.GROUP });
                    });
                    this.setState({
                         data_mt4: result.data,
                         sum_mt4_withdraw: result.withdraw,
                         sum_mt4_deposit: result.deposit,
                         sum_mt4_profits: result.sumProfit,
                         data_mt4_csv: data_csv,
                    });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
          }
     };

     getImageSlip = async (path_image) => {
          const { token } = this.state;
          try {
               const res = await POSTIMAGE(GETIMAGEPROFILE, { token, path_image });
               const imageBlob = await res.blob();
               const image_slip = URL.createObjectURL(imageBlob);
               this.setState({ image_slip });
          } catch (error) {
               console.log("error", error);
          }
     };

     getImageBank = async (path_image) => {
          const { token } = this.state;
          try {
               const res = await POSTIMAGE(GETIMAGEPROFILE, { token, path_image });
               const imageBlob = await res.blob();
               const image_bank = URL.createObjectURL(imageBlob);
               this.setState({ image_bank });
          } catch (error) {
               console.log("error", error);
          }
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };
     handleChangePageWithdraw = (e, value) => {
          this.setState({ page_withdraw: value });
     };
     handleChangePageMt4 = (e, value) => {
          this.setState({ page_mt4: value });
     };
     handleSearch = (e) => {
          // console.log("e.target.value", e.target.name, e.target.value);
          this.setState({ [e.target.name]: e.target.value });
     };
     handleDateChange = (d, s, a) => {
          this.setState({ [a]: d });
     };
     submitSearch = async () => {
          await this.getTransactionWallet();
          let { search_text, deposit, withdraw } = this.state;
          let sum_data_deposit = 0;
          let sum_data_withdraw = 0;
          let data_deposit = deposit.filter((el) => (search_text ? `${el.name} ${el.lastname}`.toString().toLowerCase().includes(search_text.toLowerCase()) : el));
          let data_withdraw = withdraw.filter((el) => (search_text ? `${el.name} ${el.lastname}`.toString().toLowerCase().includes(search_text.toLowerCase()) : el));
          if (data_deposit) {
               data_deposit.map((el) => {
                    if (el.status === 1) {
                         sum_data_deposit = sum_data_deposit + el.receive;
                    }
               });
          }
          if (data_withdraw) {
               data_withdraw.map((el) => {
                    if (el.status === 1) {
                         sum_data_withdraw = sum_data_withdraw + el.money;
                    }
               });
          }
          if (this.state.role == "admin") {
               await this.getMT4Transaction();
          }
          await this.setState({ data_deposit, data_withdraw, sum_deposit: sum_data_deposit, sum_withdraw: sum_data_withdraw, page: 1, page_withdraw: 1, page_mt4: 1 });
     };
     approveRejectDeposit = async (status, transaction_id) => {
          const { note_deposit } = this.state;
          swal.fire({
               text: `ต้องการ ${status === 1 ? "Approve" : "Reject"}  หรือไม่`,
               icon: "question",
               showCancelButton: true,
               confirmButtonText: "Submit",
               cancelButtonText: "Cancel",
          }).then(async (result) => {
               if (result.isConfirmed) {
                    try {
                         this.setState({ loading: true });
                         let arDeposit = await POST(APPROVEREJECTDEPOSIT, { status, transaction_id, remark: note_deposit });
                         if (arDeposit.success) {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "สำเร็จ", icon: "success",
                                   // timer: 6000, 
                                   showConfirmButton: true
                              });
                              this.componentDidMount();
                         } else {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "ไม่สำเร็จ", text: arDeposit.message, icon: "warning",
                                   // timer: 6000, 
                                   showConfirmButton: true
                              });
                         }
                    } catch (error) {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "ไม่สำเร็จ", text: error, icon: "warning",
                              // timer: 6000, 
                              showConfirmButton: true
                         });
                    }
               }
          });
     };

     approveRejectWithdraw = (status, transaction_id) => {
          const { note_withdraw, withdraw_type } = this.state;
          if (withdraw_type !== "null") {
               swal.fire({
                    text: `ต้องการ ${status === 1 ? "Approved" : "Reject"}  หรือไม่`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
               }).then(async (result) => {
                    if (result.isConfirmed) {
                         try {
                              this.setState({ loading: true });
                              let arDeposit = await POST(APPROVEREJECTWITHDRAW, { status, transaction_id, remark: note_withdraw, withdraw_type });
                              if (arDeposit.success) {
                                   this.setState({ loading: false });
                                   swal.fire({
                                        title: "สำเร็จ", icon: "success",
                                        // timer: 6000, 
                                        showConfirmButton: true
                                   });
                                   this.componentDidMount();
                              } else {
                                   this.setState({ loading: false });
                                   swal.fire({
                                        title: "ไม่สำเร็จ", text: arDeposit.message, icon: "warning",
                                        // timer: 6000, 
                                        showConfirmButton: true
                                   });
                              }
                         } catch (error) {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "ไม่สำเร็จ", text: error, icon: "warning",
                                   // timer: 6000, 
                                   showConfirmButton: true
                              });
                         }
                    }
               });
          } else {
               swal.fire({
                    title: "ไม่สำเร็จ", text: "กรุณาเลือกวิธีถอน", icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };
     setTap = (e) => {
          this.setState({ tap_value: e });
     };

     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };
     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };

     render() {
          const language = localStorage.getItem("language");
          const {
               page,
               data_deposit,
               tap_value,
               data_withdraw,
               loading,
               page_withdraw,
               page_mt4,
               select_withdraw,
               payment_type,
               sum_withdraw,
               sum_deposit,
               data_deposit_csv,
               data_withdraw_csv,
               data_mt4_csv,
               dataCard,
               role
          } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    {role == "admin" ?
                         // <Grid sx={{ position: "inherit", top: -90 }}>
                         <Grid position={{ sm: "inherit", md: "inherit" }} top={{ sm: -90, md: -90 }} marginBottom={3}>
                              <CardHead language={language} dataCard={dataCard} />
                         </Grid> : null}
                    <Grid>
                         <ListFinancial
                              language={language}
                              data_deposit={data_deposit}
                              data_withdraw={data_withdraw}
                              page={page}
                              page_withdraw={page_withdraw}
                              page_mt4={page_mt4}
                              handleChangePage={this.handleChangePage}
                              handleChangePageWithdraw={this.handleChangePageWithdraw}
                              handleChangePageMt4={this.handleChangePageMt4}
                              handleSearch={this.handleSearch}
                              state={this.state}
                              submitSearch={this.submitSearch}
                              handleDateChange={this.handleDateChange}
                              approveRejectDeposit={this.approveRejectDeposit}
                              approveRejectWithdraw={this.approveRejectWithdraw}
                              tap_value={tap_value}
                              setTap={this.setTap}
                              handleDateStartChange={this.handleDateStartChange}
                              handleDateEndChange={this.handleDateEndChange}
                              getImageSlip={this.getImageSlip}
                              getImageBank={this.getImageBank}
                              select_withdraw={select_withdraw}
                              payment_type={payment_type}
                              sum_withdraw={sum_withdraw}
                              sum_deposit={sum_deposit}
                              data_deposit_csv={data_deposit_csv}
                              data_withdraw_csv={data_withdraw_csv}
                              data_mt4_csv={data_mt4_csv}
                              role={role}
                              setValue={(e) => this.setState(e)}
                         />
                    </Grid>
                    <Loading open={loading} />
               </Container>
          );
     }
}
const dataTable = [
     {
          id: 1,
          transaction: "DF-20210613223145",
          user: "Username",
          create: "2021-06-13 22:31:45",
          ip: "184.22.97.35",
          deposit: "3,350.00 THB",
          receive: "100.00 USD",
          status: "approved",
     },
     {
          id: 2,
          transaction: "DF-20210613223145",
          user: "Username",
          create: "2021-06-13 22:31:45",
          ip: "184.22.97.35",
          deposit: "3,350.00 THB",
          receive: "100.00 USD",
          status: "waiting",
     },
     {
          id: 3,
          transaction: "DF-20210613223145",
          user: "Username",
          create: "2021-06-13 22:31:45",
          ip: "184.22.97.35",
          deposit: "3,350.00 THB",
          receive: "100.00 USD",
          status: "waiting",
     },
     {
          id: 4,
          transaction: "DF-20210613223145",
          user: "Username",
          create: "2021-06-13 22:31:45",
          ip: "184.22.97.35",
          deposit: "3,350.00 THB",
          receive: "100.00 USD",
          status: "reject",
     },
     {
          id: 5,
          transaction: "DF-20210613223145",
          user: "Username",
          create: "2021-06-13 22:31:45",
          ip: "184.22.97.35",
          deposit: "3,350.00 THB",
          receive: "100.00 USD",
          status: "approved",
     },
     {
          id: 6,
          transaction: "DF-20210613223145",
          user: "Username",
          create: "2021-06-13 22:31:45",
          ip: "184.22.97.35",
          deposit: "3,350.00 THB",
          receive: "100.00 USD",
          status: "reject",
     },
];
export default withRouter(Financial);
