import { Accordion, AccordionSummary, Grid, Typography, Button, AccordionDetails } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import { StringSet } from "functions/Static";
import Sidebar from "./Sidebar";
import { createMuiTheme } from "@material-ui/core/styles";
import EditPolicyCard from "./EditPolicyCard";
const theme = createMuiTheme();

const ListPolicy = (props) => {
     const { data, onClickEdit, edit, onChangeTexteditEN, onChangeTexteditTH, state, onClickSubmit, onChangePDFEN, onChangePDFTH } = props;
     const classes = useStyles();
     return (
          <Grid container spacing={2}>
               <Grid item xs={3}>
                    <Sidebar title={"Menu"} pageName={"policy"} data={StringSet.policy.sidebar} />
               </Grid>
               <Grid item xs={9} className="question-page">
                    <HeaderTypography title="Policy" />
                    <EditPolicyCard
                         state={state}
                         onChangeTexteditEN={onChangeTexteditEN}
                         onChangeTexteditTH={onChangeTexteditTH}
                         onClickSubmit={onClickSubmit}
                         onChangePDFEN={onChangePDFEN}
                         onChangePDFTH={onChangePDFTH}
                    />
               </Grid>
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     fontStyleTitle: {
          fontSize: 18,
          fontWeight: 600,
          color: "#000",
     },
     fontStyleDetail: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
     },
}));

export default ListPolicy;
