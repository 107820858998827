import { Grid, Typography, TableContainer, Table, Pagination, TableCell, TableHead, TableRow, TableBody, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import moment from "moment";
const theme = createMuiTheme();

const GridTable = (props) => {
    const classes = useStyles();

    return (
        <>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {props.head.map((el, i) => {
                                return (
                                    <TableCell key={i} align={el.align || "left"}>
                                        <Typography noWrap>{el.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.body.map((el, i) => {
                            return (
                                <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell align="left">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.promotion_name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {moment(el.start).format("DD/MM/YYYY")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {moment(el.exprie).format("DD/MM/YYYY")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" onClick={() => props.onClickEdit(el.promotion_id)}>
                                        <Link
                                            component="button"
                                            sx={{ fontSize: 16, color: "#184B8E" }}
                                            onClick={() => props.onClickEdit(el.promotion_id)}
                                        >
                                            {props.language === "th" ? "แก้ไข" : "Edit"}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
            </Grid>
        </>

    );
};
const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
}));
export default GridTable;
