import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
// import ListFinancial from "./ListFinancial/ListFinancial";
import { POST, GET, GETIBTRANSACTIONBYDATE, GETIBTRANSACTION, SEARCHIBTRANSACTIONTEXT, GETIBTRANSACTIONSUMMARY } from "service";
import swal from "sweetalert2";
import moment from "moment";
import Loading from "components/Loading/Loading";
import ListIBTransaction from "./ListIBTransaction/ListIBTransaction";

export class IBTransaction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
            page: 1,
            loading: false,
            token: localStorage.getItem("token"),
            role: localStorage.getItem("role"),
            data: [],
            search_text: "",
            reciveIB: [],
            data_csv: [],
        };
    }
    componentDidMount = () => {
        if (this.state.role == "admin" || this.state.role == "normal") {
            this.getIBTransaction();
            this.getIBTransactionSummary();
        }
    };
    // getIBTransaction = async () => {
    //     try {
    //         this.setState({ loading: true });
    //         let ibTransaction = await GET(GETIBTRANSACTION);
    //         if (ibTransaction.success) {
    //             this.setState({ data: ibTransaction.result, loading: false });
    //         } else {
    //             swal.fire({
    //                 title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
    //                 // timer: 6000, 
    //                 showConfirmButton: true
    //             });
    //             this.setState({ loading: false });
    //         }
    //     } catch (error) {
    //         swal.fire({
    //             title: "ไม่สำเร็จ", text: error, icon: "warning",
    //             // timer: 6000, 
    //             showConfirmButton: true
    //         });
    //         this.setState({ loading: false });
    //     }
    // };
    getIBTransactionSummary = async () => {
        let { search_text, date_start, date_end } = this.state;
        try {
            this.setState({ loading: true });
            let ibTransaction = await POST(GETIBTRANSACTIONSUMMARY, { text_search: search_text, date_start, date_end });
            if (ibTransaction.success) {
                this.setState({ reciveIB: ibTransaction.result, loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };
    getIBTransaction = async () => {
        let { date_start, date_end } = this.state;
        try {
            this.setState({ loading: true });
            let ibTransaction = await POST(GETIBTRANSACTIONBYDATE, { date_start, date_end });
            if (ibTransaction.success) {
                let data_csv = [];
                ibTransaction.result.map((el) => {
                    data_csv.push({
                        ib_transaction_id: el.ib_transaction_id,
                        user_id: el.user_id,
                        username: `${el.name} ${el.lastname}`,
                        created_at: moment(el.created_at).format("DD/MM/YYYY"),
                        type: el.type === 1 ? "Receive" : "Transfer to wallet",
                        detail: el.detail,
                        receive: el.receive,
                        money: el.type === 2 ? Number(el.money * -1).toFixed(6) : Number(el.money).toFixed(6),
                    });
                });
                this.setState({ data: ibTransaction.result, data_csv, loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };
    submitSearchText = async () => {
        let { search_text, date_start, date_end } = this.state;
        this.getIBTransactionSummary(search_text, date_start, date_end);
        this.setState({ data: [] });
        try {
            this.setState({ loading: true });
            let ibTransaction = await POST(SEARCHIBTRANSACTIONTEXT, { text_search: search_text, date_start, date_end });
            if (ibTransaction.success) {
                let data_csv = [];
                ibTransaction.result.map((el) => {
                    data_csv.push({
                        ib_transaction_id: el.ib_transaction_id,
                        user_id: el.user_id,
                        username: `${el.name} ${el.lastname}`,
                        created_at: moment(el.created_at).format("DD/MM/YYYY"),
                        type: el.type === 1 ? "Receive" : "Transfer to wallet",
                        detail: el.detail,
                        receive: el.receive,
                        money: el.type === 2 ? Number(el.money * -1).toFixed(6) : Number(el.money).toFixed(6),
                    });
                });
                this.setState({ data: ibTransaction.result, data_csv, loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };
    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };
    handleDateChange = (d, s, a) => {
        this.setState({ [a]: d });
    };
    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };
    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    render() {
        const language = localStorage.getItem("language");
        const { page, data, loading, reciveIB, data_csv } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <ListIBTransaction
                        language={language}
                        data={data}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        state={this.state}
                        // submitSearch={this.submitSearch}
                        handleDateStartChange={this.handleDateStartChange}
                        handleDateEndChange={this.handleDateEndChange}
                        handleSearch={this.handleSearch}
                        submitSearchText={this.submitSearchText}
                        recive={reciveIB}
                        data_csv={data_csv}
                    />
                </Grid>
            </Container>
        );
    }
}
export default withRouter(IBTransaction);
