import { Grid, Button, Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import { GridTextField } from "components";
import { ip } from "service";
import no_img from "../../../assets/images/no-image.jpg";

const CardBanner = (props) => {
     const { onClickEdit, data, onChangeFile, textFiled, state, previewImage, handleChangeText, language } = props;
     console.log("data", data);
     console.log("onChangeFile", onChangeFile);
     console.log("textFiled", textFiled);
     console.log("state", state);
     console.log("previewImage", previewImage);
     console.log("handleChangeText", handleChangeText);

     return data.map((el, i) => (
          <Card sx={{ marginBottom: 2 }}>
               <CardContent>
                    <Grid container spacing={2}>
                         <Grid item xs={12} md={4}>
                              <CardMedia component="img" sx={{ height: "100%", width: "100%" }} alt={el.banner_id} image={ip + el.banner_img} />
                         </Grid>
                         <Grid container item xs={12} md={8}>
                              <Grid item xs={12}>
                                   {/* <Typography variant="h5" component="div">
                                        {el.title}
                                   </Typography> */}
                                   {/* <Typography variant="p" component="div">
                                        Link URL
                                   </Typography>
                                   <TextField placeholder="Link URL" sx={{ width: "100%" }} disabled={disable && index_disable === i ? false : true} /> */}
                                   {/* {el.disable ? (
                                        <GridTextField
                                             data={textFiled}
                                             state={state}
                                             image={el.disable ? previewImage : null}
                                             onChangeFile={onChangeFile}
                                             handleChange={handleChangeText}
                                        />
                                   ) : null} */}
                              </Grid>
                              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                                   {/* <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        onClick={() => onClickEdit(i, el.banner_id)}
                                   >
                                        {el.disable === true ? "Save" : "Edit"}
                                   </Button> */}
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        // onClick={submitSearch}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </Grid>
                         {/* --------------desktop_th------------ */}
                         <Grid item xs={12} md={4}>
                              <CardMedia component="img" sx={{ height: "100%", width: "100%" }} alt={el.banner_id} image={el.desktop_th ? ip + el.desktop_th : no_img} />
                         </Grid>
                         <Grid container item xs={12} md={8}>
                              {state.showChooseFile === "banner_desktop_th" ? (
                                   <Grid item xs={12}>
                                        {el.disable ? (
                                             <GridTextField
                                                  data={textFiled}
                                                  state={state}
                                                  image={el.disable ? previewImage : null}
                                                  onChangeFile={onChangeFile}
                                                  handleChange={handleChangeText}
                                             />
                                        ) : null}
                                   </Grid>
                              ) : null}
                              <Grid container justifyContent={"flex-end"} alignItems={"flex-end"}>
                                   <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", borderBottom: "solid 2px #fc0316", marginBottom: 2 }}>
                                        {language === "th" ? "ขนาดหน้าจอคอมพิวเตอร์(เนื้อหาภาษาไทย)" : "Desktop Size(Thai)"}
                                   </Typography>
                              </Grid>
                              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                                   <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        onClick={() => onClickEdit(i, el.banner_id, "banner_desktop_th")}
                                   >
                                        {el.disable === true && state.showChooseFile === "banner_desktop_th" ? "Save" : "Edit"}
                                   </Button>
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        // onClick={submitSearch}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </Grid>
                         {/* --------------desktop_en------------ */}
                         <Grid item xs={12} md={4}>
                              <CardMedia component="img" sx={{ height: "100%", width: "100%" }} alt={el.banner_id} image={el.desktop_en ? ip + el.desktop_en : no_img} />
                         </Grid>
                         <Grid container item xs={12} md={8}>
                              <Grid item xs={12}>
                                   {state.showChooseFile === "banner_desktop_en" ? (
                                        <Grid item xs={12}>
                                             {el.disable ? (
                                                  <GridTextField
                                                       data={textFiled}
                                                       state={state}
                                                       image={el.disable ? previewImage : null}
                                                       onChangeFile={onChangeFile}
                                                       handleChange={handleChangeText}
                                                  />
                                             ) : null}
                                        </Grid>
                                   ) : null}
                              </Grid>
                              <Grid container justifyContent={"flex-end"} alignItems={"flex-end"}>
                                   <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", borderBottom: "solid 2px #fc0316", marginBottom: 2 }}>
                                        {language === "th" ? "ขนาดหน้าจอคอมพิวเตอร์(เนื้อหาภาษาอังกฤษ)" : "Desktop Size(English)"}
                                   </Typography>
                              </Grid>
                              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                                   <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        onClick={() => onClickEdit(i, el.banner_id, "banner_desktop_en")}
                                   >
                                        {el.disable === true && state.showChooseFile === "banner_desktop_en" ? "Save" : "Edit"}
                                   </Button>
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        // onClick={submitSearch}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </Grid>
                         {/* --------------mobile_th------------ */}
                         <Grid item xs={12} md={4}>
                              <CardMedia component="img" sx={{ height: "100%", width: "100%" }} alt={el.banner_id} image={el.mobile_th ? ip + el.mobile_th : no_img} />
                         </Grid>
                         <Grid container item xs={12} md={8}>
                              {state.showChooseFile === "banner_mobile_th" ? (
                                   <Grid item xs={12}>
                                        {el.disable ? (
                                             <GridTextField
                                                  data={textFiled}
                                                  state={state}
                                                  image={el.disable ? previewImage : null}
                                                  onChangeFile={onChangeFile}
                                                  handleChange={handleChangeText}
                                             />
                                        ) : null}
                                   </Grid>
                              ) : null}
                              <Grid container justifyContent={"flex-end"} alignItems={"flex-end"}>
                                   <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", borderBottom: "solid 2px #fc0316", marginBottom: 2 }}>
                                        {language === "th" ? "ขนาดหน้าจอโทรศัพท์(เนื้อหาภาษาไทย)" : "Mobile Size(Thai)"}
                                   </Typography>
                              </Grid>
                              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                                   <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        onClick={() => onClickEdit(i, el.banner_id, "banner_mobile_th")}
                                   >
                                        {el.disable === true && state.showChooseFile === "banner_mobile_th" ? "Save" : "Edit"}
                                   </Button>
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        // onClick={submitSearch}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </Grid>
                         {/* --------------mobile_en------------ */}
                         
                         <Grid item xs={12} md={4}>
                              <CardMedia component="img" sx={{ height: "100%", width: "100%" }} alt={el.banner_id} image={el.mobile_en ? ip + el.mobile_en : no_img} />
                         </Grid>
                         <Grid container item xs={12} md={8}>
                              {state.showChooseFile === "banner_mobile_en" ? (
                                   <Grid item xs={12}>
                                        {el.disable ? (
                                             <GridTextField
                                                  data={textFiled}
                                                  state={state}
                                                  image={el.disable ? previewImage : null}
                                                  onChangeFile={onChangeFile}
                                                  handleChange={handleChangeText}
                                             />
                                        ) : null}
                                   </Grid>
                              ) : null}
                              <Grid container justifyContent={"flex-end"} alignItems={"flex-end"}>
                                   <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", borderBottom: "solid 2px #fc0316", marginBottom: 2 }}>
                                        {language === "th" ? "ขนาดหน้าจอโทรศัพท์(เนื้อหาภาษาอังกฤษ)" : "Mobile Size(English)"}
                                   </Typography>
                              </Grid>

                              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                                   <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        onClick={() => onClickEdit(i, el.banner_id, "banner_mobile_en")}
                                   >
                                        {el.disable === true && state.showChooseFile === "banner_mobile_en" ? "Save" : "Edit"}
                                   </Button>
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 16, width: "100%", borderRadius: 40, height: 40, margin: 2 }}
                                        // onClick={submitSearch}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </Grid>
                    </Grid>
               </CardContent>
          </Card>
     ));
};

export default CardBanner;
