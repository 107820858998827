import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { StringSet, textFiled } from "functions/Static";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { POST, ADDMARKETAPI, UPLOADIMAGEMARKETAPI, MATKETBYIDAPI, EDITMARKETAPI, ip } from "service";
import swal from "sweetalert2";
import SettingManage from "./Components/SettingManage";

class ManageMarket extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               image: "",
               path: window.location.pathname,
               loading: false,
          };
     }

     componentDidMount() {
          if (this.state.path !== StringSet.market.link) {
               this.getMarketWithId();
          }
     }

     getMarketWithId = async () => {
          try {
               this.setState({ loading: true });
               let market = await POST(MATKETBYIDAPI, { forex_id: this.props.match.params.id });
               if (market.success) {
                    let forex_name = JSON.parse(market.result.forex_name);
                    let forex_detail = JSON.parse(market.result.forex_detail);
                    let forex_img = market.result.forex_img;
                    this.setState({
                         name_en: forex_name.en,
                         name_th: forex_name.th,
                         file: forex_img,
                         previewImage: ip + forex_img,
                         detail_en: forex_detail.en,
                         detail_th: forex_detail.th,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: market.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     onChangeFile = (e) => {
          const reader = new FileReader();
          reader.onload = () => {
               if (reader.readyState === 2) {
                    this.setState({ previewImage: reader.result });
               }
          };
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ file: e.target.files[0].name, image: e.target.files[0] });
     };
     onClickAdd = async () => {
          const { name_th, name_en, detail_th, detail_en, file, image } = this.state;
          let dataSet = {
               name_th: name_th,
               name_en: name_en,
               detail_th: detail_th,
               detail_en: detail_en,
          };
          if (name_th && name_en && detail_th && detail_en && file) {
               try {
                    this.setState({ loading: true });
                    let addMarket = await POST(ADDMARKETAPI, dataSet);
                    if (addMarket.success) {
                         let form_data = new FormData();
                         form_data.append("forex_id", addMarket.result.forex_id);
                         form_data.append("forex_image", image);
                         let addImageMarket = await POST(UPLOADIMAGEMARKETAPI, form_data, true);
                         if (addImageMarket.success) {
                              this.setState({ loading: false });
                              swal.fire({ title: "สำเร็จ", text: "เพิ่ม Market สำเร็จ", icon: "success", 
                              // timer: 6000, 
                              showConfirmButton: true });
                              window.location.assign("/setting_marketing");
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "แจ้งเตือน", text: addImageMarket.message, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: addMarket.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } else {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: "กรุณากรอกข้อมูลให้ครบถ้วน", icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickEdit = async () => {
          const { name_th, name_en, detail_en, detail_th, image } = this.state;
          try {
               this.setState({ loading: true });
               let dataSet = {
                    name_th: name_th,
                    name_en: name_en,
                    detail_th: detail_th,
                    detail_en: detail_en,
                    forex_id: this.props.match.params.id,
               };
               let editMarket = await POST(EDITMARKETAPI, dataSet);
               if (editMarket.success) {
                    if (image.name) {
                         let form_data = new FormData();
                         form_data.append("forex_id", this.props.match.params.id);
                         form_data.append("forex_image", image);
                         let editImageMarket = await POST(UPLOADIMAGEMARKETAPI, form_data, true);
                         if (editImageMarket.success) {
                              this.setState({ loading: false });
                              swal.fire({ title: "สำเร็จ", text: "แก้ไข Market สำเร็จ", icon: "success", 
                              // timer: 6000, 
                              showConfirmButton: true });
                              window.location.assign("/setting_marketing");
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "สำเร็จ", text: editImageMarket.message, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไข Market สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         window.location.assign("/setting_marketing");
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: editMarket.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onChangePath = (path) => {
          if (path === "/setting_manage_market/add") {
               this.setState({
                    name_th: null,
                    name_en: null,
                    detail_th: null,
                    detail_en: null,
                    forex_id: null,
                    file: null,
                    previewImage: null,
               });
          }
          this.setState({ path });
     };

     render() {
          return (
               <Container maxWidth="xl">
                    <Loading open={this.state.loading} />
                    <SettingManage
                         onClickAdd={() => this.onClickAdd()}
                         onChangeFile={(e) => this.onChangeFile(e)}
                         handleChangeText={(e) => this.handleChangeText(e)}
                         textFiled={textFiled.add_marketing}
                         previewImage={this.state.previewImage}
                         state={this.state}
                         path={this.state.path}
                         onClickEdit={() => this.onClickEdit()}
                         StringSet={StringSet.market}
                         onChangePath={this.onChangePath}
                    />
               </Container>
          );
     }
}

export default withRouter(ManageMarket);
