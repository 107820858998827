import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETREPORTMT4, GETREPORTMT4TRANSACTION, POST } from "service";
import Report from "./Report/Report";
import swal from "sweetalert2";
import moment from "moment";

class TradingReport extends Component {
     constructor(props) {
          super(props);

          this.state = {
               date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
               date_end: new Date(),
               mt4_list: [
                    { label: "Select MT4 Accont", value: "null", disabled: true },
                    { label: "All", value: "all" },
               ],
               mt4: "null",
               page: 1,
               data_table: [],
               language: localStorage.getItem("language"),
               data_lot: {},
               data_mt4: {},
          };
     }

     componentDidMount = () => {
          this.getMT4Byuser();
     };

     getMT4Byuser = async () => {
          let { mt4_list } = this.state;
          try {
               let getmt4 = await POST(GETREPORTMT4, { user_id: Number(this.props.match.params.id) });
               if (getmt4.success) {
                    getmt4.result.mt4.map((el) => {
                         mt4_list.push({ label: el.mt4, value: el.mt4 });
                    });
                    this.setState({ mt4_list, data_mt4: getmt4.result });
               } else {
                    swal.fire({ title: "Warning", text: mt4_list.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               swal.fire({ title: "Warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickSearch = async () => {
          const { mt4, date_start, date_end, language } = this.state;
          let start = moment(date_start).format("YYYY-MM-DD");
          let stop = moment(date_end).format("YYYY-MM-DD");
          try {
               if (mt4 !== "null") {
                    let get_data = await POST(GETREPORTMT4TRANSACTION, { mt4, date_start: start, date_end: stop, user_id: Number(this.props.match.params.id) });
                    if (get_data.success) {
                         this.setState({ data_table: get_data.result.data, sum_lot: get_data.result.sumLot, data_lot: get_data.result });
                    } else {
                         swal.fire({ title: "Warning", text: get_data.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    swal.fire({ title: "Warning", text: `${language === "th" ? "กรุณาเลือก MT4" : "Please Select MT4"}`, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               swal.fire({ title: "Warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     handleChange = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleDateStartChange = (date) => {
          this.setState({ date_start: date });
     };

     handleDateEndChange = (date) => {
          this.setState({ date_end: date });
     };

     render() {
          const { mt4_list, page, data_table, language, sum_lot, data_lot, data_mt4 } = this.state;
          return (
               <Container>
                    <Report
                         language={language}
                         state={this.state}
                         mt4_list={mt4_list}
                         handleChange={this.handleChange}
                         handleDateStartChange={this.handleDateStartChange}
                         handleDateEndChange={this.handleDateEndChange}
                         page={page}
                         data={data_table}
                         sum_lot={sum_lot}
                         onClickSearch={this.onClickSearch}
                         handleChangePage={this.handleChangePage}
                         data_lot={data_lot}
                         data_mt4={data_mt4}
                    />
               </Container>
          );
     }
}

export default withRouter(TradingReport);
