import { Container } from "@material-ui/core";
import { StringSet, textFiled } from "functions/Static";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SettingManage from "./Components/SettingManage";
import { ip, POST, SERVICEBYIDAPI, EDITSERVICEAPI, UPLOADSERVICEIMAGEAPI, ADDSERVICEAPI } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

const path = window.location.pathname;

class SettingManageService extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               image: "",
               path: window.location.pathname,
               loading: false,
          };
     }

     componentDidMount() {
          if (this.state.path !== StringSet.service.link) {
               this.getServiceById();
          }
     }

     getServiceById = async () => {
          try {
               this.setState({ loading: true });
               let service = await POST(SERVICEBYIDAPI, { service_id: this.props.match.params.id });
               if (service.success) {
                    let service_name = JSON.parse(service.result.service_name);
                    let service_detail = JSON.parse(service.result.service_detail);
                    let service_image = service.result.service_img;
                    this.setState({
                         name_en: service_name.en,
                         name_th: service_name.th,
                         file: service_image,
                         previewImage: ip + service_image,
                         detail_en: service_detail.en,
                         detail_th: service_detail.th,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: service.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     onChangeFile = (e) => {
          const reader = new FileReader();
          reader.onload = () => {
               if (reader.readyState === 2) {
                    this.setState({ previewImage: reader.result });
               }
          };
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ file: e.target.files[0].name, image: e.target.files[0] });
     };
     onClickAdd = async () => {
          const { detail_en, detail_th, name_en, name_th, image } = this.state;
          try {
               this.setState({ loading: true });
               let addService = await POST(ADDSERVICEAPI, { service_id: this.props.match.params.id, name_en, name_th, detail_en, detail_th });
               if (addService.success) {
                    if (image.name) {
                         this.setState({ loading: true });
                         let form_data = new FormData();
                         form_data.append("service_id", this.props.match.params.id);
                         form_data.append("service_image", image);
                         let uploadImage = await POST(UPLOADSERVICEIMAGEAPI, form_data, true);
                         if (uploadImage.success) {
                              this.setState({ loading: false });
                              swal.fire({ title: "สำเร็จ", text: "แก้ไข service สำเร็จ", icon: "success", 
                              // timer: 6000, 
                              showConfirmButton: true });
                              window.location.assign("/setting_service");
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "แจ้งเตือน", text: uploadImage.message, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไข service สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         window.location.assign("/setting_service");
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: addService.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };
     onClickEdit = async () => {
          const { detail_en, detail_th, name_en, name_th, image } = this.state;
          try {
               this.setState({ loading: true });
               let editService = await POST(EDITSERVICEAPI, { service_id: this.props.match.params.id, name_en, name_th, detail_en, detail_th });
               if (editService.success) {
                    if (image.name) {
                         let form_data = new FormData();
                         form_data.append("service_id", this.props.match.params.id);
                         form_data.append("service_image", image);
                         let uploadImage = await POST(UPLOADSERVICEIMAGEAPI, form_data, true);
                         if (uploadImage.success) {
                              this.setState({ loading: false });
                              swal.fire({ title: "สำเร็จ", text: "แก้ไข service สำเร็จ", icon: "success", 
                              // timer: 6000, 
                              showConfirmButton: true });
                              window.location.assign("/setting_service");
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "แจ้งเตือน", text: uploadImage.message, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไข service สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         window.location.assign("/setting_service");
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: editService.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     render() {
          return (
               <Container maxWidth="xl">
                    <Loading open={this.state.loading} />
                    <SettingManage
                         onClickAdd={() => this.onClickAdd()}
                         onClickEdit={() => this.onClickEdit()}
                         onChangeFile={(e) => this.onChangeFile(e)}
                         handleChangeText={(e) => this.handleChangeText(e)}
                         textFiled={textFiled.add_service}
                         previewImage={this.state.previewImage}
                         state={this.state}
                         path={this.state.path}
                         StringSet={StringSet.service}
                    />
               </Container>
          );
     }
}

export default withRouter(SettingManageService);
