import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import Sidebar from "./Components/Sidebar";
import BannerCard from "./Components/Card";
import { textFiled } from "functions/Static";
import { GET, POST, GETBANNER, UPLOADBANNER } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";
// import logo from "../../assets/images/picture2.png";

export class Setting extends Component {
     constructor(props) {
          super(props);

          this.state = {
               banner: [],
               loading: false,
               showChooseFile:"",
          };
     }

     componentDidMount() {
          this.getBanner();
     }

     getBanner = async () => {
          try {
               this.setState({ loading: true });
               let banner = await GET(GETBANNER);
               if (banner.success) {
                    let newBanner = banner.result.map((e) => {
                         return { ...e, disable: false };
                    });
                    this.setState({
                         banner: newBanner,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: banner.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onChangeFile = (e) => {
          const reader = new FileReader();
          reader.onload = () => {
               if (reader.readyState === 2) {
                    this.setState({ previewImage: reader.result });
               }
          };
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ file: e.target.files[0].name, image: e.target.files[0] });
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     onClickEdit = async (i, banner_id ,size) => {
          this.setState({showChooseFile:size})
          const { banner, image } = this.state;
          banner[i].disable = !banner[i].disable;
          this.setState({ banner });
          if (image) {
               try {
                    console.log("eeeeee");
                    this.setState({ loading: true });
                    let form_data = new FormData();
                    form_data.append("banner_id", banner_id);
                    form_data.append(size, image);
                    let upload = await POST(UPLOADBANNER, form_data, true);
                    if (upload.success) {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไข Banner สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         this.setState({ previewImage: null, file: null, image: null ,showChooseFile:"" });
                         window.location.reload();
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: upload.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          }

     };
     render() {
          const language = localStorage.getItem("language");
          const { banner, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title={"Banner"} pageName={"banner"} />
                         </Grid>
                         <Grid item xs={9}>
                              <BannerCard
                                   language={language}
                                   onClickEdit={this.onClickEdit}
                                   data={banner}
                                   index_disable={this.state.index_disable}
                                   previewImage={this.state.previewImage}
                                   onChangeFile={(e) => this.onChangeFile(e)}
                                   handleChangeText={(e) => this.handleChangeText(e)}
                                   state={this.state}
                                   textFiled={textFiled.edit_banner}
                              />
                         </Grid>
                    </Grid>
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(Setting);
