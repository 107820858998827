import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, CardMedia, Typography, Grid, Button } from "@material-ui/core";
import logo_web from "../../assets/logo/Logo-color.png";

export class Login extends Component {
     propHistory = (path) => {
          this.props.history.push(path);
     };
     render() {
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl">
                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                         <CardMedia sx={{ height: 120, width: "auto", objectFit: "contain", mb: 2 }} component="img" alt="Logo" image={logo_web} />
                         <Typography sx={{ color: "#fff", fontSize: 50, fontWeight: 600, mb: 2 }}>ATS-forex</Typography>
                         <Button
                              variant="contained"
                              // color="error"
                              style={{ backgroundColor: "#4589C6" }} 
                              className="btn-style"
                              sx={{ maxWidth: 260, mb: 2 }}
                              onClick={() => this.propHistory("/login")}
                         >
                              {language === "th" ? "เข้าสู่ระบบ" : "Login"}
                         </Button>
                         <Button
                              variant="outlined"
                              color="error"
                              className="btn-style"
                              sx={{ maxWidth: 260 }}
                              onClick={() => this.propHistory("/register")}
                         >
                              {language === "th" ? "ลงทะเบียน" : "Register"}
                         </Button>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(Login);
