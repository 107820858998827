import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Stack, Button } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";

const ListMT = (props) => {
    const { data, language, page, handleChangePage, handleSearch, state, submitSearch, deleteAlert } = props;
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);
    return (
        <Grid container spacing={5} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>List MT4/MT5</Typography>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                    <Grid container sx={{ width: "40vw", mr: 2 }}>
                        <GridTextField
                            data={textFiled.search_text}
                            state={state}
                            handleChange={handleSearch}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    submitSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#4589C6" }}
                        //  className="btn-style"
                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                        onClick={submitSearch}
                    >
                        {language === "th" ? "ค้นหา" : "Search"}
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <GridTable head={headerTable.MTlist} body={dataList} count={count} page={page} handleChangePage={handleChangePage} deleteAlert={deleteAlert} />
            </Grid>
        </Grid>
    );
};
export default withRouter(ListMT);
