import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@material-ui/core";
import GridTableListCampaign from "./GridTableListCampaign";
import { headerTable } from "functions/Static";
import { createMuiTheme } from "@material-ui/core/styles";
import GridTableManage from "./GridTableManage";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CloseIcon from "@material-ui/icons/Close";
const theme = createMuiTheme();

const ListIBCampaign = (props) => {
    const {
        handleChangePage,
        handleEnableAdd,
        handleChangeSelect,
        handleChangeEditRebate,
        handleChangeSave,
        handleDeleteNav,
        handleEditCampaign,
        handleAction,
        handleDeleteCampaign,
        page,
        select_name_ib,
        data_select,
        nav_name_ib,
        data_tbl_manage,
        data_tbl_rebate,
        enable_add,
        enable_edit,
    } = props;

    const classes = useStyles();
    const count = Math.ceil(data_tbl_rebate.length > 0 && data_tbl_rebate.length / 10);
    const setPage = props.page - 1;
    const dataList = data_tbl_rebate.slice(setPage * 10, props.page * 10);
    const role = localStorage.getItem("role");
    const nav_length = nav_name_ib.length;

    return (
        <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} sx={{ borderBottom: "solid 2px #4589C6" }}>
                <Stack width="100%" direction="row" display="flex" justifyContent="space-between" my={1}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000" }}>IB Rebate Campaign</Typography>
                    {!enable_add ?
                        <Button
                            variant="outlined"
                            sx={{ fontSize: 16, fontWeight: 600 }}
                            disabled={enable_add}
                            onClick={handleEnableAdd}
                        >
                            + Add
                        </Button>
                        :
                        <Stack direction="row" spacing={3}>
                            <Button
                                variant="outlined"
                                sx={{ fontSize: 16, fontWeight: 600 }}
                                onClick={() => handleAction('save')}
                            >
                                save
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ fontSize: 16, fontWeight: 600 }}
                                onClick={() => handleAction('cancel')}
                            >
                                cancel
                            </Button>
                        </Stack>
                    }
                </Stack>
            </Grid>
            {(enable_add || enable_edit) &&
                <>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <InputLabel >เลือกชื่อ IB</InputLabel>
                            <Select
                                id="ib_rebate_campaign"
                                value={select_name_ib}
                                label="เลือกชื่อ IB"
                                onChange={handleChangeSelect}
                            >
                                <MenuItem value="" disabled>เลือกชื่อ IB</MenuItem>
                                {data_select.map((e, i) =>
                                    <MenuItem key={i} value={e} disabled={!e.ib_status}>{e.name} {e.lastname} ({e.email})</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" display="flex" alignItems="center" >
                            {props.nav_name_ib.map((e, i) =>
                                <Typography key={i + 1} className={classes.fontStyleNav}  >
                                    {e.name} {e.lastname} &nbsp;{nav_length - (i + 1) > 0 && `>`}&nbsp;{((i + 1) !== 1) && (nav_length === (i + 1)) && <CloseRoundedIcon onClick={() => handleDeleteNav(e.user_id)} sx={{ cursor: "pointer" }} />}
                                </Typography>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <GridTableManage body={data_tbl_manage.data_tbl} data_tbl_manage={data_tbl_manage} handleChangeEditRebate={handleChangeEditRebate} handleChangeSave={handleChangeSave} />
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <GridTableListCampaign
                    head={headerTable.ib_rebate_list_campaign}
                    body={dataList} count={count}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleEditCampaign={handleEditCampaign}
                    enable_add={enable_add}
                    handleDeleteCampaign={handleDeleteCampaign}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    fontStyleNav: {
        display: "flex",
        alignItems: "center",
        fontSize: 13,
        fontWeight: "bold",
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
    },
}));

export default withRouter(ListIBCampaign);
