import { Backdrop, Button, Fade, Grid, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { textFiled } from "functions/Static";
import { GridTextField } from "components";
const theme = createMuiTheme();

const ModalChangeUser = (props) => {
     const { open, onClickClose, state, onChangeText, menuItem, onClickChangeIB } = props;
     const classes = useStyles();
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               //    onClose={onClickClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container justifyContent="flex-end" xs={12}>
                              <Button onClick={() => onClickClose()} className={classes.buttonClose}>
                                   <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                              </Button>
                         </Grid>
                         <Grid container xs={12} justifyContent="center" spacing={2}>
                              <Typography fontWeight={600}>เปลี่ยนสายงาน</Typography>
                              <GridTextField state={state} data={textFiled.change_ib} handleChange={onChangeText} menuItem={menuItem} />
                              <Button onClick={() => onClickClose()} className={classes.btnStyle} variant="outlined" color="error">
                                   close
                              </Button>
                              <Button
                                   onClick={() => {
                                        onClickClose();
                                        onClickChangeIB();
                                   }}
                                   className={classes.btnStyle}
                                   variant="contained"
                                   style={{ backgroundColor:"#4589C6"}}
                              >
                                   Submit
                              </Button>
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};

const useStyles = makeStyles({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "10px 18px 25px",
          borderRadius: "8px",
     },
     btnStyle: { maxWidth: 250, fontSize: 16, width: "100%", borderRadius: 40, marginTop: 10, marginLeft: 5, marginRight: 5, marginTop: 50 },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
});
export default ModalChangeUser;
