import { Grid, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";

const HeaderTypography = (props) => {
     const { title } = props;
     return (
          <Grid xs={12}>
               <Typography sx={{ fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6", marginBottom: 2 }} fontSize={{ xs: 20, sm: 24, md: 24 }}>{title}</Typography>
          </Grid>
     );
};

export default withRouter(HeaderTypography);
