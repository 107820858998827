import { Button, Grid, Paper, Stack, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GridTextField } from "components";
import { headerTable, textFiled } from "functions/Static";
import DatePicker from "react-date-picker";
import GridTableSaleList from "./GridTableSaleList";

const SaleCard = (props) => {
     const { handleSearch, submitSearch, state, language, handleDateStartChange, handleDateEndChange, sale, deposit, withdraw, adminList, sumWithDraw, sumDeposit, role, allLot } =
          props;

     const classes = useStyles();
     return (
          <Grid container spacing={2} justifyContent="center" alignItems="center">
               {role === "admin" && (
                    <Grid item xs={12}>
                         <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>Sale list</Typography>
                         <Stack direction="row" alignItems="center" mt={2}>
                              <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                              <Grid container sx={{ width: "40vw", mr: 2 }}>
                                   <GridTextField data={textFiled.search_text_sale_list} state={state} handleChange={handleSearch} menuItem={sale} />
                              </Grid>
                              <Button
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                   onClick={submitSearch}
                              >
                                   {language === "th" ? "ค้นหา" : "Search"}
                              </Button>
                         </Stack>
                    </Grid>
               )}
               <Grid item container spacing={5}>
                    <Grid item xs={12} md={3}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   ยอดฝาก
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {deposit || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   ยอดถอน
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {withdraw || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
               </Grid>
               <Grid item container spacing={2}>
                    <Grid item xs={4}>
                         <Typography variant="body1">วันที่เริ่ม</Typography>
                         <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={4}>
                         <Typography variant="body1">วันที่สิ้นสุด</Typography>
                         <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                    </Grid>
               </Grid>
               <Grid item container spacing={5}>
                    <Grid item xs={12} md={4}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   ยอดฝาก
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {sumDeposit || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   ยอดถอน
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {sumWithDraw || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                         <Paper elevation={3} className={classes.paper}>
                              <Typography variant="h5" fontWeight={600}>
                                   Lot รวม
                              </Typography>
                              <Typography variant="h5" paddingLeft={5}>
                                   {allLot || "0.00"}
                              </Typography>
                         </Paper>
                    </Grid>
               </Grid>
               <Grid item xs={12}>
                    <GridTableSaleList head={headerTable.salelist} body={adminList} />
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles(() => ({
     paper: {
          padding: 20,
     },
}));
export default SaleCard;
