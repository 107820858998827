import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button, TextField } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";

const PromotionList = (props) => {
    //  const classes = useStyles();
    const { data, language, page, handleChangePage, handleSearch, state, onClickEdit } = props;
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);
    const [copied, setCopied] = useState(false);
    const role = localStorage.getItem("role");

    const onClickOpenAdd = () => {
        props.history.push("/promotion_add");
    };

    return (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={6}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000" }}>Promotion List</Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent={"flex-end"} >
                <Button
                    variant="contained"
                    style={{ backgroundColor: "#4589C6" }}
                    sx={{ maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40 }}
                    onClick={onClickOpenAdd}
                >
                    {language === "th" ? "เพิ่มโปรโมชัน" : "Add Promotion"}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ borderBottom: "solid 2px #4589C6" }}></Typography>
            </Grid>
            <Grid item mt={2} xs={12}>
                <GridTable head={headerTable.pormotion_list} body={dataList} count={count} page={page} handleChangePage={handleChangePage} onClickEdit={onClickEdit} />
            </Grid>
        </Grid>
    );
};
export default withRouter(PromotionList);
