import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button, TextField, Paper } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DatePicker from "react-date-picker";
import { CSVLink } from "react-csv";
import CsvDownload from "react-csv-downloader";

const ListIBTransaction = (props) => {
    //  const classes = useStyles();
    const { data, language, page, handleChangePage, state, submitSearch, handleDateStartChange, handleDateEndChange, handleSearch, submitSearchText, recive, data_csv } = props;
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = page - 1;
    const dataList = data.slice(setPage * 10, page * 10);
    const [copied, setCopied] = useState(false);
    const role = localStorage.getItem("role");

    const columns = [
        {
            id: 'ib_transaction_id',
            displayName: "IB Transaction ID"
        },
        {
            id: 'user_id',
            displayName: "User Id"
        },
        {
            id: 'username',
            displayName: "User"
        },
        {
            id: 'created_at',
            displayName: "Create"
        },
        {
            id: 'type',
            displayName: "Type"
        },
        {
            id: 'detail',
            displayName: "Detail"
        },
        {
            id: 'money',
            displayName: "Money"
        }
    ];

    return (
        <Grid container spacing={5} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6", my: 2 }}>IB Transaction</Typography>
                <Grid container item rowSpacing={2}>
                    <Grid item xs={12} sm={3} mr={1}>
                        <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={12} sm={3} mr={1}>
                        <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#4589C6" }}
                            sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                            onClick={submitSearchText}
                        >
                            {language === "th" ? "ค้นหา" : "Search"}
                        </Button>
                    </Grid> */}
                </Grid>
                <Grid container item rowSpacing={2} my={2} display="flex" alignItems="center">
                    <Grid item xs={12} sm={3} mr={1}>
                        <GridTextField
                            data={textFiled.search_text_MT4}
                            state={state}
                            handleChange={handleSearch}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    submitSearchText();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#4589C6" }}
                            //  className="btn-style"
                            sx={{ maxWidth: { xs: "95%", sm: 120, md: 120 }, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                            onClick={submitSearchText}
                        >
                            {language === "th" ? "ค้นหา" : "Search"}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container mb={2} mt={4} justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3} sx={{ width: "100%" }}>
                            <Grid container p={2} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" fontWeight={600}>
                                        รายได้ IB
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center">
                                    {recive.map((e) =>
                                        <Typography variant="h5">{Number(e.receiveIB).toFixed(4) || "0.00"} $</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                <CsvDownload
                    style={{ color: "#fff", textDecoration: "none" }}
                    filename={`IB_Transaction_Report_` + new Date().toLocaleString()}
                    extension='.csv'
                    columns={columns}
                    datas={data_csv}
                >
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#4589C6" }}
                        sx={{ maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                    >
                        export data
                    </Button>
                </CsvDownload>
            </Grid>
            <Grid item xs={12}>
                <GridTable head={headerTable.ibTransactionlist} body={dataList} count={count} page={page} handleChangePage={handleChangePage} />
            </Grid>
        </Grid >
    );
};
export default withRouter(ListIBTransaction);
