import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const Profile = (props) => {
     return (
          <Grid item container xs={12} direction="row" spacing={2}>
               {props.dataCard.map((el, i) => {
                    return (
                         <Grid item xs={12} md={3}>
                              <Card>
                                   <CardContent>
                                        <Typography variant="body" component="p">
                                             {el.title}
                                        </Typography>
                                        <Typography variant="h5" component="h2" sx={{ color: "#4589C6", fontWeight: 600 }}>
                                             ${el.amount ? Math.floor(Number(el.amount) * 100) / 100 : "0.00"}
                                             {/* ${el.amount ? el.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"} */}
                                        </Typography>
                                   </CardContent>
                              </Card>
                         </Grid>
                    );
               })}
          </Grid>
     );
};

export default Profile;
