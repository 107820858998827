import { Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { headerTable } from "functions/Static";
import { makeStyles } from "@material-ui/styles";

const GridTable = (props) => {
     const classes = useStyles();
     const count = Math.ceil(props.body.length > 0 && props.body.length / 10);
     const setPage = props.page - 1;
     const dataList = props.body.slice(setPage * 10, props.page * 10);

     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {headerTable.broker_income.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {dataList.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.ib_transaction_id}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.detail}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       ${Math.floor(Number(el.money) * 100) / 100}
                                                  </Typography>
                                             </TableCell>
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {dataList.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {dataList.length > 0 && <Pagination count={count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
});

export default GridTable;
