import { Grid, Typography } from "@material-ui/core";
import GridTableIBMemberRebate from "./GridTableIBMemberRebate";

const TableAccountTypeRebate = (props) => {
    const role = localStorage.getItem("role");
    const count = Math.ceil(props.data_network.length > 0 && props.data_network.length / 10);
    const setPage = props.page - 1;
    const dataList = props.data_network.slice(setPage * 10, props.page * 10);

    return (
        <Grid container spacing={2}>
            <Grid xs={12} item>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>IB Network</Typography>
            </Grid>
            {props.data_header_profile.map((e, i) =>
                <Grid key={i + 1} item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
                        {e.name} {e.lastname} ( {props.data_header.count_team ? props.data_header.count_team : 0} )
                    </Typography>
                </Grid>
            )}
            <Grid xs={12} item>
                <GridTableIBMemberRebate
                    data={props.data_network}
                    nav_sub_ib={props.nav_sub_ib}
                    state={props.state}
                    userId={props.userId}
                    onClickNetworkChild={props.onClickNetworkChild}
                    onClickNetworkChildBack={props.onClickNetworkChildBack}
                    page={props.page}
                    handleChangePage={props.handleChangePage}
                    body={dataList}
                    account_type_rebate={props.account_type_rebate}
                    getAccountRebate={props.getAccountRebate}
                    onClickEditAbleRebate={props.onClickEditAbleRebate}
                    handleChangeEditRebate={props.handleChangeEditRebate}
                    saveEditRebate={props.saveEditRebate}
                />
            </Grid>
        </Grid>
    );
};

export default TableAccountTypeRebate;
