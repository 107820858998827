import { Container, Grid, Button, Typography, TextField, InputAdornment } from "@material-ui/core";
import React, { Component } from "react";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import { withRouter } from "react-router-dom";
import { POST, WITHDRAWCOMPANY } from "service";
import Sidebar from "./Components/Sidebar";
import Loading from "components/Loading/Loading";
import swal from "sweetalert2";

class SettingWithdraw extends Component {
     constructor(props) {
          super(props);

          this.state = {
               money: null,
               withdraw: null,
               open: false,
               loading: false,
          };
     }
     componentDidMount = () => {
          //    this.getWithdrawConfig();
     };

     handleSubmit = async () => {
          const { money } = this.state;
          const language = localStorage.getItem("language");
          try {
               if (money != null && money != 0 && money > 0) {
                    swal.fire({
                         title: `${language === "th" ? "ยืนยันการถอนเงิน" : "Confirmation of withdrawal"}`,
                         text: `${language === "th" ? "จำนวน" : "Amount"} : ${money}`,
                         icon: "warning",
                         showCancelButton: true,
                         confirmButtonColor: "#3085d6",
                         cancelButtonColor: "#d33",
                         confirmButtonText: "Yes",
                    }).then(async (result) => {
                         if (result.isConfirmed) {
                              try {
                                   let withdrawCompany = await POST(WITHDRAWCOMPANY, { money });
                                   if (withdrawCompany.success) {
                                        swal.fire(`${language === "th" ? "สำเร็จ!" : "Success!"}`, `${language === "th" ? "ถอนเงินสำเร็จ" : "Successful withdrawal"}`, "success");
                                   } else {
                                        swal.fire(`${language === "th" ? "ไม่สำเร็จ!" : "UnSuccess!"}`, `${withdrawCompany.message}`, "error");
                                   }
                              } catch (error) {
                                   swal.fire(`${language === "th" ? "ไม่สำเร็จ!" : "UnSuccess!"}`, `${error}`, "error");
                              }
                         }
                    });
               }
          } catch (error) {
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     render() {
          const language = localStorage.getItem("language");
          const { loading, money } = this.state;
          // console.log(money);
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title="Withdraw" pageName="Withdraw" />
                         </Grid>
                         <Grid item xs={9}>
                              <HeaderTypography title={"Withdraw"} />

                              <Grid item md={12} xs={12} container spacing={2} alignContent="flex-start">
                                   {/* <Grid item xs={12}>
                                        <Typography>{language === "th" ? "สกุลเงิน" : "Currency"}</Typography>
                                   </Grid> */}
                                   <Grid item container xs={10} spacing={2}>
                                        <Grid item className="typography-profile pt-0" xs={6}>
                                             <Typography>{language === "th" ? "การถอนเงิน" : "Withdrawal"}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                             <TextField
                                                  InputProps={{
                                                       endAdornment: <InputAdornment position="end">฿</InputAdornment>,
                                                  }}
                                                  fullWidth
                                                  size="small"
                                                  type="number"
                                                  placeholder="0.00"
                                                  name="withdraw"
                                                  onChange={(e) => {
                                                       this.setState({ money: e.target.value });
                                                  }}
                                                  value={money}
                                             />
                                        </Grid>
                                   </Grid>
                                   <Grid item xs={12} container justifyContent="center" alignContent="flex-end" marginTop={10}>
                                        <Button variant="contained" style={{ backgroundColor: "#4589C6" }} className="btn-style" sx={{ maxWidth: 260 }} onClick={this.handleSubmit}>
                                             {language === "th" ? "ถอนเงิน" : "Withdrawal Funds"}
                                        </Button>
                                   </Grid>
                              </Grid>
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(SettingWithdraw);
