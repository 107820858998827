import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Pagination, Menu, MenuItem, Fade, Modal, InputAdornment, TextField, Backdrop, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { headerTable } from "../../../functions/Static";
import WarningIcon from "@material-ui/icons/Warning";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";


import {
     ADDCREDIT,
     GET,
     POST,

} from "service";

import swal from "sweetalert2";


const TableGrid = (props) => {
     const { language } = props;
     const classes = useStyles();
     const count = Math.ceil(props.body.length > 0 && props.body.length / 10);
     const setPage = props.page - 1;
     const dataList = props.body.slice(setPage * 10, props.page * 10);

     const role = localStorage.getItem("role");
     const header_tbl = role === "admin" ? headerTable.tradingAccount_admin : headerTable.tradingAccount;

     const [open, setOpen] = React.useState(false);
     const handleOpen = (mt4) => {
          setOpen(true)
          setMt4(mt4)
     }
     const handleCloseMd = () => {
          setOpen(false)
          setMt4(null)
          setAmount(null)
     }
     const [amount, setAmount] = useState(null)
     const [mt4, setMt4] = useState(null)


     const onClickAddCredit = async () => {
          setOpen(false)
          try {
               if (amount) {
                    swal.fire({
                         text: `ต้องการเพิ่มเครดิตจำนวน ${amount} หรือไม่`,
                         icon: "question",
                         showCancelButton: true,
                         confirmButtonText: "Submit",
                         cancelButtonText: "Cancel",
                    }).then(async (result) => {
                         if (result.isConfirmed) {
                              // this.setState({ loading: true });
                              let addCredit = await POST(ADDCREDIT, { money: amount, mt4: mt4 });
                              if (addCredit.success) {
                                   swal.fire({
                                        title: "Success",
                                        text: "เพิ่มเครดิตสำเร็จ",
                                        icon: "success",
                                        // timer: 6000,
                                        showConfirmButton: true,
                                   });
                                   // this.setState({ loading: false });
                                   window.location.reload();
                              } else {
                                   swal.fire({
                                        title: "Warning", text: addCredit.message, icon: "warning",
                                        // timer: 6000, 
                                        showConfirmButton: true
                                   });
                                   // this.setState({ loading: false });
                              }
                         } else {
                              setAmount(null)
                              setMt4(null)
                         }
                    });
               } else {
                    // this.setState({ loading: false });
                    swal.fire({
                         title: "Warning",
                         text: "กรุณากรอกจำนวนเงินที่ต้องการ",
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               // this.setState({ loading: false });
          }
     };

     const handleSearch = (e) => {
          // this.setState({ [e.target.name]: e.target.value });
          setAmount(e.target.value)
     };





     return (
          <Grid className="table-btn">
               <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleCloseMd}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                         timeout: 500,
                    }}
               >
                    <Fade in={open}>
                         <Grid className={classes.boxStyle}>
                              <Grid container rowSpacing={3} justifyContent="center" alignItems="center">
                                   <Grid container justifyContent="flex-end" xs={12}>
                                        <Button onClick={() => handleCloseMd()} className={classes.buttonClose}>
                                             <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                                        </Button>
                                   </Grid>
                                   <Typography sx={{ fontSize: 18 }}>{language === "th" ? "เพิ่มเครดิต" : "Add Credit"}</Typography>
                                   <Grid item container spacing={2} justifyContent={"center"}>
                                        <Grid item xs={11} spacing={2}>
                                             <Typography sx={{ fontSize: 16, mb: 1 }}>{language === "th" ? "จำนวน" : "Amount"}</Typography>
                                             <TextField
                                                  InputProps={{
                                                       endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                                  }}
                                                  fullWidth
                                                  size="small"
                                                  type="number"
                                                  placeholder="0.00"
                                                  name="amount"
                                                  value={amount}
                                                  onChange={handleSearch}
                                             />
                                        </Grid>
                                        <Button
                                             variant="contained"
                                             style={{ backgroundColor: "#4589C6" }}
                                             // onClick={async () => {
                                             //      await onClickAddDemo();
                                             //      handleCloseModal();
                                             // }}
                                             // disabled={loading}
                                             sx={{ m: 1, mt: 2, minWidth: 130 }}
                                             onClick={async () => { await onClickAddCredit() }}
                                        >
                                             {language === "th" ? "ตกลง" : "Submit"}
                                        </Button>
                                        <Button
                                             variant="outlined"
                                             color="error"
                                             onClick={() => {
                                                  handleCloseMd();
                                             }}
                                             // disabled={loading}
                                             sx={{ m: 1, mt: 2, minWidth: 130 }}
                                        >
                                             {language === "th" ? "ยกเลิก" : "Cancel"}
                                        </Button>
                                   </Grid>
                              </Grid>
                         </Grid>
                    </Fade>
               </Modal>


               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {header_tbl.map((el, i) => {
                                        return (
                                             <TableCell key={i} align={el.align || "left"}>
                                                  <Typography noWrap>{el.label}</Typography>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {dataList.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.mt4}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.name} {el.lastname}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.email}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {Math.floor(Number(el.balance) * 100) / 100}
                                                  </Typography>
                                             </TableCell>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.account_type_name}
                                                  </Typography>
                                             </TableCell>
                                             {role === "admin" &&
                                                  <TableCell>
                                                       {/* <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {el.account_type_name}
                                                  </Typography> */}
                                                       <Typography >
                                                            <Button
                                                                 // onClick={(event) => handleClick(event)}
                                                                 variant="contained"
                                                                 style={{ backgroundColor: "#4589C6" }}
                                                                 sx={{ fontSize: 16, borderRadius: 40, }}
                                                                 onClick={() => handleOpen(el.mt4)}
                                                            >
                                                                 {/* <MoreHorizIcon /> */}
                                                                 + Add
                                                            </Button>



                                                       </Typography>
                                                  </TableCell>
                                             }
                                             {/* <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.ip}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.wallet}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.tranfer}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.tranferBy}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                            {el.status === "success" ? (
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    sx={{ maxWidth: 50,  width: "100%", borderRadius: 2, height: 30, margin: 2, fontSize: 12 }}
                                                    // onClick={submitSearch}
                                                >
                                                    Success
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    sx={{ maxWidth: 50,  width: "100%", borderRadius: 2, height: 30, margin: 2, fontSize: 12 }}
                                                    // onClick={submitSearch}
                                                >
                                                    <WarningIcon style={{ fontSize: 16, marginRight: 2 }} /> Fail
                                                </Button>
                                            )}
                                        </Typography>
                                    </TableCell> */}
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {dataList.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {dataList.length > 0 && <Pagination count={count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "40px 18px",
          borderRadius: "8px",
          marginTop: "20px",
     },
});



export default withRouter(TableGrid);
