import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GETIBACCOUNTREBATE, GETIBHEADER, EDITIBACCOUNTREBATE, POST } from "service";
import swal from "sweetalert2";
import TableAccountTypeRebate from "./TableIB/TableAccountTypeRebate";

class IBDetailRebate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            loading: false,
            nav_sub_ib: [],
            user_id: this.props.match.params.id,
            account_type_rebate: [],
            edit_rebate: false,
            data_header_profile: [],
            data_header: [],
            date_start: new Date(),
            date_end: new Date(),
        };
    }

    componentDidMount = () => {
        this.getHeader();
        this.getAccountRebate(this.props.match.params.id);
    };

    getHeader = async () => {
        let { date_start, date_end } = this.state;
        let date_start_format = moment(date_start).format("YYYY-MM-DD");
        let date_end_format = moment(date_end).format("YYYY-MM-DD");
        // console.log('dataheaer2')
        try {
            let amount = await POST(GETIBHEADER, { date_start: date_start_format, date_end: date_end_format, user_id: this.props.match.params.id });
            if (amount.success) {
                this.setState({ data_header: amount.result })
            }
        } catch (error) {
            console.log(error)
        }
    }

    onClickNetworkChild = async (user_id, name, lastname) => {

        const { nav_sub_ib } = this.state;

        try {
            this.setState({ loading: true });
            let network = await POST(GETIBACCOUNTREBATE, { user_id });
            if (network.success) {
                nav_sub_ib.push({
                    user_id,
                    name,
                    lastname,
                })
                this.setState({ account_type_rebate: network.result, user_id });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: network.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    onClickNetworkChildBack = async (user_id) => {

        const { nav_sub_ib } = this.state;

        try {
            this.setState({ loading: true });
            let network = await POST(GETIBACCOUNTREBATE, { user_id });
            if (network.success) {
                const indexObj = nav_sub_ib.findIndex(el => (el.user_id === user_id))
                this.setState({ nav_sub_ib: nav_sub_ib.slice(0, indexObj + 1) });
                this.setState({ account_type_rebate: network.result, user_id });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: network.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
        }
    };

    getAccountRebate = async (user_id) => {
        try {
            this.setState({ loading: true });
            let res = await POST(GETIBACCOUNTREBATE, { user_id });
            if (res.success) {
                let nav_sub_ib = [];
                let data_header_profile = []
                const { user_id, name, lastname } = res.result;
                nav_sub_ib.push({ user_id, name, lastname })
                data_header_profile.push({ user_id, name, lastname })
                this.setState({ account_type_rebate: res.result, nav_sub_ib, data_header_profile });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    saveEditRebate = async (data = {}) => {

        // console.log(data);
        const { user_id } = this.state;

        try {
            this.setState({ loading: true });
            let res = await POST(EDITIBACCOUNTREBATE, { ...data });
            if (res.success) {
                swal.fire({
                    title: "Success",
                    text: `${res.result.message}`,
                    icon: "success",
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getAccountRebate(user_id);
                    }
                })
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "Warning",
                    text: `Edit Rebate Fail`,
                    icon: "warning",
                    showConfirmButton: true,
                })
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    onClickEditAbleRebate = (user_id, i) => {
        const { account_type_rebate } = this.state;

        let newData = account_type_rebate.data_tbl.map(item => {
            if (item.user_id === user_id) {
                const updatedEdit = item.rebate.map((rebate, index) => {
                    if (index === i) {
                        return {
                            ...rebate,
                            edit_rebate: !rebate.edit_rebate
                        };
                    }
                    return rebate;
                });
                return {
                    ...item,
                    rebate: updatedEdit
                };
            }
            return item;
        });

        this.setState({ account_type_rebate: { ...account_type_rebate, data_tbl: newData } });

    };

    handleChangeEditRebate = (e, i, user_id) => {

        const { account_type_rebate } = this.state;
        let name = e.target.name;
        let value = Number(e.target.value);

        let newData = account_type_rebate.data_tbl.map(item => {
            if (item.user_id === user_id) {
                const updatedRebate = item.rebate.map((rebate, index) => {
                    if (index === i) {
                        return {
                            ...rebate,
                            [name]: value
                        };
                    }
                    return rebate;
                });
                return {
                    ...item,
                    rebate: updatedRebate
                };
            }
            return item;
        });

        // console.log("newData", newData);

        this.setState({ account_type_rebate: { ...account_type_rebate, data_tbl: newData } });

    };

    render() {
        const { page, loading, nav_sub_ib, account_type_rebate, data_header_profile, data_header } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                {(account_type_rebate && account_type_rebate.data_tbl)
                    &&
                    <TableAccountTypeRebate
                        data_network={account_type_rebate.data_tbl}
                        nav_sub_ib={nav_sub_ib}
                        data_header_profile={data_header_profile}
                        data_header={data_header}
                        state={this.state}
                        userId={this.props.match.params.id}
                        onClickNetworkChild={this.onClickNetworkChild}
                        onClickNetworkChildBack={this.onClickNetworkChildBack}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        account_type_rebate={account_type_rebate}
                        getAccountRebate={this.getAccountRebate}
                        onClickEditAbleRebate={this.onClickEditAbleRebate}
                        handleChangeEditRebate={this.handleChangeEditRebate}
                        saveEditRebate={this.saveEditRebate}
                    />
                }
            </Container>
        );
    }
}

export default withRouter(IBDetailRebate);
