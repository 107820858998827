import { Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { StringSet, textFiled } from "../../functions/Static";
import SettingManage from "./Components/SettingManage";
import { POST, UPLOADIMAGENEWS, ADDNEWSAPI, NEWSADMINBYID, EDITNEWS, ip } from "service";
import swal from "sweetalert2";
import "react-quill/dist/quill.snow.css";
import Loading from "components/Loading/Loading";

class SettingNews extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               description_en: "",
               description_th: "",
               subject_en: "",
               subject_th: "",
               value_en: "",
               value_th: "",
               news_image: "",
               path: window.location.pathname,
               loading: false,
          };
     }

     componentDidMount() {
          if (this.state.path !== StringSet.news.link) {
               this.getNewsById();
          }
     }

     getNewsById = async () => {
          try {
               this.setState({ loading: true });
               let news = await POST(NEWSADMINBYID, { news_id: this.props.match.params.id });
               if (news.success) {
                    let news_name = JSON.parse(news.result.news_name);
                    let news_detail = JSON.parse(news.result.news_detail);
                    let content = JSON.parse(news.result.content);
                    let news_img = news.result.news_img;
                    this.setState({
                         subject_en: news_name.en,
                         subject_th: news_name.th,
                         description_en: news_detail.en,
                         description_th: news_detail.th,
                         value_en: content.en,
                         value_th: content.th,
                         previewImage: ip + news_img,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: news.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     onChangeFile = (e) => {
          const reader = new FileReader();
          reader.onload = () => {
               if (reader.readyState === 2) {
                    this.setState({ previewImage: reader.result });
               }
          };
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ file: e.target.files[0].name, image: e.target.files[0] });
     };

     onClickAdd = async () => {
          const { description_en, description_th, subject_en, subject_th, value_en, value_th, image } = this.state;
          if (description_en && description_th && subject_en && subject_th && value_en && value_th && image) {
               try {
                    this.setState({ loading: true });
                    let addNews = await POST(ADDNEWSAPI, {
                         name_th: subject_th,
                         name_en: subject_en,
                         detail_th: description_th,
                         detail_en: description_en,
                         content_th: value_th,
                         content_en: value_en,
                    });
                    if (addNews.success) {
                         this.setState({ loading: true });
                         let form_data = new FormData();
                         form_data.append("news_id", addNews.result.news_id);
                         form_data.append("news_image", image);
                         let uploadImage = await POST(UPLOADIMAGENEWS, form_data, true);
                         if (uploadImage.success) {
                              this.setState({ loading: false });
                              swal.fire({ title: "สำเร็จ", text: "เพิ่มข่าวสำเร็จ", icon: "success", 
                              // timer: 6000, 
                              showConfirmButton: true });
                              window.location.assign("/setting_news");
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "แจ้งเตือน", text: uploadImage.message, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: addNews.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } else {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: "กรุณากรอกข้อมูลให้ครบถ้วน", icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };
     onClickEdit = async () => {
          const { description_en, description_th, subject_en, subject_th, value_en, value_th, image } = this.state;
          let dataSet = {
               name_th: subject_th,
               name_en: subject_en,
               detail_th: description_th,
               detail_en: description_en,
               content_th: value_th,
               content_en: value_en,
               news_id: this.props.match.params.id,
          };
          if (description_en && description_th && subject_en && subject_th && value_en && value_th) {
               try {
                    this.setState({ loading: true });
                    let editNews = await POST(EDITNEWS, dataSet);
                    if (editNews.success) {
                         if (image) {
                              this.setState({ loading: true });
                              let form_data = new FormData();
                              form_data.append("news_id", this.props.match.params.id);
                              form_data.append("news_image", image);
                              let uploadImage = await POST(UPLOADIMAGENEWS, form_data, true);
                              if (uploadImage.success) {
                                   this.setState({ loading: false });
                                   swal.fire({ title: "สำเร็จ", text: "แก้ไขข่าวสำเร็จ", icon: "success", 
                                   // timer: 1800, 
                                   showConfirmButton: true });
                                   window.location.assign("/setting_news");
                              } else {
                                   this.setState({ loading: false });
                                   swal.fire({ title: "แจ้งเตือน", text: uploadImage.message, icon: "warning", 
                                   // timer: 6000, 
                                   showConfirmButton: true });
                              }
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "สำเร็จ", text: "แก้ไขข่าวสำเร็จ", icon: "success", 
                              // timer: 6000, 
                              showConfirmButton: true });
                              window.location.assign("/setting_news");
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: editNews.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } else {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: "กรุณากรอกข้อมูลให้ครบถ้วน", icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onChangeTexteditEN = (e) => {
          this.setState({ value_en: e });
     };
     onChangeTexteditTH = (e) => {
          this.setState({ value_th: e });
     };

     render() {
          const path = window.location.pathname;
          const { previewImage, value_en, value_th, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <SettingManage
                         handleChangeText={(e) => this.handleChangeText(e)}
                         onChangeFile={(e) => this.onChangeFile(e)}
                         textFiled={textFiled.add_news}
                         state={this.state}
                         previewImage={previewImage}
                         path={path}
                         onClickAdd={() => this.onClickAdd()}
                         onClickEdit={() => this.onClickEdit()}
                         StringSet={StringSet.news}
                         valueEdit_en={value_en}
                         valueEdit_th={value_th}
                         onChangeTexteditEN={(e) => this.onChangeTexteditEN(e)}
                         onChangeTexteditTH={(e) => this.onChangeTexteditTH(e)}
                    />
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(SettingNews);
