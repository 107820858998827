import React from "react";
import { MainHeader, MinorHeader } from "../../components/index";
import { Grid } from "@material-ui/core";
import TitleHeader from "./TitleHeader";
// import ThirdHeader from "components/Header/ThirdHeader";

const PageLayout = (props) => {
     const language = localStorage.getItem("language");
     return (
          <div>
               <MainHeader language={language} data={props.data_admin} />
               <MinorHeader language={language} />
               {/* <ThirdHeader language={language} /> */}
               <TitleHeader language={language} />
               <Grid sx={{ position: "relative", background: "white", width: "100vw", pt: 5, pb: 10 }}>
                    {props.children}
               </Grid>
               {/* <Footer language={language} /> */}
          </div>
     );
};
export default PageLayout;
