import { Button, Grid, TextField, Typography } from "@material-ui/core";
import ReactQuill from "react-quill";

const EditPolicyCard = (props) => {
     const { state, onChangeTexteditEN, onChangeTexteditTH, onClickSubmit, onChangePDFEN, onChangePDFTH } = props;
     return (
          <Grid item container mt={5} spacing={2}>
               <Grid item xs={12} mb={10}>
                    <Typography>Policy</Typography>
                    <ReactQuill theme="snow" value={state.policy_en} onChange={onChangeTexteditEN} style={{ width: "100%", height: "30vh" }} />
               </Grid>
               <Grid item xs={12} mb={10}>
                    <Typography>Policy(ภาษาไทย)</Typography>
                    <ReactQuill theme="snow" value={state.policy_th} onChange={onChangeTexteditTH} style={{ width: "100%", height: "30vh" }} />
               </Grid>
               <Grid item container direction={"row"}>
                    <TextField size="small" disabled={true} value={state.file_name_en} />
                    <Button variant="contained" component="label" color="error">
                         Upload File
                         <input accept="application/pdf" type="file" hidden onChange={onChangePDFEN} />
                    </Button>
               </Grid>
               <Grid item container direction={"row"}>
                    <TextField size="small" disabled={true} value={state.file_name_th} />
                    <Button variant="contained" component="label" color="error">
                         Upload File (ภาษาไทย)
                         <input accept="application/pdf" type="file" hidden onChange={onChangePDFTH} />
                    </Button>
               </Grid>
               <Grid item container justifyContent={"center"}>
                    <Button sx={{ borderRadius: 5, minWidth: 150 }} variant="contained" color="error" onClick={onClickSubmit}>
                         Submit
                    </Button>
               </Grid>
          </Grid>
     );
};
export default EditPolicyCard;
