import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { POST, GET, SEARCHCASHBACK, GETALLCASHBACK } from "service";
import swal from "sweetalert2";
import moment from "moment";
import Loading from "components/Loading/Loading";
import ListCashback from "./ListCashback/ListCashback";

export class Cashback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      date_end: new Date(),
      search_cashback: "",
      page: 1,
      loading: false,
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
      data: [],
    };
  }
  componentDidMount = () => {
    if (this.state.role == "admin") {
      this.getCB();
    }
  };
  getCB = async () => {
    try {
      this.setState({ loading: true });
      let cb = await GET(GETALLCASHBACK);
      if (cb.success) {
        this.setState({ data: cb.result, loading: false });
      } else {
        swal.fire({
          title: "ไม่สำเร็จ",
          text: "กรุณาลองใหม่ภายหลัง",
          icon: "warning",
          // timer: 6000,
          showConfirmButton: true,
        });
        this.setState({ loading: false });
      }
    } catch (error) {
      swal.fire({
        title: "ไม่สำเร็จ",
        text: error,
        icon: "warning",
        // timer: 6000,
        showConfirmButton: true,
      });
      this.setState({ loading: false });
    }
  };
  submitSearch = async () => {
    let { date_start, date_end, search_cashback } = this.state;
    try {
      this.setState({ loading: true });
      let cb = await POST(SEARCHCASHBACK, {
        date_start,
        date_end,
        search: search_cashback,
      });
      if (cb.success) {
        this.setState({ data: cb.result, loading: false });
      } else {
        swal.fire({
          title: "ไม่สำเร็จ",
          text: "กรุณาลองใหม่ภายหลัง",
          icon: "warning",
          // timer: 6000,
          showConfirmButton: true,
        });
        this.setState({ loading: false });
      }
    } catch (error) {
      swal.fire({
        title: "ไม่สำเร็จ",
        text: error,
        icon: "warning",
        // timer: 6000,
        showConfirmButton: true,
      });
      this.setState({ loading: false });
    }
  };
  handleChangePage = (e, value) => {
    this.setState({ page: value });
  };
  handleDateChange = (d, s, a) => {
    this.setState({ [a]: d });
  };
  handleDateStartChange = (date) => {
    this.setState({ date_start: date });
  };
  handleDateEndChange = (date) => {
    this.setState({ date_end: date });
  };
  handleSearchCashback = (event) => {
    this.setState({ search_cashback: event.target.value });
  };

  render() {
    const language = localStorage.getItem("language");
    const { page, data, generate, url, loading } = this.state;
    return (
      <Container maxWidth="xl" sx={{ position: "relative" }}>
        <Loading open={loading} />
        <Grid>
          <ListCashback
            language={language}
            data={data}
            page={page}
            handleChangePage={this.handleChangePage}
            state={this.state}
            submitSearch={this.submitSearch}
            handleDateStartChange={this.handleDateStartChange}
            handleDateEndChange={this.handleDateEndChange}
            handleSearchCashback={this.handleSearchCashback}
          />
        </Grid>
      </Container>
    );
  }
}
const dataTable = [
  {
    id: 1,
    user: "Username",
    create: "2021-06-13 22:31:45",
    detail: "cashback ticket 73039188 to wallet",
    money: "0.005",
    type: 1,
  },
  {
    id: 2,
    user: "Username",
    create: "2021-06-13 22:31:45",
    detail: "cashback ticket 73039188 to wallet",
    money: "0.005",
    type: 1,
  },
  {
    id: 3,
    user: "Username",
    create: "2021-06-13 22:31:45",
    detail: "cashback ticket 73039188 to wallet",
    money: "0.005",
    type: 1,
  },
  {
    id: 4,
    user: "Username",
    create: "2021-06-13 22:31:45",
    detail: "cashback ticket 73039188 to wallet",
    money: "0.005",
    type: 1,
  },
  {
    id: 5,
    user: "Username",
    create: "2021-06-13 22:31:45",
    detail: "cashback ticket 73039188 to wallet",
    money: "0.005",
    type: 1,
  },
  {
    id: 6,
    user: "Username",
    create: "2021-06-13 22:31:45",
    detail: "cashback ticket 73039188 to wallet",
    money: "0.005",
    type: 1,
  },
];
export default withRouter(Cashback);
