import { Grid, Tabs, Tab } from "@material-ui/core";
import React from "react";

const DetailTabs = (props) => {
     const [value, setValue] = React.useState(1);
     const handleChange = (event, newValue) => {
          setValue(newValue);
          props.setTap(newValue);
     };

     const role = localStorage.getItem("role");

     return (
          <Grid item container justifyContent="center" alignItems="center">
               <Grid item xs={12} className="taps-css">
                    <Tabs value={value} onChange={handleChange} centered variant="fullWidth" indicatorColor="#4589C6">
                         <Tab value={1} label="Profile" />
                         <Tab value={2} label="Documents" />
                         <Tab value={3} label="Bank Account" />
                         {(role === "admin" || role === "normal") && <Tab value={4} label="Network" />}
                         <Tab value={5} label="Address Crypto" />
                         {/* <Tab value={3} label="IB Network" /> */}
                    </Tabs>
               </Grid>
          </Grid>
     );
};

export default DetailTabs;
