import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, CardMedia, Typography, Grid, Button } from "@material-ui/core";
import { textFiled } from "../../../functions/Static";
import { GridTextField } from "../../../components/index";
import logo_web from "../../../assets/logo/LOGOQrs.png";
import { POST, LOGIN } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";


export class Login extends Component {
     constructor(props) {
          super(props);

          this.state = {
               inputValue: {},
               checkInput: {},
               loading: false,
          };
     }
     handleChange = (e) => {
          let { checkInput, inputValue } = this.state;
          let name = e.target.name;
          let value = e.target.value;
          checkInput[name] = {
               err: value ? false : true,
               label: "",
          };
          inputValue[name] = value;
          this.setState({ inputValue, checkInput });
     };
     checkInput = () => {
          let state = this.state;
          let { checkInput } = this.state;
          let Check = textFiled.login.map((el) => el.name);
          Check.forEach((e) => {
               checkInput[e] = {
                    err: state.inputValue[e] ? false : true,
                    label: state.inputValue[e] ? "" : "กรุณากรอกข้อมูลให้ครบ",
               };
          });
          this.setState({ checkInput }, async () => {
               let { checkInput } = this.state;
               let input = Object.keys(checkInput).some((e) => checkInput[e].err);
               if (!input) {
                    this.setState({ checkInput: {} });
                    this.onSunmit();
               }
          });
     };
     onSunmit = async () => {
          const { inputValue } = this.state;
          try {
               this.setState({ loading: true });
               let login = await POST(LOGIN, { username: inputValue.username, password: inputValue.password });
               if (login.success) {
                    this.setState({ loading: false });
                    window.location.assign("/dashboard");
                    localStorage.removeItem("role");
                    localStorage.removeItem("token");
                    localStorage.setItem("token", login.result.token);
                    localStorage.setItem("role", login.result.role);
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: login.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    // alert('error')
                    // let Check = textFiled.login.map((el) => el.name);
                    // Check.forEach((e) => {
                    //      checkInput[e] = {
                    //           err: true,
                    //           label: login.message,
                    //      };
                    // });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
               // let Check = textFiled.login.map((el) => el.name);
               // Check.forEach((e) => {
               //      checkInput[e] = {
               //           err:  true,
               //           label: error,
               //      };
               // });
          }
     };

     render() {
          const { inputValue, checkInput, loading } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                         <CardMedia sx={{ height: 120, width: "auto", objectFit: "contain", mb: 2 }} component="img" alt="Logo" image={logo_web} />
                         {/* <Typography sx={{ color: "#4589C6", fontSize: 50, fontWeight: 600, mb: 3 }}>QRS Broker</Typography> */}
                         <Typography sx={{ color: "#707070", fontSize: 35, mb: 3 }}>{language === "th" ? "เข้าสู่ระบบแอดมิน" : "Admin Login"}</Typography>
                         <Grid container rowSpacing={3} mb={4}>
                              <GridTextField
                                   data={textFiled.login}
                                   state={inputValue}
                                   check={checkInput}
                                   handleChange={this.handleChange}
                                   onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                             this.checkInput();
                                        }
                                   }}
                              />
                         </Grid>
                         <Button variant="contained" style={{ backgroundColor:"#4589C6" }} className="btn-style" sx={{ maxWidth: 260, mb: 2 }} onClick={this.checkInput}>
                              {language === "th" ? "เข้าสู่ระบบ" : "Login"}
                         </Button>
                         {/* <Link href="/forgot" color="white" sx={{ fontSize: 14 }}>
                              Forgot password?
                         </Link> */}
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(Login);
