import React from "react";
import { Backdrop, Fade, Typography, Modal, Grid, Button, CardMedia, TextField, TextareaAutosize, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import slip from "../../../assets/images/slip.png";
import { ip_image } from "service";
import moment from "moment";
import { createMuiTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const theme = createMuiTheme();
const ModalSlip = (props) => {
     const classes = useStyles();
     const { open, handleClose, language, data, approveRejectDeposit, onChange, stateFinance } = props;
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               onClose={handleClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
               style={{ overflow: "scroll" }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container rowSpacing={3} padding={3} justifyContent="center" alignItems="center">
                              <Grid container justifyContent="flex-end" xs={12}>
                                   <Button onClick={() => handleClose()} className={classes.buttonClose}>
                                        <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                                   </Button>
                              </Grid>
                              <Grid item xs={12} textAlign="center">
                                   <Typography sx={{ fontSize: 24, fontWeight: 600 }}>View slip</Typography>
                              </Grid>
                              {data && (
                                   <Grid item container columnSpacing={1} xs={12}>
                                        <Grid item container rowSpacing={3} xs={12} sm={6}>
                                             <Grid item xs={12}>
                                                  <Typography sx={{ fontSize: 14, color: "#000" }}>Transaction Number</Typography>
                                                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>{data.transaction_id || "-"}</Typography>
                                             </Grid>
                                             <Grid item xs={12}>
                                                  <Typography sx={{ fontSize: 14, color: "#000" }}>User</Typography>
                                                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>
                                                       {data.name} {data.lastname}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12}>
                                                  <Typography sx={{ fontSize: 14, color: "#000" }}>Create</Typography>
                                                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>
                                                       {data.created_at ? moment(data.created_at).format("DD/MM/YYYY, HH:mm:ss") : "-"}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12}>
                                                  <Typography sx={{ fontSize: 14, color: "#000" }}>Deposit (Wallet)</Typography>
                                                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>{data.money || "-"} THB</Typography>
                                             </Grid>
                                             <Grid item xs={12}>
                                                  <Typography sx={{ fontSize: 14, color: "#000" }}>Receive</Typography>
                                                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000" }}>{data.receive || "-"} USD</Typography>
                                             </Grid>
                                             <Grid item xs={12}>
                                                  <Typography sx={{ fontSize: 14, color: "#000" }}>Remark</Typography>
                                                  <TextareaAutosize
                                                       aria-label="minimum height"
                                                       minRows={2}
                                                       placeholder="Remark"
                                                       onChange={onChange}
                                                       name="note_deposit"
                                                       value={data.remark || stateFinance.note_deposit}
                                                       disabled={data.status !== 2}
                                                  />
                                             </Grid>
                                        </Grid>
                                        <Grid item container xs={12} sm={6}>
                                             <Grid container justifyContent="center" alignContent="center" sx={{ p: 3, background: "#F8F8F8", width: "100%" }}>
                                                  <CardMedia
                                                       component="img"
                                                       sx={{ width: "100%", height: "auto", maxHeight: "400px" }}
                                                       alt="slip"
                                                       image={data.slip_path ? stateFinance.image_slip : slip}
                                                  />
                                             </Grid>
                                        </Grid>
                                   </Grid>
                              )}
                              {data.status === 2 && (
                                   <Grid item xs={12} container rowSpacing={3} columnSpacing={3}>
                                        <Grid item xs={12} sm={6} className={classes.btnStyleClose}>
                                             <Button
                                                  variant="outlined"
                                                  color="error"
                                                  className={classes.btnStyle}
                                                  onClick={() => {
                                                       approveRejectDeposit(3, data.transaction_id);
                                                       handleClose();
                                                  }}
                                             >
                                                  {language === "th" ? "ปฏิเสธ" : "reject"}
                                             </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={classes.btnStyleWallet}>
                                             <Button
                                                  variant="contained"
                                                  // color="error"
                                                  style={{ backgroundColor: "#4589C6" }}
                                                  className={classes.btnStyle}
                                                  onClick={() => {
                                                       approveRejectDeposit(1, data.transaction_id);
                                                       handleClose();
                                                  }}
                                             >
                                                  {language === "th" ? "อนุมัติ" : "Approved"}
                                             </Button>
                                        </Grid>
                                   </Grid>
                              )}
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};
const useStyles = makeStyles(() => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "40px 18px",
          borderRadius: "8px",

          "@media (max-width: 600px)": {
               marginTop: "50%",
               display: "flex",
          },
     },
     btnStyle: { maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40 },
     btnStyleClose: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "right",
          },
     },
     btnStyleWallet: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "left",
          },
     },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
}));
export default ModalSlip;
