import { Button, Fade, Grid, Modal, Backdrop, Typography, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
const theme = createMuiTheme();

const ModalLimitAccount = (props) => {
     const { open, onClickCloseLimit, state, onClickUpdateLimitAccount, handleChangeLimitAccount } = props;
     const classes = useStyles();
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               // onClose={handleClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container justifyContent="flex-end" xs={12}>
                              <Button onClick={() => onClickCloseLimit()} className={classes.buttonClose}>
                                   <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                              </Button>
                         </Grid>
                         <Grid container xs={12} justifyContent="center" spacing={2}>
                              <Typography fontWeight={600}>Account Limit</Typography>
                              <Grid item xs={12} container direction={"row"}>
                                   <Typography fontWeight={600} mr={2}>
                                        Account :
                                   </Typography>
                                   <Typography>
                                        {state.account_count}
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} container direction={"row"} display="flex" alignItems="center">
                                   <Typography fontWeight={600} mr={2}>
                                        Change Limit Account :
                                   </Typography>
                                   <TextField
                                        maxWidth={'80%'}
                                        size="small"
                                        type="number"
                                        placeholder="0"
                                        name="account_count"
                                        value={state.update_limitAcc}
                                        onChange={handleChangeLimitAccount}
                                   />
                              </Grid>

                              <Button className={classes.btnStyle} variant="outlined" color="error" onClick={() => onClickCloseLimit()}>
                                   close
                              </Button>
                              <Button
                                   disabled={state.active}
                                   className={classes.btnStyle}
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   onClick={() => {
                                        onClickUpdateLimitAccount();
                                        onClickCloseLimit();
                                   }}
                              >
                                   Submit
                              </Button>
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};

const useStyles = makeStyles(() => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "500px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "10px 18px 25px",
          borderRadius: "8px",
     },
     btnStyle: { maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, marginTop: 10, marginLeft: 5, marginRight: 5, marginTop: 50 },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
}));

export default ModalLimitAccount;
