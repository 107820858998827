import { Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { headerTable } from "functions/Static";
import { withRouter } from "react-router-dom";
import GridTable from "./GridTable";
const theme = createMuiTheme();

const GridIBTransfer = (props) => {
    const {
        page,
        data,
        handleChangePage,
        handleChangeSelect,
        handleSave,
        select_transfer,
        select_ib,
        data_select,
    } = props;

    const classes = useStyles();
    const count = Math.ceil(data.length > 0 && data.length / 10);
    const setPage = props.page - 1;
    const dataList = data.slice(setPage * 10, props.page * 10);
    const role = localStorage.getItem("role");

    return (
        <Grid container spacing={5} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>IB Transfer</Typography>
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={5}>
                        <Autocomplete
                            fullWidth
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : `${option.name} ${option.lastname} (${option.email})`
                            }
                            options={data_select.filter(e => e.user_id !== 0)}
                            value={select_transfer}
                            freeSolo
                            onChange={(event, value) => handleChangeSelect(value, 'select_transfer')}
                            renderInput={(params) => <TextField {...params} placeholder="เลือกผู้ย้าย" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Autocomplete
                            fullWidth
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.user_id ? `${option.name} ${option.lastname} (${option.email})` : `${option.name}`
                            }
                            options={data_select}
                            value={select_ib}
                            freeSolo
                            onChange={(event, value) => handleChangeSelect(value, 'select_ib')}
                            renderInput={(params) => <TextField {...params} placeholder="ไปต่อสาย" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} display="flex" justifyContent={{ xs: "flex-end", md: "flex-start" }}>
                        <Button variant="contained" size="large" onClick={handleSave} disabled={!(select_ib && select_transfer)}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <GridTable
                    head={headerTable.ib_transfer}
                    body={dataList}
                    count={count}
                    page={page}
                    handleChangePage={handleChangePage}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    fontStyleNav: {
        display: "flex",
        alignItems: "center",
        fontSize: 13,
        fontWeight: "bold",
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
    },
}));

export default withRouter(GridIBTransfer);
