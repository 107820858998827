import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { GET, GETSUPPORT } from "service";
import Swal from "sweetalert2";
import CardSupport from "./component/CardSupport";

class Support extends Component {
     constructor(props) {
          super(props);

          this.state = {
               page: 1,
               data_table: [],
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getSupport();
     };

     getSupport = async () => {
          try {
               this.setState({ loading: true });
               let get_support = await GET(GETSUPPORT);
               if (get_support.success) {
                    this.setState({ data_table: get_support.result });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    Swal.fire({ title: "ไม่สำเร็จ", text: get_support.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               Swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     onClickView = (id) => {
          this.props.history.push(`/support/detail/${id}`);
     };

     render() {
          const { page, data_table, loading } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl">
                    <CardSupport language={language} page={page} handleChangePage={this.handleChangePage} data_table={data_table} onClickView={this.onClickView} />
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(Support);
