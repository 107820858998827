import { Button, Grid, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
const theme = createMuiTheme();

const GridTableManage = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const role = localStorage.getItem("role");

    const sub_head = [{ label: "Forex" }, { label: "Gold" }, { label: "Crypto" }, { label: "Index" }, { label: "Oil" }];

    return (
        <Grid className="table-btn">
            < TableContainer >
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" rowSpan={2}>
                                <Typography noWrap>Username</Typography>
                            </TableCell>
                            {props.data_tbl_manage.head_tbl.map((e, i) => {
                                return (
                                    // <>
                                    //     <TableCell align="center" colSpan={5}>
                                    //         <Typography noWrap>{e.account_type_name}</Typography>
                                    //     </TableCell>
                                    //     <TableCell rowSpan={2} align="center" style={{ minWidth: 100 }}>
                                    //         <Typography noWrap>Action</Typography>
                                    //     </TableCell>
                                    // </>
                                    <>
                                        <TableCell align="center" colSpan={4}>
                                            <Typography noWrap>{e.account_type_name}</Typography>
                                        </TableCell>
                                        <TableCell align="center" >
                                            <Button variant="outlined" disabled={props.body.length === 0} onClick={() => props.handleChangeSave(e.account_type_id)}>
                                                save
                                            </Button>
                                            {/* <Typography noWrap>{e.account_type_name}</Typography> */}
                                        </TableCell>
                                    </>
                                )
                            })}
                        </TableRow>
                        <TableRow>
                            {Array(props.data_tbl_manage.head_tbl.length)
                                .fill({}).map((e, i) =>
                                    sub_head.map((e, ind) => {
                                        return (
                                            <TableCell key={ind} align="left" style={{ minWidth: 100 }}>
                                                <Typography noWrap>{e.label}</Typography>
                                            </TableCell>
                                        );
                                    })
                                )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.body.map((el, ind) => {
                            return (
                                <TableRow hover key={ind} className={ind % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell >
                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }} >
                                            {el.name} {el.lastname} ({el.email})
                                        </Typography>
                                    </TableCell>
                                    {el.rebate.map((ele, i) => {
                                        return (
                                            <>
                                                <TableCell >
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Forex"}
                                                                name={"forex"}
                                                                value={ele.forex}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                // disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Gold"}
                                                                name={"gold"}
                                                                value={ele.gold}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                // disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Crypto"}
                                                                name={"crypto"}
                                                                value={ele.crypto}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                // disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Index"}
                                                                name={"index"}
                                                                value={ele.index}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                // disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Oil"}
                                                                name={"oil"}
                                                                value={ele.oil}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                // disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                            </>
                                        )
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {/* {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />} */}
            </Grid>
        </Grid >
    );
};
const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
    fontStyleTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: "#000",
    },
    fontStyleNav: {
        fontSize: 13,
        fontWeight: "bold",
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
    },
    fontStyleDetail: {
        fontSize: 13,
        color: "#000",
        // textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
        marginTop: 10,
        marginBottom: 10,
    },
    fontStyle: {
        fontSize: 13,
        color: "#000",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
        display: "flex",
        justifyContent: "center",
    },
}));
export default GridTableManage;
