import { Grid, Typography } from "@material-ui/core";
import React from "react";
import GridTableIBMember from "./GridTableIBMember";

const NetworkuserCard = (props) => {
     const { data_network, language } = props;
     return (
          <Grid container justifyContent="center" xs={12} spacing={2}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #fc0316" }}>Network</Typography>
               </Grid>
               <Grid item xs={12}>
                    <GridTableIBMember data={data_network} language={language} />
               </Grid>
          </Grid>
     );
};

export default NetworkuserCard;
