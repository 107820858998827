import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { StringSet, textFiled } from "functions/Static";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { ACCOUNTTYPEBYIDAPI, ACCOUNTTYPEEDITAPI, ADDACCOUNTTYPEAPI, GET, IB, POST } from "service";
import swal from "sweetalert2";
import SettingManage from "./Components/SettingManage";

const path = window.location.pathname;

class SettingManageAccountType extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               add: 1,
               funtion_array_th: [],
               funtion_array_en: [],
               image: "",
               account_type_id: 0,
               leverage: "",
               margin_call: "",
               maximum_lot: "",
               maximum_order: "",
               min_deposit: "",
               minimum_lot: "",
               name_en: "",
               name_th: "",
               stop_out: "",
               spread: "",
               commission: "",
               commission_per_lot: "",
               type: null,
               path: window.location.pathname,
               titleGrid: [
                    {
                         xs: 5,
                         // sm: 6,
                         // md: 3,
                         classNameGrid: "pt-0",
                         classNameText: "textfield-css mt-4",
                         placeholder: "Function",
                         title: "Function",
                         name: "function_en1",
                         placeholder2: "Function(ภาษาไทย)",
                         title2: "Function(ภาษาไทย)",
                         name2: "function_th1",
                         button: "Delete",
                         btn: "btn1",
                    },
               ],
               loading: false,
               leverageSelect: [],
               leverageSelectGet: [],
               checked_cent: false,
               checked_bonus: false,
               data_team: [],
               select_team: [],
               select_data_team: [],
          };
     }

     componentDidMount() {
          if (path !== StringSet.account_type.link) {
               this.getAccountTypeWithID();
          }
          this.getIB();
     }

     getIB = async () => {
          try {
               this.setState({ loading: true });
               let ib = await GET(IB);
               if (ib.success) {
                    this.setState({
                         data_team: ib.result,
                    });
                    this.setState({ loading: false });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: ib.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               this.setState({ loading: false });
          }
     };

     getAccountTypeWithID = async () => {
          let { titleGrid } = this.state;
          try {
               this.setState({ loading: true });
               let accountType = await POST(ACCOUNTTYPEBYIDAPI, { account_type_id: this.props.match.params.id });
               if (accountType.success) {
                    let account_type_name = JSON.parse(accountType.result.account_type_name);
                    let leverageSelectGetJson = JSON.parse(accountType.result.leverage_select);
                    titleGrid = [];
                    this.setState({
                         name_th: account_type_name.th,
                         name_en: account_type_name.en,
                         account_type_id: this.props.match.params.id,
                         leverage: accountType.result.leverage,
                         margin_call: accountType.result.margin_call,
                         maximum_lot: accountType.result.maximum_lot,
                         maximum_order: accountType.result.maximum_order,
                         min_deposit: accountType.result.min_deposit,
                         minimum_lot: accountType.result.minimum_lot,
                         stop_out: accountType.result.stop_out,
                         spread: accountType.result.spread,
                         commission: accountType.result.commission,
                         commission_per_lot: accountType.result.commission_per_lot,
                         manager_name: accountType.result.manager_name,
                         loading: false,
                         leverageSelectGet: leverageSelectGetJson ? leverageSelectGetJson : [],
                         leverageSelect: leverageSelectGetJson,
                         checked_cent: accountType.result.is_cent,
                         checked_bonus: accountType.result.is_bonus,
                         select_data_team: accountType.result.user_team,
                         type: accountType.result.type,
                         select_team: accountType.result.user_team.map(({ manager_id }) => manager_id.toString()),
                         // leverageSelectGet:accountType.result.leverage_select,
                    });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleChangeTextArray = (e, i) => {
          let { funtion_array_en, funtion_array_th } = this.state;
          if (e.target.name.includes("function_en")) {
               funtion_array_en[i] = e.target.value;
               this.setState({ funtion_array_en, [e.target.name]: e.target.value });
          } else {
               funtion_array_th[i] = e.target.value;
               this.setState({ funtion_array_th, [e.target.name]: e.target.value });
          }
     };

     onClickAddFun = (e) => {
          let { add, titleGrid } = this.state;
          add++;
          titleGrid.push({
               xs: 5,
               // sm: 6,
               // md: 3,
               classNameGrid: "pt-0",
               classNameText: "textfield-css mt-4",
               placeholder: "Function",
               title: "Function",
               name: "function_en" + add,
               placeholder2: "Function(ภาษาไทย)",
               title2: "Function(ภาษาไทย)",
               name2: "function_th" + add,
               button: "Delete",
               btn: "btn" + add,
          });
          this.setState({ add });
     };

     onClickDelete = (btn) => {
          let { funtion_array_en, funtion_array_th, titleGrid } = this.state;
          funtion_array_en.splice(
               titleGrid.findIndex((el) => el.btn === btn),
               1
          );
          funtion_array_th.splice(
               titleGrid.findIndex((el) => el.btn === btn),
               1
          );
          titleGrid.splice(
               titleGrid.findIndex((el) => el.btn === btn),
               1
          );
          this.setState({ titleGrid, funtion_array_en, funtion_array_th });
     };

     onChangeFile = (e) => {
          const reader = new FileReader();
          reader.onload = () => {
               if (reader.readyState === 2) {
                    this.setState({ previewImage: reader.result });
               }
          };
          reader.readAsDataURL(e.target.files[0]);
          this.setState({ file: e.target.files[0].name, image: e.target.files[0] });
     };

     onClickAdd = async () => {
          const {
               name_th,
               name_en,
               min_deposit,
               leverage,
               margin_call,
               stop_out,
               spread,
               commission,
               minimum_lot,
               maximum_lot,
               maximum_order,
               commission_per_lot,
               manager_name,
               leverageSelect,
               type,
               checked_cent,
               checked_bonus,
               select_team,
          } = this.state;
          const leverageSelectJSON = JSON.stringify(leverageSelect);
          const teamSelectJSON = JSON.stringify(select_team);
          try {
               this.setState({ loading: true });
               if (
                    name_th &&
                    name_en &&
                    min_deposit &&
                    leverage &&
                    type &&
                    // margin_call &&
                    // stop_out &&
                    // spread &&
                    // commission &&
                    // minimum_lot &&
                    // maximum_lot &&
                    // maximum_order &&
                    // commission_per_lot &&
                    manager_name
               ) {
                    let dataPass = {
                         name_th: name_th,
                         name_en: name_en,
                         min_deposit: min_deposit,
                         // margin_call: margin_call,
                         // spread: spread,
                         // commission: commission,
                         // minimum_lot: minimum_lot,
                         // maximum_lot: maximum_lot,
                         // maximum_order: maximum_order,
                         // commission_per_lot: commission_per_lot,
                         // stop_out: stop_out,
                         leverage: leverage,
                         manager_name: manager_name,
                         type,
                         leverage_select: leverageSelectJSON,
                         is_cent: checked_cent,
                         is_bonus: checked_bonus,
                         // team: select_team
                         team_array: teamSelectJSON
                    };
                    let addAccount = await POST(ADDACCOUNTTYPEAPI, dataPass);
                    if (addAccount.success) {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "สำเร็จ", text: "เพิ่มประเภทบัญชีสำเร็จ", icon: "success",
                              // timer: 6000, 
                              showConfirmButton: true
                         });
                         window.location.assign("/setting_account_type");
                    } else {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "แจ้งเตือน", text: addAccount.message, icon: "warning",
                              // timer: 6000, 
                              showConfirmButton: true
                         });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "แจ้งเตือน", text: "กรุณากรอกข้อมูลให้ครบถ้วน", icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "แจ้งเตือน", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickEdit = async () => {
          const {
               name_th,
               name_en,
               min_deposit,
               leverage,
               // margin_call,
               // stop_out,
               // spread,
               // commission,
               // minimum_lot,
               // maximum_lot,
               // maximum_order,
               // commission_per_lot,
               account_type_id,
               manager_name,
               leverageSelect,
               checked_cent,
               checked_bonus,
               select_team,
          } = this.state;
          const leverageSelectJSON = JSON.stringify(leverageSelect);
          const teamSelectJSON = JSON.stringify(select_team);
          try {
               let dataPass = {
                    name_th: name_th,
                    name_en: name_en,
                    min_deposit: min_deposit,
                    // margin_call: margin_call,
                    // spread: spread,
                    // commission: commission,
                    // minimum_lot: minimum_lot,
                    // maximum_lot: maximum_lot,
                    // maximum_order: maximum_order,
                    // commission_per_lot: commission_per_lot,
                    // stop_out: stop_out,
                    leverage: leverage,
                    account_type_id: account_type_id,
                    manager_name: manager_name,
                    leverage_select: leverageSelectJSON,
                    is_cent: checked_cent,
                    is_bonus: checked_bonus,
                    team_array: teamSelectJSON,
               };
               let addAccount = await POST(ACCOUNTTYPEEDITAPI, dataPass);
               // console.log("addAccount", addAccount);
               if (addAccount.success) {
                    swal.fire({
                         title: "สำเร็จ", text: "แก้ไขประเภทบัญชีสำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    window.location.assign("/setting_account_type");
               } else {
                    swal.fire({
                         title: "แจ้งเตือน", text: addAccount.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "แจ้งเตือน", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onChangePath = (path) => {
          if (path === "/setting_manage_account_type/add") {
               this.setState({
                    name_th: null,
                    name_en: null,
                    detail_th: null,
                    detail_en: null,
                    forex_id: null,
                    funtion_array_th: [],
                    funtion_array_en: [],
                    file: null,
                    previewImage: null,
                    titleGrid: [
                         {
                              xs: 5,
                              // sm: 6,
                              // md: 3,
                              classNameGrid: "pt-0",
                              classNameText: "textfield-css mt-4",
                              placeholder: "Function",
                              title: "Function",
                              name: "function_en1",
                              placeholder2: "Function(ภาษาไทย)",
                              title2: "Function(ภาษาไทย)",
                              name2: "function_th1",
                              button: "Delete",
                              btn: "btn1",
                         },
                    ],
               });
          }
          this.setState({ path });
     };

     onChangeAutocomplete = (valueAuto) => {
          const { leverageSelect } = this.state;
          let leverageSelectArr = [];
          leverageSelectArr.push(...valueAuto);
          console.log(leverageSelectArr);
          this.setState({ leverageSelect: leverageSelectArr });
     };

     onChangeTeam = (valueAuto) => {
          let team = [];
          let manager_id_team = valueAuto.map(({ manager_id }) => manager_id.toString())
          team.push(...valueAuto);
          this.setState({ select_team: manager_id_team, select_data_team: team });
          // this.setState({ select_team: valueAuto.manager_id, select_data_team: `${valueAuto.name} ${valueAuto.lastname} (${valueAuto.email})` });
     };

     handleChangeCheck = (e) => {
          this.setState({ [e.target.name]: e.target.checked });
     };

     render() {
          return (
               <Container maxWidth="xl">
                    <Loading open={this.state.loading} />
                    <SettingManage
                         onClickAdd={() => this.onClickAdd()}
                         onClickEdit={() => this.onClickEdit()}
                         onChangeFile={(e) => this.onChangeFile(e)}
                         handleChangeText={(e) => this.handleChangeText(e)}
                         textFiled={textFiled.add_account_type}
                         textFiledAccount={textFiled.account_type_list}
                         // previewImage={this.state.previewImage}
                         state={this.state}
                         path={this.state.path}
                         StringSet={StringSet.account_type}
                         // titleGrid={this.state.titleGrid}
                         onClickAddFun={() => this.onClickAddFun()}
                         handleChangeTextArray={(e, i) => this.handleChangeTextArray(e, i)}
                         onClickDelete={this.onClickDelete}
                         onChangePath={this.onChangePath}
                         onChangeAutocomplete={this.onChangeAutocomplete}
                         handleChangeCheck={this.handleChangeCheck}
                         onChangeTeam={this.onChangeTeam}
                    />
               </Container>
          );
     }
}

export default withRouter(SettingManageAccountType);
