import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import RebateCard from "./components/RebateCard";

export class Rebate extends Component {
     constructor(props) {
          super(props);

          this.state = {
               rebate: [
                    { label: "รายชื่อชั้นที่ 1", align: "left" },
                    { label: "XX", align: "left" },
                    { label: "YY", align: "left" },
                    { label: "ZZ", align: "left" },
                    { label: "AA", align: "left" },
                    { label: "BB", align: "left" },
               ],
          };
     }

    //  getRebate = () => {

    //  }
     render() {
          return (
               <Container maxWidth="xl">
                    <RebateCard state={this.state} />
               </Container>
          );
     }
}

export default withRouter(Rebate);
