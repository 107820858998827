import React from "react";
import { Grid, CardMedia, Container, Typography, CardActionArea } from "@material-ui/core";
import logo_web from "../../assets/logo/Logo-color.png";
import { footBar } from "../../functions/Static";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const Footer = (props) => {
     const classes = useStyles();
     const propsHistory = (el) => {
          if (el.link) {
               props.history.push(el.link);
          }
     };
     let { language } = props;
     return (
          <Grid className="footer">
               <Container maxWidth="xl" sx={{ py: 5 }}>
                    <Grid container rowSpacing={5}>
                         <Grid item xs={12} sm={6} md={3}>
                              <CardMedia className={classes.imgfooter} component="img" alt="Logo" image={logo_web} />
                         </Grid>
                         {footBar.footer.map((el, i) => (
                              <Grid key={i} item xs={12} sm={6} md={i === 0 ? 4 : i === 2 ? 2 : 3}>
                                   <Grid item xs={12} sx={{ height: "max-content" }}>
                                        <Typography className={classes.titlefooter}>{language === "th" ? el.title_th : el.title_en}</Typography>
                                   </Grid>
                                   <Grid container item xs={12} sx={{ mt: 2 }} rowSpacing={3}>
                                        {el.arr_body.map((ele, ind) => (
                                             <Grid key={ind} item xs={i === 0 ? 6 : i === 1 ? 12 : "auto"}>
                                                  <CardActionArea
                                                       onClick={() => propsHistory(ele)}
                                                       sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "flex-start",
                                                            width: "max-content",
                                                       }}
                                                  >
                                                       {ele.icon && (
                                                            <CardMedia
                                                                 component="img"
                                                                 sx={{ height: 24, width: "auto", mr: i === 2 ? 2 : 1 }}
                                                                 alt="Logo"
                                                                 image={ele.icon}
                                                            />
                                                       )}
                                                       {(ele.th || ele.en) && (
                                                            <Typography sx={{ fontSize: 18, color: "white" }}>
                                                                 {language === "th" ? ele.th : ele.en}
                                                            </Typography>
                                                       )}
                                                  </CardActionArea>
                                             </Grid>
                                        ))}
                                   </Grid>
                              </Grid>
                         ))}
                    </Grid>
               </Container>
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     imgfooter: {
          width: "auto",
          height: 64,
          [theme.breakpoints.up("sm")]: {
               height: 100,
          },
     },
     titlefooter: {
          color: "white",
          borderBottom: "2px solid #fc0316",
          width: "max-content",
          fontSize: 20,
          [theme.breakpoints.up("sm")]: {
               fontSize: 26,
          },
     },
}));
export default withRouter(Footer);
