import { Container, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import Sidebar from "./Components/Sidebar";
import CardNews from "./Components/CardNews";
import { StringSet } from "functions/Static";
import { POST, NEWSAPI, DELETENEWS } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

class SettingNews extends Component {
     constructor(props) {
          super(props);
          this.state = {
               news: [],
               loading: false,
          };
     }

     componentDidMount() {
          this.getNews();
     }

     getNews = async () => {
          try {
               this.setState({ loading: true });
               let news = await POST(NEWSAPI, {});
               if (news.success) {
                    this.setState({
                         news: news.result,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: news.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickEdit = (news_id) => {
          this.props.history.push(`/setting_manage_news/edit/${news_id}`);
     };
     onClickDelete = async (news_id) => {
          try {
               this.setState({ loading: true });
               let deleteNews = await POST(DELETENEWS, { news_id });
               if (deleteNews.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "ลบข่าวสำเร็จ", icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    this.getNews();
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: deleteNews.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };
     render() {
          const { news, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title="Menu" pageName="news" data={StringSet.news.sidebar} />
                         </Grid>
                         <Grid item container spacing={2} direction="row" xs={9} alignContent={"flex-start"}>
                              <Grid item xs={12}>
                                   <HeaderTypography title="All News" />
                              </Grid>
                              <CardNews data={news} title="หัวข้อข่าวสาร" onClickEdit={this.onClickEdit} onClickDelete={this.onClickDelete} />
                         </Grid>
                    </Grid>
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(SettingNews);
