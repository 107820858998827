import { Button, Grid, Stack, Tab, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { GridTextField } from "components";
import { headerTable, textFiled } from "functions/Static";
import DatePicker from "react-date-picker";
import TableReport from "./TableReport";
import { tofieds } from "functions/Functions";
const Report = (props) => {
     const { data, language, page, handleChangePage, handleChange, state, onClickSearch, handleDateStartChange, handleDateEndChange, mt4_list, sum_lot, data_lot, data_mt4 } =
          props;
     const count = Math.ceil(data.length > 0 && data.length / 10);
     const setPage = page - 1;
     const dataList = data.slice(setPage * 10, page * 10);
     return (
          <Grid container spacing={5} justifyContent="center" alignItems="center" mt={5}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                         {language === "th" ? "รายงานการเทรด" : "Trading Report"}
                    </Typography>
                    <Grid item container direction={"row"} mb={{ md: -2 }}>
                         <Grid item xs={12} sm={6} md={4}>
                              <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                                   <Typography>Name - Lastname : </Typography>
                                   <Typography sx={{ color: "#184B8E" }}>{`${data_mt4.name} ${data_mt4.lastname}` || "-"}</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} sm={6} md={4}>
                              <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                                   <Typography>Email : </Typography>
                                   <Typography sx={{ color: "#184B8E" }}>{`${data_mt4.email}` || "-"}</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} sm={12} md={4}>
                              <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                                   <Typography>Phone : </Typography>
                                   <Typography sx={{ color: "#184B8E" }}>{`${data_mt4.phone}` || "-"}</Typography>
                              </Stack>
                         </Grid>
                    </Grid>
                    <Grid item container direction={"row"}>
                         <Grid item xs={12} sm={3} md={2}>
                              <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                                   <Typography>Lot รวม : </Typography>
                                   <Typography sx={{ color: "#184B8E" }}>${tofieds(sum_lot) || "0.00"}</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} sm={3} md={2}>
                              <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                                   <Typography>Profits: </Typography>
                                   <Typography sx={{ color: "#184B8E" }}>${tofieds(data_lot.profit) || "0.00"}</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} sm={3} md={2}>
                              <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                                   <Typography>Lost: </Typography>
                                   <Typography sx={{ color: "#184B8E" }}>${tofieds(data_lot.lost) || "0.00"}</Typography>
                              </Stack>
                         </Grid>
                         <Grid item xs={12} sm={3} md={2}>
                              <Stack spacing={2} direction="row" marginBottom={2} mt={2}>
                                   <Typography>Net Profit: </Typography>
                                   <Typography sx={{ color: "#184B8E" }}>${tofieds(data_lot.netLost) || "0.00"}</Typography>
                              </Stack>
                         </Grid>
                    </Grid>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                              {language === "th" ? "ค้นหา" : "Search"}:
                         </Typography>
                         <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                              <Grid item xs={12} sm={12} md={3}>
                                   <GridTextField data={textFiled.search_rp} language={language} state={state} handleChange={handleChange} menuItem={mt4_list} />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3}>
                                   <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3}>
                                   <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3}>
                                   <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#4589C6" }}
                                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                        onClick={onClickSearch}
                                        onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                  onClickSearch();
                                             }
                                        }}
                                   >
                                        {language === "th" ? "ค้นหา" : "Search"}
                                   </Button>
                              </Grid>
                         </Grid>
                         {/* <Button
                              variant="contained"
                              // color="error"
                              style={{ backgroundColor: "#4589C6" }}
                              sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                              onClick={onClickSearch}
                         >
                              <SearchIcon />
                         </Button> */}
                    </Stack>
               </Grid>
               <Grid item xs={12}>
                    <TableReport head={headerTable.report_transaction} body={dataList} count={count} page={page} handleChangePage={handleChangePage} language={language} />
               </Grid>
          </Grid>
     );
};

export default Report;
