import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import HeaderTypography from "../../../components/HeaderTypography/HeaderTypography";
import CardBanner from "./CardBanner";

const BannerCard = (props) => {
     const { language } = props;
     return (
          <Grid>
               <Grid xs={12}>
                    <HeaderTypography title="Banner" />
               </Grid>
               <Grid xs={12} container direction="row" justifyContent="flex-end">
                    {/* <Button
                         variant="contained"
                         color="error"
                         className="btn-style"
                         sx={{ maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40, height: 50, margin: 1 }}
                         // onClick={submitSearch}
                    >
                         {language === "th" ? "+ เพิ่มแบนเนอร์" : "+ Add Banner"}
                    </Button> */}
               </Grid>
               <Grid Grid xs={12}>
                    <CardBanner
                         bannerNum={1}
                         onClickEdit={props.onClickEdit}
                         data={props.data}
                         index_disable={props.index_disable}
                         previewImage={props.previewImage}
                         onChangeFile={props.onChangeFile}
                         textFiled={props.textFiled}
                         state={props.state}
                         handleChangeText={props.handleChangeText}
                         language={language}
                    />
               </Grid>
          </Grid>
     );
};

export default withRouter(BannerCard);
