import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import ListBList from "./ListBList/ListBList";
import { GET, IB, GENMANGERURL, POST } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

export class IBList extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               data_ib: [],
               page: 1,
               search_text: "",
               generate: false,
               url: "http://localhost:3000/iblist",
               loading: false,
               count_page: 0,
               data_csv: [],
          };
     }
     componentDidMount = () => {
          this.getIB();
          // this.getIBExport();
          // this.setState({ data: dataTable });
     };

     getIBExport = async () => {
          try {
               // this.setState({ loading: true });
               let ib = await GET(IB);
               if (ib.success) {
                    // console.log(ib.result);
                    // let data_csv = [];
                    // ib.result.map((el, i) => {
                    //      data_csv.push({
                    // ib_id: i + 1,
                    // manager: `${el.name} ${el.lastname}`,
                    // email: el.email,
                    // people: el.people,
                    // admin_name: el.admin_name,
                    //      });
                    // });
                    // this.setState({ loading: false, data_csv });
                    return Promise.resolve
                         (
                              Promise.all(ib.result.map((el, i) => {
                                   return {
                                        user_id: el.user_id,
                                        ib_id: i + 1,
                                        manager: `${el.name} ${el.lastname}`,
                                        email: el.email,
                                        phone: el.phone ? '"""' + el.phone + '"""' : "",
                                        people: el.people,
                                        admin_name: el.admin_name,
                                   }
                              }))
                         )
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: ib.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    // this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               // this.setState({ loading: false });
          }
     };

     getIB = async (value) => {
          const { page, search_text } = this.state;
          try {
               this.setState({ loading: true });
               let ib = await POST(IB, { page: value ? value : page, text_search: search_text });
               if (ib.success) {
                    this.setState({
                         data_ib: ib.result.ib_data,
                         data: ib.result.ib_data,
                         count_page: ib.result.ib_count,
                    });
                    this.setState({ loading: false });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: ib.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               this.setState({ loading: false });
          }
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
          this.getIB(value);
     };
     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     submitSearch = async () => {
          const { search_text } = this.state;
          try {
               this.setState({ loading: true });
               let ib = await POST(IB, { page: 1, text_search: search_text });
               if (ib.success) {
                    this.setState({
                         data_ib: ib.result.ib_data,
                         data: ib.result.ib_data,
                         count_page: ib.result.ib_count,
                         page: 1
                    });
                    this.setState({ loading: false });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: ib.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.setState({ loading: false });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               this.setState({ loading: false });
          }
          // this.setState({ data, page: 1 });
     };
     onClicView = (id) => {
          this.props.history.push(`/ibdetail/${id}`);
     };
     onClickGenerate = async () => {
          try {
               this.setState({ loading: true });
               let genUrl = await GET(GENMANGERURL);
               if (genUrl.success) {
                    let url = "https://portal.ats-forex.com/register/sale/" + genUrl.result.url;
                    this.setState({ url, generate: true, loading: false });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               this.setState({ loading: false });
          }
     };
     onClickCopy = async () => {
          if ("clipboard" in navigator) {
               swal.fire({
                    title: "Coppy สำเร็จ", icon: "success",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               return await navigator.clipboard.writeText(this.state.url);
          } else {
               return document.execCommand("copy", true, this.state.url);
          }
     };
     render() {
          const language = localStorage.getItem("language");
          const { page, data, generate, url, loading, count_page, data_csv } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Loading open={loading} />
                    <Grid>
                         <ListBList
                              language={language}
                              data={data}
                              page={page}
                              handleChangePage={this.handleChangePage}
                              handleSearch={this.handleSearch}
                              state={this.state}
                              submitSearch={this.submitSearch}
                              onClicView={this.onClicView}
                              onClickGenerate={this.onClickGenerate}
                              onClickCopy={this.onClickCopy}
                              generate={generate}
                              url={url}
                              count_page={count_page}
                              getIBExport={this.getIBExport}
                         />
                    </Grid>
               </Container>
          );
     }
}
const dataTable = [
     {
          id: 1,
          code: "00000",
          manager: "Name Lastname",
          amount: 1000,
     },
     {
          id: 2,
          code: "00000",
          manager: "Name Lastname",
          amount: 1000,
     },
     {
          id: 3,
          code: "00000",
          manager: "Name Lastname",
          amount: 1000,
     },
     {
          id: 4,
          code: "00000",
          manager: "Name Lastname",
          amount: 1000,
     },
     {
          id: 5,
          code: "00000",
          manager: "Name Lastname",
          amount: 1000,
     },
     {
          id: 6,
          code: "00000",
          manager: "Name Lastname",
          amount: 1000,
     },
];
export default withRouter(IBList);
