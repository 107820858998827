import { Autocomplete, Button, Checkbox, Chip, Grid, TextField, Typography } from "@material-ui/core";
import { GridTextField } from "components";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import ReactQuill from "react-quill";
import TextfielAccount from "../Components/TextfielAccount";
import Sidebar from "./Sidebar";
import TextfielAdd from "./TextfielAdd";

const SettingManage = (props) => {
     const {
          textFiled,
          state,
          handleChangeText,
          onChangeFile,
          previewImage,
          onClickAdd,
          path,
          StringSet,
          onClickDelete,
          handleChangeTextArray,
          titleGrid,
          onClickAddFun,
          onClickEdit,
          textFiledAccount,
          onChangeTexteditEN,
          onChangeTexteditTH,
          valueEdit_en,
          valueEdit_th,
          onChangeAutocomplete,
          handleChangeCheck,
          onChangeTeam,
     } = props;
     let leverageGet = state.leverageSelectGet;
     let teamGet = state.select_data_team;
     return (
          <Grid container spacing={2}>
               <Grid item xs={3}>
                    <Sidebar
                         title={StringSet.sidebar.title}
                         pageName={StringSet.sidebar.pageName}
                         data={StringSet.sidebar}
                         onChangePath={props.onChangePath}
                    // onChangePath={props.onChangePath}
                    />
               </Grid>
               <Grid item container spacing={2} direction="row" xs={9} justifyContent="center" alignContent={"flex-start"}>
                    <Grid item xs={12}>
                         <HeaderTypography title={path === StringSet.link ? StringSet.header.title : StringSet.headerEdit.title} />
                    </Grid>
                    <GridTextField data={textFiled} state={state} handleChange={handleChangeText} onChangeFile={onChangeFile} image={previewImage} />
                    {titleGrid ? (
                         <>
                              <Grid item xs={12}>
                                   <Button
                                        variant="contained"
                                        // color="error"
                                        style={{ backgroundColor: "#4589C6" }}
                                        className="btn-style"
                                        sx={{ maxWidth: 200, fontSize: 10, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={onClickAddFun}
                                   >
                                        Add+
                                   </Button>
                              </Grid>
                              <TextfielAdd data={titleGrid} state={state} handleChange={handleChangeTextArray} onClickDelete={onClickDelete} />
                         </>
                    ) : null}
                    {onChangeTexteditEN ? (
                         <>
                              <Grid item xs={12} container marginBottom={5}>
                                   <Typography>Content</Typography>
                                   <ReactQuill theme="snow" value={valueEdit_en} onChange={onChangeTexteditEN} style={{ width: "100%", height: "30vh" }} />
                              </Grid>
                              <Grid item xs={12} container marginBottom={10}>
                                   <Typography>Content(ภาษาไทย)</Typography>
                                   <ReactQuill theme="snow" value={valueEdit_th} onChange={onChangeTexteditTH} style={{ width: "100%", height: "30vh" }} />
                              </Grid>
                         </>
                    ) : null}
                    {textFiledAccount ? (
                         <Grid item xs={12} container justifyContent="center">
                              <TextfielAccount handleChangeText={handleChangeText} data={textFiledAccount} state={state} menuItem={menuItem} />
                              <Grid Grid container item xs={12} spacing={2} marginBottom={2}>
                                   <Grid item xs={12} md={4}>
                                        <Typography fontSize={{ xs: 14, sm: 16, md: 16 }}>Leverage select</Typography>
                                   </Grid>
                                   <Grid item xs={12} md={8}>
                                        <Autocomplete
                                             multiple
                                             id="tags-filled"
                                             options={leverageSet.map((option) => option.leverageDefault)}
                                             // defaultValue={[leverageSet[1].leverageDefault]}
                                             value={state.leverageSelect ? state.leverageSelect : leverageGet}
                                             freeSolo
                                             onChange={(event, value) => onChangeAutocomplete(value)}
                                             renderTags={(value, getTagProps) =>
                                                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
                                             }
                                             renderInput={(params) => <TextField {...params} variant="standard" placeholder="Leverage select" />}
                                        />
                                   </Grid>
                                   <Grid item xs={6} md={4} display="flex" alignItems="center">
                                        <Typography fontSize={{ xs: 14, sm: 16, md: 16 }}>Cent</Typography>
                                   </Grid>
                                   <Grid item xs={6} md={8}>
                                        <Checkbox
                                             name="checked_cent"
                                             checked={state.checked_cent}
                                             onChange={handleChangeCheck}
                                             inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                   </Grid>
                                   <Grid item xs={6} md={4} display="flex" alignItems="center">
                                        <Typography fontSize={{ xs: 14, sm: 16, md: 16 }}>Bonus</Typography>
                                   </Grid>
                                   <Grid item xs={6} md={8}>
                                        <Checkbox
                                             name="checked_bonus"
                                             checked={state.checked_bonus}
                                             onChange={handleChangeCheck}
                                             inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                   </Grid>
                                   <Grid item xs={12} md={4}>
                                        <Typography fontSize={{ xs: 14, sm: 16, md: 16 }}>Team</Typography>
                                   </Grid>
                                   <Grid item xs={12} md={8}>
                                        <Autocomplete
                                             multiple
                                             id="tags-filled"
                                             getOptionLabel={(option) =>
                                                  typeof option === 'string' ? option : `${option.name} ${option.lastname} (${option.email})`
                                             }
                                             options={state.data_team}
                                             value={state.select_data_team}
                                             freeSolo
                                             onChange={(event, value) => onChangeTeam(value)}
                                             renderInput={(params) => <TextField {...params} variant="standard" placeholder="Team select" />}
                                        />
                                   </Grid>
                              </Grid>
                         </Grid>
                    ) : null}
                    <Grid item xs={12} container justifyContent="center">
                         <Button
                              variant="contained"
                              style={{ backgroundColor: "#4589C6" }}
                              className="btn-style"
                              sx={{ maxWidth: 200, fontSize: 12, width: "100%", borderRadius: 40, height: 50, margin: 1 }}
                              onClick={path === StringSet.link ? onClickAdd : onClickEdit}
                         >
                              {path === StringSet.link ? "Add" : "Edit"}
                         </Button>
                    </Grid>
               </Grid>
          </Grid>
     );
};

const leverageSet = [
     { leverageDefault: "1" },
     { leverageDefault: "50" },
     { leverageDefault: "100" },
     { leverageDefault: "200" },
     { leverageDefault: "300" },
     { leverageDefault: "400" },
     { leverageDefault: "500" },
     { leverageDefault: "600" },
     { leverageDefault: "700" },
     { leverageDefault: "800" },
     { leverageDefault: "900" },
     { leverageDefault: "1000" },
];

const menuItem = [
     { label: "Select Type", value: "null", disabled: true },
     { label: "MT4", value: "MT4" },
     { label: "MT5", value: "MT5" },
];



export default SettingManage;
