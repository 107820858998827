import { Grid, InputAdornment, Table, TableContainer, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const GridTableNetwork = (props) => {
     const classes = useStyles();
     return (
          <TableContainer>
               <Table className={classes.table} aria-label="caption table">
                    <Grid item container direction={"row"} spacing={2} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px">
                         <Grid item xs={6}>
                              <Typography className={classes.fontStyleTitle}>Name</Typography>
                         </Grid>
                         <Grid item xs={6}>
                              <Typography className={classes.fontStyleTitle}>Dividend(%)</Typography>
                         </Grid>
                    </Grid>
                    {/* {props.data.map((el, i) => {
                         return ( */}
                              <Grid item container direction={"row"} spacing={1} borderBottom={"1px solid #EBEBEB"} marginBottom="20px" paddingBottom="20px">
                                   <Grid item xs={6}>
                                        <Typography className={classes.fontStyleDetail}>{props.name}</Typography>
                                   </Grid>
                                   <Grid item xs={6} container alignItems="center">
                                        <TextField
                                             className="textfield-profile"
                                             InputProps={{
                                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                             }}
                                             fullWidth
                                             size="small"
                                             type="number"
                                             placeholder="Dividend"
                                             name="dividend"
                                             value={props.state.dividend}
                                             disabled={props.edit}
                                             onChange={props.handleChangeText}
                                             disabled={props.edit_disabled}
                                        />
                                   </Grid>
                              </Grid>
                         {/* );
                    })} */}
               </Table>
          </TableContainer>
     );
};
const useStyles = makeStyles(() => ({
     table: {
          minWidth: 400,
     },
     fontStyleTitle: {
          fontSize: 18,
          fontWeight: 600,
          color: "#000",
     },
     fontStyleDetail: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
          marginTop: 20,
     },
     fontStyleDetailnoMargin: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
     },
     fontEdit: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
          marginTop: 20,
          color: "#184B8E",
     },
}));
export default GridTableNetwork;
