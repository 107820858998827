import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { CHANGEPASSWORD, POST } from "service";
import ChangePasswordCard from "./components/ChangePasswordCard";
import swal from "sweetalert2";

export class ChangePassword extends Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: false,
               language: localStorage.getItem("language"),
          };
     }

     handleChange = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     onClickChangePassword = async () => {
          const { old_password, new_password, confirm_password, language } = this.state;
          if (old_password && new_password && confirm_password) {
               if (new_password.length < 6) {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป" : "Your new_password needs a minimum of six characters"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป" : "Your new_password needs a minimum of six characters"}`,
                    });
               } else if (new_password.search(/[a-z]/) < 0) {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your new_password needs a lower case letter"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your new_password needs a lower case letter"}`,
                    });
               } else if (new_password.search(/[A-Z]/) < 0) {
                    swal.fire({
                         title: `${language === "th" ? `${language === "th" ? "ไม่สำเร็จ" : "Warning"}` : "Warning"}`,
                         text: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your new_password needs an uppser case letter"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your new_password needs an uppser case letter"}`,
                    });
               } else if (new_password.search(/[0-9]/) < 0) {
                    swal.fire({
                         title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your new_password needs a number"}`,
                         icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true,
                    });
                    this.setState({ error: true, error_massage: `${language === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your new_password needs a number"}` });
               } else {
                    if (new_password === confirm_password) {
                         try {
                              this.setState({ loading: true });
                              let change_password = await POST(CHANGEPASSWORD, { old_password, new_password });
                              if (change_password.success) {
                                   this.setState({ loading: false });
                                   swal.fire({ title: "สำเร็จ", icon: "success", 
                                   // timer: 6000, 
                                   showConfirmButton: true });
                                   this.props.history.push('/dashboard')
                              } else {
                                   this.setState({ loading: false });
                                   swal.fire({
                                        title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                                        text: change_password.message,
                                        icon: "warning",
                                        // timer: 6000,
                                        showConfirmButton: true,
                                   });
                              }
                         } catch (error) {
                              this.setState({ loading: false });
                              swal.fire({ title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`, text: error, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({
                              title: `${language === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                              text: `${language === "th" ? "กรุณากรอกรหัสผ่านให้ตรงกัน" : "Password Not Match"}`,
                              icon: "warning",
                              // timer: 6000,
                              showConfirmButton: true,
                         });
                    }
               }
          }
     };

     render() {
          const { loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <ChangePasswordCard state={this.state} handleChange={this.handleChange} onClickChangePassword={this.onClickChangePassword} />
               </Container>
          );
     }
}

export default withRouter(ChangePassword);
