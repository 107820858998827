import { Container, Grid } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { GET, POST, ALLMEMBER, GETIBCHILD, GETIBLISTUSER, TRANSFERIB } from "service";
import Swal from "sweetalert2";
import GridIBTransfer from "./components/GridIBTransfer";

export class IBTransfer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            data: [],
            user_id: this.props.match.params.id,
            data_select: [],
            select_transfer: '',
            select_ib: '',
            data_select_transfer: null,
            data_select_ib: null,
        };
    }
    componentDidMount = () => {
        this.getUser();
    };

    getUser = async () => {
        try {
            this.setState({ loading: true });
            let res = await GET(GETIBLISTUSER);
            if (res.success) {
                let data = res.result;
                data.unshift(
                    {
                        name: "ต่อตรงโบรก",
                        lastname: null,
                        email: null,
                        user_id: 0,
                    }
                );
                this.setState({ data_select: data })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    getIBChild = async (user_id) => {
        try {
            this.setState({ loading: true });
            let res = await POST(GETIBCHILD, { user_id });
            if (res.success) {
                this.setState({ data: res.result, page: 1 })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    handleSave = async () => {
        const { data_select_transfer, data_select_ib } = this.state;
        if (data_select_transfer !== data_select_ib) {
            try {
                this.setState({ loading: true });
                let res = await POST(TRANSFERIB, { transfer_ib_id: data_select_transfer, select_ib_id: data_select_ib });
                if (res.success) {
                    this.setState({ loading: false });
                    Swal.fire({
                        title: "สำเร็จ", text: `${res.result}`, icon: "success",
                        showConfirmButton: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                } else {
                    this.setState({ loading: false });
                    Swal.fire({
                        title: "ไม่สำเร็จ", text: `${res.message}`, icon: "warning",
                        showConfirmButton: true
                    })
                }
            } catch (error) {
                console.log(error)
                this.setState({ loading: false });
            }
        } else {
            Swal.fire({
                title: "ไม่สำเร็จ", text: 'กรุณาเลือกสายงานให้ถูกต้อง', icon: "warning",
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }

    }

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    handleChangeSelect = (value, name) => {
        // console.log(name, value);
        if (value) {
            if (name === 'select_transfer') {
                //call api
                this.getIBChild(value.user_id);
                this.setState({ data_select_transfer: value.user_id });
            } else {
                this.setState({ data_select_ib: value.user_id });
            }
        }
        this.setState({ [name]: value });
    }

    render() {
        const language = localStorage.getItem("language");
        const { loading, page, data_select, data, select_transfer, select_ib } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <GridIBTransfer
                        handleChangePage={this.handleChangePage}
                        handleChangeSelect={this.handleChangeSelect}
                        handleSave={this.handleSave}
                        page={page}
                        data={data}
                        select_transfer={select_transfer}
                        select_ib={select_ib}
                        data_select={data_select}
                    />
                </Grid>
            </Container>
        );
    }
}

export default withRouter(IBTransfer);
