import { Container, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { POST, NETWORKBYID } from "service";
import swal from "sweetalert2";
import TableNetwork from "./TableNetwork";

class NetworkById extends Component {
     constructor(props) {
          super(props);

          this.state = {
               dataId: props.dataId,
               data: [],
          };
     }

     componentDidMount() {
          this.getNetwork();
     }

     getNetwork = async () => {
          const { data } = this.state;
          try {
               this.setState({ loading: true });
               let network = await POST(NETWORKBYID, { user_id: this.state.dataId });
               if (network.success) {
                    let dataNw = network.result;
                    this.setState({ data: dataNw });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: network.message, icon: "warning", 
                    // imer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     render() {
          const language = localStorage.getItem("language");
          const { data } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Grid item xs={12} mt={5} mb={5}>
                         <Typography fontWeight={600}>Network</Typography>
                    </Grid>
                    <TableNetwork data={data} />
               </Container>
          );
     }
}

export default NetworkById;
