import { Grid, Button, Stack } from "@material-ui/core";
import DatePicker from "react-date-picker";
import { withRouter } from "react-router-dom";

const SearchGrid = (props) => {
     const { language, state, handleDateStartChange, handleDateEndChange, onClickSearch } = props;
     return (
          <Grid item xs={8} marginBottom={2}>
               <Stack spacing={2} direction="row">
                    <Grid item xs={4}>
                         <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                    </Grid>
                    <Grid item xs={4}>
                         <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                    </Grid>
                    <Button
                         variant="contained"
                         color="error"
                         //  className="btn-style"
                         sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 50 }}
                         onClick={() => onClickSearch()}
                    >
                         {language === "th" ? "ค้นหา" : "Search"}
                    </Button>
               </Stack>
          </Grid>
     );
};

export default withRouter(SearchGrid);
