import { Grid, Typography, TableContainer, Table } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const GridTableIBMember = (props) => {
     const classes = useStyles();
     return (
          <TableContainer>
               <Table className={classes.table} aria-label="caption table">
                    <Grid item container direction={"row"} spacing={2} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px">
                         <Grid item xs={6}>
                              <Typography className={classes.fontStyleTitle}>Sub IB</Typography>
                         </Grid>
                         <Grid item xs={6}>
                              <Typography className={classes.fontStyleTitle}>Name</Typography>
                         </Grid>
                    </Grid>
                    {props.data.map((el, i) => (
                         <Grid key={i} item container direction={"row"} spacing={1} borderBottom={"1px solid #EBEBEB"} marginBottom="20px" paddingBottom="20px">
                              <Grid item xs={6}>
                                   <Typography className={classes.fontStyleDetail} sx={{ fontWeight: 600 }}>
                                        {i + 1}
                                   </Typography>
                              </Grid>
                              {el.map((ele, ind) => (
                                   <Grid key={ind} item xs={6}>
                                        <Typography className={classes.fontStyleDetail}>
                                             {ele.name} {ele.lastname}
                                        </Typography>
                                   </Grid>
                              ))}
                         </Grid>
                    ))}
                    {props.data.length === 0 && (
                         <Grid item xs={12} container justifyContent={"center"} mt={2}>
                              <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                   {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                              </Typography>
                         </Grid>
                    )}
               </Table>
          </TableContainer>
     );
};
const useStyles = makeStyles(() => ({
     table: {
          minWidth: 400,
     },
     fontStyleTitle: {
          fontSize: 18,
          fontWeight: 600,
          color: "#000",
     },
     fontStyleDetail: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
          marginTop: 20,
     },
}));
export default GridTableIBMember;
