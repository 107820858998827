import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import ListMembers from "./ListMembers/ListMembers";
import { GET, ALLMEMBER } from "service";

export class Members extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               page: 1,
               search_text: "",
          };
     }
     componentDidMount = () => {
          this.getAllMember()
          
     };
     getAllMember = async() =>{
          try{
               let member = await GET(ALLMEMBER)
               if(member.success){
                    this.setState({ data: member.result });
               }
          }catch(e){

          }
     }


     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };
     handleSearch = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };
     submitSearch = () => {
          let { search_text } = this.state;
          let data = dataTable.filter(
               (el) =>
                    (search_text ? el.user.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.name.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.mobile.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.email.toLowerCase().includes(search_text.toLowerCase()) : el)
          );
          this.setState({ data, page: 1 });
     };
     render() {
          const language = localStorage.getItem("language");
          const { page, data } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Grid>
                         <ListMembers
                              language={language}
                              data={data}
                              page={page}
                              handleChangePage={this.handleChangePage}
                              handleSearch={this.handleSearch}
                              state={this.state}
                              submitSearch={this.submitSearch}
                         />
                    </Grid>
               </Container>
          );
     }
}
const dataTable = [
     {
          id: 1,
          user: "Username",
          name: "Name Lastname",
          mobile: "080-000-0000",
          email: "demo@email.com",
          status: "Active",
          date: "2020-05-07",
          active: "Active",
     },
     {
          id: 2,
          user: "Username",
          name: "Name Lastname",
          mobile: "080-000-0000",
          email: "demo@email.com",
          status: "Active",
          date: "2020-05-07",
          active: "Active",
     },
     {
          id: 3,
          user: "Username",
          name: "Name Lastname",
          mobile: "080-000-0000",
          email: "demo@email.com",
          status: "Active",
          date: "2020-05-07",
          active: "Active",
     },
     {
          id: 4,
          user: "Username",
          name: "Name Lastname",
          mobile: "080-000-0000",
          email: "demo@email.com",
          status: "Active",
          date: "2020-05-07",
          active: "Active",
     },
     {
          id: 5,
          user: "Username",
          name: "Name Lastname",
          mobile: "080-000-0000",
          email: "demo@email.com",
          status: "Active",
          date: "2020-05-07",
          active: "Active",
     },
     {
          id: 6,
          user: "Username",
          name: "Name Lastname",
          mobile: "080-000-0000",
          email: "demo@email.com",
          status: "Active",
          date: "2020-05-07",
          active: "Active",
     },
];
export default withRouter(Members);
