import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination, Link, Button, TextField } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const RebateTable = (props) => {
     const { data, state, handleChangeTextRebate, user_id } = props;
     const classes = useStyles();
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   <TableCell align="center" colSpan={1} >
                                        Type
                                   </TableCell>
                                   {props.head.map((element, index) => (
                                        <TableCell align="center" colSpan={6} style={{backgroundColor:element.color }}>
                                             {element.label}
                                        </TableCell>
                                    ))} 
                              
                              </TableRow>
                              <TableRow>
                                   <TableCell>
                                        {/* <Typography noWrap>Type</Typography> */}
                                        <TableCell >
                                             <Typography noWrap>Account Type</Typography>
                                        </TableCell>
                                   </TableCell>
                                   {/* {props.head.map((el, i) => (
                                        <TableCell key={i} align={el.align || "left"}>
                                             <Typography noWrap>{el.label}</Typography> */}
                                             
                                             {props.head.map((element, index) => (
                                                  <>
                                                       {props.accountType?props.accountType.accountType.map((el, i) => (
                                                            <TableCell key={i} style={{backgroundColor:element.color}} >
                                                                 <Typography noWrap>{el.account_type_name?el.account_type_name:null}</Typography>
                                                            </TableCell>
                                                       )):null}
                                                  </>
                                             ))} 
                                        {/* </TableCell>
                                   ))} */}
                              </TableRow>
                              
                         </TableHead>
                         {/* <TableHead>
                              <TableRow>
                                   {props.accountType?props.accountType.accountType.map((el, i) => (
                                        <TableCell key={i} >
                                             <Typography noWrap>{el.account_type_name?el.account_type_name:null}</Typography>
                                        </TableCell>
                                   )):null}
                              </TableRow>
                         </TableHead> */}
                         <TableBody>
                              {data.rebate.map((el, i) => {
                                   return (
                                        <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                             <TableCell>
                                                  <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                       {i === 0 ? "Rebate IB" : `rebate ชั้นที่ ${i}`}
                                                  </Typography>
                                             </TableCell>
                                            
                                                  {props.accountType?props.accountType.accountType.map((ele, index) => (
                                                       <TableCell  key={index}>
                                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                 <Grid container>
                                                                      <TextField
                                                                           className="textfield-profile"
                                                                           fullWidth
                                                                           size="small"
                                                                           type="number"
                                                                           placeholder={"Forex"}
                                                                           name={"forex"}
                                                                           value={el.forex}
                                                                           inputProps={{
                                                                                maxLength: 13,
                                                                                step: "1",
                                                                           }}
                                                                           onChange={(e) => handleChangeTextRebate(e, i, user_id,ele.account_type_id,index)}
                                                                      />
                                                                 </Grid>
                                                            </Typography>
                                                       </TableCell>
                                                  )):null}
                                             {props.accountType?props.accountType.accountType.map((ele, index) => (
                                                  <TableCell>
                                                       <Grid container>
                                                            <TextField
                                                                 className="textfield-profile"
                                                                 fullWidth
                                                                 size="small"
                                                                 type="number"
                                                                 placeholder={"Gold"}
                                                                 name={"gold"}
                                                                 value={el.gold}
                                                                 inputProps={{
                                                                      maxLength: 13,
                                                                      step: "1",
                                                                 }}
                                                                 onChange={(e) => handleChangeTextRebate(e, i, user_id,ele.account_type_id,index)}
                                                            />
                                                       </Grid>
                                                  </TableCell>
                                             )):null}
                                             {props.accountType?props.accountType.accountType.map((ele, index) => (
                                                  <TableCell>
                                                       <Grid container>
                                                            <TextField
                                                                 className="textfield-profile"
                                                                 fullWidth
                                                                 size="small"
                                                                 type="number"
                                                                 placeholder={"Crypto"}
                                                                 name={"crypto"}
                                                                 value={el.crypto}
                                                                 inputProps={{
                                                                      maxLength: 13,
                                                                      step: "1",
                                                                 }}
                                                                 onChange={(e) => handleChangeTextRebate(e, i, user_id,ele.account_type_id,index)}
                                                            />
                                                       </Grid>
                                                  </TableCell>
                                             )):null}
                                             {props.accountType?props.accountType.accountType.map((ele, index) => (
                                                  <TableCell>
                                                       <Grid container>
                                                            <TextField
                                                                 className="textfield-profile"
                                                                 fullWidth
                                                                 size="small"
                                                                 type="number"
                                                                 placeholder={"Index"}
                                                                 name={"index"}
                                                                 value={el.index}
                                                                 inputProps={{
                                                                      maxLength: 13,
                                                                      step: "1",
                                                                 }}
                                                                 onChange={(e) => handleChangeTextRebate(e, i, user_id,ele.account_type_id,index)}
                                                            />
                                                       </Grid>
                                                  </TableCell> 
                                             )):null}
                                             {props.accountType?props.accountType.accountType.map((ele, index) => (
                                                  <TableCell>
                                                       <Grid container>
                                                            <TextField
                                                                 className="textfield-profile"
                                                                 fullWidth
                                                                 size="small"
                                                                 type="number"
                                                                 placeholder={"Oil"}
                                                                 name={"oil"}
                                                                 value={el.oil}
                                                                 inputProps={{
                                                                      maxLength: 13,
                                                                      step: "1",
                                                                 }}
                                                                 onChange={(e) => handleChangeTextRebate(e, i, user_id,ele.account_type_id,index)}
                                                            />
                                                       </Grid>
                                                  </TableCell>
                                             )):null}
                                        </TableRow>
                                   );
                              })}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {/* {props.body.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    )}
                    {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />} */}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     fontActive: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
});

export default RebateTable;
