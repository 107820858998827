import { Grid, Button, Stack, Typography, TextField } from "@material-ui/core";
import { header_csv } from "functions/Static";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";

const searchGrid = (props) => {
     const { language, handleChangeText, onClickSearch, state, data_trading_csv } = props;

     const csvReportTrading = {
          data: data_trading_csv,
          headers: header_csv.trading_account,
          filename: "trading_accounts_report.csv",
     };

     return (
          <>
               <Grid container display="flex" alignItems="center" columnSpacing={2} rowSpacing={2} marginBottom={2}>
                    <Grid item xs={12} sm={6} md={6}>
                         <Stack spacing={2} direction="row">
                              <Typography>MT4 Balance : </Typography>
                              <Typography sx={{ color: "#184B8E" }}>${props.balance || "0.00"}</Typography>
                         </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} display="flex" justifyContent={{ xs: "flex-start", sm: "flex-end", md: "flex-end" }}>
                         <Grid item sx={{ width: 200 }}>
                              <CSVLink {...csvReportTrading} style={{ color: "#fff", textDecoration: "none" }}>
                                   <Button variant="contained" style={{ backgroundColor: "#4589C6" }} sx={{ width: "100%", fontSize: 16, width: "100%", borderRadius: 40, height: 50 }}>
                                        Export data
                                   </Button>
                              </CSVLink>
                         </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                         <Stack spacing={2} direction="row">
                              <Grid item sx={{ width: "65%" }}>
                                   <TextField
                                        placeholder="User Account"
                                        sx={{ width: "100%" }}
                                        value={state.search_text}
                                        name="search_text"
                                        onChange={handleChangeText}
                                        onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                  onClickSearch();
                                             }
                                        }}
                                   />
                              </Grid>
                              {/* <TextField placeholder="Date" sx={{ width: "100%" }} /> */}
                              <Button
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   //  className="btn-style"
                                   sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 50 }}
                                   onClick={onClickSearch}
                              >
                                   {language === "th" ? "ค้นหา" : "Search"}
                              </Button>
                         </Stack>
                    </Grid>
                    {/* <Grid item xs={12} marginBottom={2}>
                         <Stack spacing={2} direction="row" marginBottom={2} justifyContent="space-between">
                              <Grid container direction={"row"}>
                                   <Typography>MT4 Balance : </Typography>
                                   <Typography sx={{ color: "#184B8E" }}>${props.balance || "0.00"}</Typography>
                              </Grid>
                              <Grid item sx={{ width: 200 }}>
                                   <CSVLink {...csvReportTrading} style={{ color: "#fff", textDecoration: "none" }}>
                                        <Button variant="contained" style={{ backgroundColor: "#4589C6" }} sx={{ width: "100%", fontSize: 16, width: "100%", borderRadius: 40, height: 50 }}>
                                             Export data
                                        </Button>
                                   </CSVLink>
                              </Grid>
                         </Stack>
                         <Grid xs={8}>
                              <Stack spacing={2} direction="row">
                                   <TextField
                                        placeholder="User Account"
                                        sx={{ width: "100%" }}
                                        value={state.search_text}
                                        name="search_text"
                                        onChange={handleChangeText}
                                        onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                  onClickSearch();
                                             }
                                        }}
                                   />
                                   <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#4589C6" }}
                                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 50 }}
                                        onClick={onClickSearch}
                                   >
                                        {language === "th" ? "ค้นหา" : "Search"}
                                   </Button>
                              </Stack>
                         </Grid>
                    </Grid> */}
               </Grid>
          </>
     );
};

export default withRouter(searchGrid);
