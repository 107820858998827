import React from "react";
import { Grid } from "@material-ui/core";

const LoginLayout = (props) => {
     const path = window.location.pathname.replace("/", "");
     return (
          <Grid className={`${path}-layout`}>
               <Grid sx={{ position: "relative", background: "tranparent", width: "100vw", py: 5 }}>{props.children}</Grid>
          </Grid>
     );
};
export default LoginLayout;
