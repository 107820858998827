import { Grid, TextField, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";

const TextfielAdd = (props) => {
     const onClick = (btn) => {
          props.onClickDelete(btn);
     };
     const onChange = (event, index) => {
          props.handleChange(event, index);
     };

     return (
          <Grid Grid container item xs={12} spacing={2}>
               {props.data.map((e, index) => {
                    return (
                         <>
                              <Grid item xs={e.xs} textAlign="center">
                                   <TextField
                                        placeholder={e.placeholder ? e.placeholder : ""}
                                        label={e.label || ""}
                                        name={e.name || ""}
                                        type={e.type || ""}
                                        value={props.state[e.name] || ""}
                                        onChange={(event) => onChange(event, index)}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onKeyDown={props.onKeyDown}
                                        autoComplete="off"
                                   ></TextField>
                              </Grid>
                              <Grid item xs={e.xs} textAlign="center">
                                   <TextField
                                        placeholder={e.placeholder2 ? e.placeholder2 : ""}
                                        label={e.label2 || ""}
                                        name={e.name2 || ""}
                                        type={e.type || ""}
                                        value={props.state[e.name2] || ""}
                                        onChange={(event) => onChange(event, index)}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onKeyDown={props.onKeyDown}
                                        autoComplete="off"
                                   ></TextField>
                              </Grid>
                              <Grid xs={2} item textAlign="center" justifyContent="center" marginTop={-1}>
                                   <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 10, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={() => onClick(e.btn)}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </>
                    );
               })}
          </Grid>
     );
};

export default withRouter(TextfielAdd);
