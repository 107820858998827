import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import PromotionList from "./components/PromotionList";
import { GET, PROMOTIONAPI } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

export class Promotion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            page: 1,
            search_text: "",
            loading: false,
        };
    }
    componentDidMount = () => {
        this.getPromotion();
    };

    getPromotion = async () => {
        try {
            this.setState({ loading: true });
            let res = await GET(PROMOTIONAPI);
            if (res.success) {
                this.setState({ data: res.result, });
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onClickEdit = (id) => {
        this.props.history.push(`/promotion_edit/${id}`);
    };

    render() {
        const language = localStorage.getItem("language");
        const { page, data, generate, url, loading } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <PromotionList
                        language={language}
                        data={data}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        handleSearch={this.handleSearch}
                        state={this.state}
                        onClickEdit={this.onClickEdit}
                    />
                </Grid>
            </Container>
        );
    }
}
const dataTable = [
    {
        id: 1,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        id: 2,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        id: 3,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        id: 4,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        id: 5,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
    {
        id: 6,
        promotion_name: "Promotion Name",
        date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        date_end: new Date(),
    },
];
export default withRouter(Promotion);
