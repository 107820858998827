import { Grid, Tabs, Tab } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const DetailTabs = (props) => {
     const [value, setValue] = React.useState(1);
     const handleChange = (event, newValue) => {
          setValue(newValue);
          props.setTap(newValue);
     };
     const classes = useStyles();
     return (
          <Grid item container justifyContent="center" alignItems="center">
               <Grid item xs={12} className="taps-css">
                    <Tabs value={value} onChange={handleChange} centered variant="fullWidth" indicatorColor="#4589C6">
                         <Tab value={1} label="Deposit" />
                         <Tab value={2} label="Withdrawal" />
                         {props.role == "admin" ?
                              <Tab value={3} label="MT4" /> : null
                         }

                         {/* <Tab value={3} label="IB Network" /> */}
                    </Tabs>
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles(() => ({
     tab: {
          color: "#fff",
     },
     tabSelected: {
          backgroundColor: "#4589C6"
     }
}));

export default DetailTabs;
