import { Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import { StringSet, textFiled } from "../../functions/Static";
import TextfieldPromotion from "./Components/TextfieldPromotion";
import swal from "sweetalert2";
import { ADDPORMOTIONAPI, UPLOADIMAGEPORMOTIONAPI, PROMOTIONBYIDAPI, EDITPROMOTION, POST, ip, UPLOADIMAGEPORMOTIONENAPI } from "service";
import Loading from "components/Loading/Loading";
class SettingAddPromotion extends Component {
     constructor(props) {
          super(props);

          this.state = {
               add: 1,
               promotion_array_en: [],
               promotion_array_th: [],
               requirements_th: "",
               requirements_en: "",
               image: "",
               path: window.location.pathname,
               titleGrid: [
                    {
                         xs: 5,
                         // sm: 6,
                         // md: 3,
                         classNameGrid: "pt-0",
                         classNameText: "textfield-css mt-4",
                         placeholder: "Condition and Benefits",
                         title: "Condition and Benefits",
                         name: "condition_benefits_en1",
                         placeholder2: "Condition and Benefits(ภาษาไทย)",
                         title2: "Condition and Benefits(ภาษาไทย)",
                         name2: "condition_benefits_th1",
                         button: "Delete",
                         btn: "btn",
                         path: window.location.pathname,
                    },
               ],
               loading: false,
          };
     }

     componentDidMount() {
          if (this.state.path !== StringSet.promotion.link) {
               this.getPromotionById();
          }
     }

     getPromotionById = async () => {
          let { add, titleGrid } = this.state;
          try {
               this.setState({ loading: true });
               let promotion = await POST(PROMOTIONBYIDAPI, { promotion_id: this.props.match.params.id });
               if (promotion.success) {
                    let promotion_name = JSON.parse(promotion.result.promotion_name);
                    let promotion_detail = JSON.parse(promotion.result.promotion_detail);
                    let promotion_image = promotion.result.promotion_img;
                    let promotion_image_en = promotion.result.promotion_img_en;
                    let promotion_array = JSON.parse(promotion.result.promotion_array);
                    let array_th = JSON.parse(promotion_array.th);
                    let array_en = JSON.parse(promotion_array.en);
                    let requirements = promotion.result.promotion_requirment;
                    let requirements_th;
                    let requirements_en;
                    if (requirements !== undefined) {
                         requirements_th = JSON.parse(requirements).th;
                         requirements_en = JSON.parse(requirements).en;
                    }
                    titleGrid = [];
                    for (let index = 0; index < array_en.length; index++) {
                         titleGrid.push({
                              xs: 5,
                              // sm: 6,
                              // md: 3,
                              classNameGrid: "pt-0",
                              classNameText: "textfield-css mt-4",
                              placeholder: "Condition and Benefits",
                              title: "Condition and Benefits",
                              name: "condition_benefits_en" + add,
                              placeholder2: "Condition and Benefits(ภาษาไทย)",
                              title2: "Condition and Benefits(ภาษาไทย)",
                              name2: "condition_benefits_th" + add,
                              typeincon: "delete",
                              btn: "btn" + add,
                         });
                         let name_en = "condition_benefits_en" + add;
                         let name_th = "condition_benefits_th" + add;
                         add++;
                         this.setState({ [name_en]: array_en[index], [name_th]: array_th[index], titleGrid, add });
                    }
                    this.setState({
                         name_en: promotion_name.en,
                         name_th: promotion_name.th,
                         detail_en: promotion_detail.en,
                         detail_th: promotion_detail.th,
                         promotion_image: promotion_image,
                         promotion_image_en: promotion_image_en,
                         previewImage: ip + promotion_image,
                         previewImage_en: ip + promotion_image_en,
                         promotion_array_en: array_en,
                         promotion_array_th: array_th,
                         requirements_th,
                         requirements_en,
                         loading: false,
                    });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: promotion.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleChangeTextArray = (e, i) => {
          let { promotion_array_en, promotion_array_th } = this.state;
          if (e.target.name.includes("condition_benefits_en")) {
               promotion_array_en[i] = e.target.value;
               this.setState({ promotion_array_en, [e.target.name]: e.target.value });
          } else {
               promotion_array_th[i] = e.target.value;
               this.setState({ promotion_array_th, [e.target.name]: e.target.value });
          }
     };

     onClickAdd = (e) => {
          let { add, titleGrid } = this.state;
          add++;
          titleGrid.push({
               xs: 5,
               // sm: 6,
               // md: 3,
               classNameGrid: "pt-0",
               classNameText: "textfield-css mt-4",
               placeholder: "Condition and Benefits",
               title: "Condition and Benefits",
               name: "condition_benefits_en" + add,
               placeholder2: "Condition and Benefits(ภาษาไทย)",
               title2: "Condition and Benefits(ภาษาไทย)",
               name2: "condition_benefits_th" + add,
               typeincon: "delete",
               btn: "btn" + add,
          });
          this.setState({ add });
     };

     onClickDelete = (btn) => {
          let { promotion_array_en, promotion_array_th, titleGrid } = this.state;
          promotion_array_en.splice(
               titleGrid.findIndex((el) => el.btn === btn),
               1
          );
          promotion_array_th.splice(
               titleGrid.findIndex((el) => el.btn === btn),
               1
          );
          titleGrid.splice(
               titleGrid.findIndex((el) => el.btn === btn),
               1
          );
          this.setState({ titleGrid, promotion_array_en, promotion_array_th });
     };

     onChangeFile = async (e) => {
          const reader = new FileReader();
          reader.onload = () => {
               if (reader.readyState === 2) {
                    this.setState({ previewImage: reader.result });
               }
          };
          reader.readAsDataURL(e.target.files[0]);
          // try {
          //      let form_data = new FormData();
          //      form_data.append("promotion_id", this.props.match.params.id);
          //      form_data.append("promotion_image", e.target.files[0]);
          //      let uploadImage = await POST(UPLOADIMAGEPORMOTIONAPI, form_data, true);
          //      if (uploadImage.success) {
          //           this.setState({ loading: false });
          //           swal.fire({ title: "สำเร็จ", text: "อัพเดตรูปภาพสำเร็จ", icon: "success", timer: 1800, showConfirmButton: false });
          //      } else {
          //           this.setState({ loading: false });
          //           swal.fire({ title: "แจ้งเตือน", text: uploadImage.message, icon: "warning", timer: 1800, showConfirmButton: false });
          //      }
          // } catch (error) {
          //      swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", timer: 1800, showConfirmButton: false });
          // }
          this.setState({ promotion_image: e.target.files[0].name, image: e.target.files[0] });
     };

     onChangeFileEN = async (e) => {
          const reader = new FileReader();
          reader.onload = () => {
               if (reader.readyState === 2) {
                    this.setState({ previewImage_en: reader.result });
               }
          };
          reader.readAsDataURL(e.target.files[0]);
          // try {
          //      let form_data = new FormData();
          //      form_data.append("promotion_id", this.props.match.params.id);
          //      form_data.append("promotion_image", e.target.files[0]);
          //      let upload_image_en = await POST(UPLOADIMAGEPORMOTIONENAPI, form_data, true);
          //      if (upload_image_en.success) {
          //           this.setState({ loading: false });
          //           swal.fire({ title: "สำเร็จ", text: "อัพเดตรูปภาพสำเร็จ", icon: "success", timer: 1800, showConfirmButton: false });
          //      } else {
          //           this.setState({ loading: false });
          //           swal.fire({ title: "แจ้งเตือน", text: upload_image_en.message, icon: "warning", timer: 1800, showConfirmButton: false });
          //      }
          // } catch (error) {
          //      console.log("catch", error);
          //      swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", timer: 1800, showConfirmButton: false });
          // }
          this.setState({ promotion_image_en: e.target.files[0].name, image_en: e.target.files[0] });
     };

     onClickAddPromotion = async (e) => {
          const {
               name_th,
               name_en,
               detail_th,
               detail_en,
               promotion_array_en,
               promotion_array_th,
               image,
               requirements_th,
               requirements_en,
               promotion_image,
               promotion_image_en,
               image_en,
          } = this.state;
          let dataPass = {
               name_th: name_th,
               name_en: name_en,
               detail_th: detail_th,
               detail_en: detail_en,
               // promotion_image: promotion_image,
               promotion_array_en: JSON.stringify(promotion_array_en),
               promotion_array_th: JSON.stringify(promotion_array_th),
               promotion_requirment_th: requirements_th,
               promotion_requirment_en: requirements_en,
               // image: image
          };
          if (name_th && name_en && detail_th && detail_en && promotion_array_en !== [] && promotion_array_th !== [] && promotion_image && promotion_image_en) {
               try {
                    this.setState({ loading: true });
                    let addPromotion = await POST(ADDPORMOTIONAPI, dataPass);
                    if (addPromotion.success) {
                         let promotion_id = addPromotion.result.promotion_id;
                         let form_data_en = new FormData();
                         form_data_en.append("promotion_id", promotion_id);
                         form_data_en.append("promotion_image", image_en);
                         let upload_image_en = await POST(UPLOADIMAGEPORMOTIONENAPI, form_data_en, true);
                         let form_data = new FormData();
                         form_data.append("promotion_id", promotion_id);
                         form_data.append("promotion_image", image);
                         let uploadImage = await POST(UPLOADIMAGEPORMOTIONAPI, form_data, true);
                         if (upload_image_en.success && uploadImage.success) {
                              this.setState({ loading: false });
                              swal.fire({ title: "สำเร็จ", text: "เพิ่มโปรโมชันสำเร็จ", icon: "success", 
                              // timer: 6000, 
                              showConfirmButton: true });
                              window.location.assign("/setting_promotion");
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "แจ้งเตือน", text: upload_image_en.message || uploadImage.message, icon: "warning", 
                              // timer: 6000, 
                              showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: addPromotion.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } else {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: "กรุณากรอกข้อมูลให้ครบถ้วน", icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onChangePath = (path) => {
          if (path === "/setting_manage_promotion/add") {
               this.setState({
                    name_th: null,
                    name_en: null,
                    detail_th: null,
                    detail_en: null,
                    forex_id: null,
                    promotion_array_en: [],
                    promotion_array_th: [],
                    promotion_image: null,
                    previewImage: null,
                    titleGrid: [
                         {
                              xs: 5,
                              // sm: 6,
                              // md: 3,
                              classNameGrid: "pt-0",
                              classNameText: "textfield-css mt-4",
                              placeholder: "Condition and Benefits",
                              title: "Condition and Benefits",
                              name: "condition_benefits_en1",
                              placeholder2: "Condition and Benefits(ภาษาไทย)",
                              title2: "Condition and Benefits(ภาษาไทย)",
                              name2: "condition_benefits_th1",
                              button: "Delete",
                              btn: "btn",
                              path: window.location.pathname,
                         },
                    ],
               });
          }
          this.setState({ path });
     };

     onClickEdit = async () => {
          console.log(`this.state`, this.state);
          try {
               this.setState({ loading: true });
               const { name_th, name_en, detail_th, detail_en, promotion_array_th, promotion_array_en, requirements_th, requirements_en, image, image_en } = this.state;
               let obj = {
                    name_th,
                    name_en,
                    detail_th,
                    detail_en,
                    promotion_array_th: JSON.stringify(promotion_array_th),
                    promotion_array_en: JSON.stringify(promotion_array_en),
                    promotion_requirment_th: requirements_th,
                    promotion_requirment_en: requirements_en,
                    promotion_id: this.props.match.params.id,
               };
               let edit = await POST(EDITPROMOTION, obj);
               if (edit.success) {
                    let uploadImage;
                    let upload_image_en;
                    if (image_en) {
                         let form_data_en = new FormData();
                         form_data_en.append("promotion_id", this.props.match.params.id);
                         form_data_en.append("promotion_image", image_en);
                         upload_image_en = await POST(UPLOADIMAGEPORMOTIONENAPI, form_data_en, true);
                    }
                    if (image) {
                         let form_data = new FormData();
                         form_data.append("promotion_id", this.props.match.params.id);
                         form_data.append("promotion_image", image);
                         uploadImage = await POST(UPLOADIMAGEPORMOTIONAPI, form_data, true);
                    }
                    if (upload_image_en?.success || uploadImage?.success || edit?.success) {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไขโปรโมชันสำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         window.location.assign("/setting_promotion");
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: upload_image_en.message || uploadImage.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: edit.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     render() {
          return (
               <Container maxWidth="xl">
                    <Loading open={this.state.loading} />
                    <TextfieldPromotion
                         state={this.state}
                         textFiled={textFiled.add_promotion}
                         textFiled_image={textFiled.add_promotion_image_en}
                         textFiled_back={textFiled.add_promotion_back}
                         titleGrid={this.state.titleGrid}
                         onClickAdd={this.onClickAdd}
                         onClickDelete={this.onClickDelete}
                         handleChangeText={(e) => this.handleChangeText(e)}
                         onChangeFileEN={this.onChangeFileEN}
                         onChangeFile={this.onChangeFile}
                         onClickAddPromotion={this.onClickAddPromotion}
                         onClickEditPromotion={this.onClickEdit}
                         handleChangeTextArray={(e, i) => this.handleChangeTextArray(e, i)}
                         image={this.state.previewImage}
                         image_en={this.state.previewImage_en}
                         path={this.state.path}
                         StringSet={StringSet.promotion}
                         onChangePath={this.onChangePath}
                    />
               </Container>
          );
     }
}

export default withRouter(SettingAddPromotion);
