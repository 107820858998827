import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Fade, Grid, Modal, Backdrop, Typography, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
const theme = createMuiTheme();


const head = [{ label: "MT4" }, { label: "Cashback Freedom" }, { label: "Cashback Standard Swap Free" }, { label: "Cashback Standard" }, { label: "Cashback Raw" }];

const ModalCashback = (props) => {
     const { open, onClickCloseCashback, state, handleChangeCashback, onClickUpdateCashback, header_cashback } = props;
     const classes = useStyles();
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               // onClose={handleClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         {/* {console.log("data_get_cashback", state.data_get_cashback)} */}
                         <Grid container justifyContent="flex-end" xs={12}>
                              <Button onClick={() => onClickCloseCashback()} className={classes.buttonClose}>
                                   <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                              </Button>
                         </Grid>

                         <Grid className="table-btn">
                              <TableContainer>
                                   <Grid item container justifyContent={"center"} mb={2}>
                                        <Typography className={classes.fontStyleTitle}>
                                             Account : {state.name}
                                        </Typography>
                                   </Grid>
                                   <Grid>
                                        <Table className={classes.table}>
                                             <TableHead>
                                                  <TableRow>
                                                       <TableCell align="center" colSpan={12}>
                                                            <Typography noWrap>Cashback</Typography>
                                                       </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                       <TableCell>
                                                            <Typography noWrap>MT4</Typography>
                                                       </TableCell>
                                                       {header_cashback.map((e, ind) => {
                                                            return (
                                                                 <TableCell key={ind}>
                                                                      <Typography noWrap>{e}</Typography>
                                                                 </TableCell>
                                                            );
                                                       })}
                                                  </TableRow>
                                             </TableHead>
                                             <TableBody>
                                                  <TableRow hover className="bg-table">
                                                       <TableCell>
                                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                 MT4
                                                            </Typography>
                                                       </TableCell>
                                                       {state.data_get_cashback.map((el, index) => {
                                                            return (
                                                                 <TableCell key={index}>
                                                                      <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                           <Grid container rowSpacing={3}>
                                                                                <Grid item xs={12} mt={3}>
                                                                                     <TextField
                                                                                          className="textfield-profile"
                                                                                          label="Standard"
                                                                                          focused
                                                                                          fullWidth
                                                                                          size="small"
                                                                                          type="number"
                                                                                          placeholder={Number(el.get_cb).toFixed(2)}
                                                                                          name={el.account_type_name}
                                                                                          value={el.value_cb}
                                                                                          inputProps={{
                                                                                               maxLength: 13,
                                                                                               step: "1",
                                                                                          }}
                                                                                          // onChange={handleChangeCashback}
                                                                                          onChange={(e) =>
                                                                                               handleChangeCashback(e, el.id, "standard")
                                                                                          }
                                                                                     />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                     <TextField
                                                                                          className="textfield-profile"
                                                                                          label="Gold"
                                                                                          focused
                                                                                          fullWidth
                                                                                          size="small"
                                                                                          type="number"
                                                                                          placeholder={Number(el.get_cb_gold).toFixed(2)}
                                                                                          name={el.account_type_name_gold}
                                                                                          value={el.value_cb_gold}
                                                                                          inputProps={{
                                                                                               maxLength: 13,
                                                                                               step: "1",
                                                                                          }}
                                                                                          // onChange={handleChangeCashback}
                                                                                          onChange={(e) =>
                                                                                               handleChangeCashback(e, el.id, "gold")
                                                                                          }
                                                                                     />
                                                                                </Grid>


                                                                           </Grid>
                                                                      </Typography>
                                                                 </TableCell>
                                                            );
                                                       })}
                                                  </TableRow>
                                             </TableBody>
                                        </Table>
                                        <Grid item container justifyContent={"center"} mt={5} mb={5}>
                                             <Button
                                                  variant="contained"
                                                  // color="error"
                                                  style={{ backgroundColor: "#4589C6" }}
                                                  sx={{ borderRadius: 5, width: 100, fontSize: 16 }}
                                                  onClick={() => {
                                                       onClickUpdateCashback();
                                                       onClickCloseCashback();
                                                  }}                                             >
                                                  Save
                                             </Button>
                                        </Grid>
                                   </Grid>
                              </TableContainer>
                         </Grid>
                    </Grid>
               </Fade>
               {/* <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container justifyContent="flex-end" xs={12}>
                              <Button onClick={() => onClickCloseCashback()} className={classes.buttonClose}>
                                   <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                              </Button>
                         </Grid>
                         <Grid container xs={12} justifyContent="center" spacing={2}>
                              <Typography fontWeight={600}>Cashback</Typography>
                              <Grid item xs={12} container direction={"row"}>
                                   <Typography fontWeight={600} mr={2}>
                                        Account : {state.name}
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} container direction={"row"} display="flex" alignItems="center">
                                   <Typography fontWeight={600} mr={2}>
                                        Cashback Gold : {Number(state.cashback_gold).toFixed(1)}
                                   </Typography>
                              </Grid>
                              <Grid item container justifyContent="flex-end" xs={12}>
                                   <Grid item xs={5} container direction={"row"} display="flex" alignItems="center">
                                        <Typography fontWeight={600} mr={2}>
                                             Change Gold :
                                        </Typography>
                                   </Grid>
                                   <Grid item xs={7} container direction={"row"} display="flex" alignItems="center">
                                        <TextField
                                             maxWidth={'80%'}
                                             size="small"
                                             type="text"
                                             placeholder="0.0"
                                             name="update_gold"
                                             value={state.update_gold}
                                             onChange={handleChangeCashback}
                                        />
                                   </Grid>
                              </Grid>
                              <Grid item xs={12} container direction={"row"} display="flex" alignItems="center">
                                   <Typography fontWeight={600} mr={2}>
                                        Cashback Standard : {Number(state.cashback_standard).toFixed(1)}
                                   </Typography>
                              </Grid>
                              <Grid item container justifyContent="flex-end" xs={12}>
                                   <Grid item xs={5} container direction={"row"} display="flex" alignItems="center">
                                        <Typography fontWeight={600} mr={2}>
                                             Change Standard :
                                        </Typography>
                                   </Grid>
                                   <Grid item xs={7} container direction={"row"} display="flex" alignItems="center">
                                        <TextField
                                             maxWidth={'80%'}
                                             size="small"
                                             type="text"
                                             placeholder="0.0"
                                             name="update_standard"
                                             value={state.update_standard}
                                             onChange={handleChangeCashback}
                                        />
                                   </Grid>
                              </Grid>

                              <Button className={classes.btnStyle} variant="outlined" color="error" onClick={() => onClickCloseCashback()}>
                                   close
                              </Button>
                              <Button
                                   disabled={state.active}
                                   className={classes.btnStyle}
                                   variant="contained"
                                   style={{ backgroundColor: "#4589C6" }}
                                   onClick={() => {
                                        onClickUpdateCashback();
                                        onClickCloseCashback();
                                   }}
                              >
                                   Submit
                              </Button>
                         </Grid>
                    </Grid>
               </Fade> */}
          </Modal>
     );
};

const useStyles = makeStyles(() => ({
     table: {
          minWidth: 320,
     },
     fontActive: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "80%",
          minWidth: "80%",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "10px 18px 25px",
          borderRadius: "8px",
     },
     btnStyle: { maxWidth: 150, fontSize: 16, width: "100%", borderRadius: 40, marginTop: 10, marginLeft: 5, marginRight: 5, marginTop: 50 },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
}));

export default ModalCashback;
