import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import GridAdd from "./components/GridAdd";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";
import { EditorState, convertToRaw } from 'draft-js';
import { POST, ADDPORMOTIONAPI, UPLOADIMAGEPORMOTIONAPI } from "service";

export class AddPromotion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotion_data: [],
            page: 1,
            search_text: "",
            loading: false,
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
            promotion_name: "",
            editorState: EditorState.createEmpty(),
            file: "",
            token: localStorage.getItem("token"),
        };
    }
    // componentDidMount = () => {

    // };

    uploadImage = async (e) => {
        const { promotion_data } = this.state;
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                promotion_data.profile_image = reader.result;
                this.setState({ promotion_data: promotion_data });
            }
        };
        reader.readAsDataURL(e.target.files[0]);
        this.setState({ file: e.target.files[0] });
    };

    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    handleChangeText = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChangeEditor = (editorState) => {
        this.setState({ editorState })
    };

    onClickAddImg = async (promotion_id) => {
        const { file } = this.state;

        try {
            let form_data = new FormData();
            form_data.append("promotion_id", promotion_id)
            form_data.append("promotion_image", file)
            let add = await POST(UPLOADIMAGEPORMOTIONAPI, form_data, true);
            if (add.success) {
                swal.fire({
                    title: "Success",
                    text: "เพิ่มโปรโมชันสำเร็จ",
                    icon: "success",
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push(`/promotion_list`);
                    }
                })
            } else {
                swal.fire({
                    title: "Warning", text: add.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };

    onClickAddPromotion = async () => {
        const { promotion_name, editorState, date_start, date_end, file } = this.state;

        try {
            if (promotion_name && editorState && date_start && date_end) {

                let addPromotion = await POST(ADDPORMOTIONAPI, { promotion_name, promotion_detail: JSON.stringify(convertToRaw(editorState.getCurrentContent())), start: date_start, expire: date_end });
                if (addPromotion.success) {
                    if (file) {
                        this.onClickAddImg(addPromotion.result.promotion_id)
                    } else {
                        swal.fire({
                            title: "Success",
                            text: "เพิ่มโปรโมชันสำเร็จ",
                            icon: "success",
                            showConfirmButton: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.props.history.push(`/promotion_list`);
                            }
                        })
                    }
                } else {
                    swal.fire({
                        title: "Warning", text: addPromotion.message, icon: "warning",
                        showConfirmButton: true
                    });
                }
            } else {
                swal.fire({
                    title: "Warning",
                    text: "กรุณากรอกข้อมูลให้ครบถ้วน",
                    icon: "success",
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                showConfirmButton: true
            });
        }
    };


    render() {
        const language = localStorage.getItem("language");
        const { page, promotion_data, loading } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <GridAdd
                        language={language}
                        data={promotion_data}
                        page={page}
                        state={this.state}
                        uploadImage={this.uploadImage}
                        handleDateStartChange={this.handleDateStartChange}
                        handleDateEndChange={this.handleDateEndChange}
                        handleChangeText={this.handleChangeText}
                        handleChangeEditor={this.handleChangeEditor}
                        onClickAddPromotion={this.onClickAddPromotion}
                    />
                </Grid>
            </Container>
        );
    }
}
export default withRouter(AddPromotion);
