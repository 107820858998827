import { Button, Container, Grid, InputAdornment, Switch, TextField, Typography } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import Sidebar from "./Components/Sidebar";
import { CONFIGWITHDRAWAUTO, GET, GETTYPEEMAIL, GETWITHDRAWAUTO, POST } from "service";
import Swal from "sweetalert2";
import TableEmail from "./ComponentsEmail/TableEmail";

class SettingEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: [],
        };
    }
    componentDidMount = () => {
        this.getTypeEmail();
    };

    getTypeEmail = async () => {
        try {
            this.setState({ loading: true });
            let res = await GET(GETTYPEEMAIL);
            if (res.success) {
                this.setState({ data: res.result });
                this.setState({ loading: false });
            } else {
                Swal.fire({
                    title: "ไม่สำเร็จ", text: 'ดึงข้อมูลไม่สำเร็จ', icon: "warning",
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            Swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    onClickEdit = (id) => {
        this.props.history.push(`setting_email/edit/${id}`);
    };

    render() {
        const language = localStorage.getItem("language");
        const { loading, data } = this.state;
        return (
            <Container maxWidth="xl">
                <Loading open={loading} />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Sidebar title="Setting E-mail" pageName="SettingEmail" />
                    </Grid>
                    <Grid item xs={9}>
                        <HeaderTypography title={"Setting E-mail"} />

                        <Grid container columns={12} spacing={3}>
                            <Grid item xs={12}>
                                <TableEmail
                                    data={data}
                                    onClickEdit={this.onClickEdit}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withRouter(SettingEmail);
