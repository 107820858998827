import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import { StringSet } from "functions/Static";
import Sidebar from "./Sidebar";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const ListQuestion = (props) => {
     const { data, onClickEdit } = props;
     const classes = useStyles();
     return (
          <Grid container spacing={2}>
               <Grid item xs={3}>
                    <Sidebar title={"Menu"} pageName={"FAQ"} data={StringSet.faq.sidebar} />
               </Grid>
               <Grid item xs={9} className="question-page">
                    <HeaderTypography title="FAQ" />
                    {data.map((el, i) => (
                         <Accordion key={i} defaultExpanded={i === 0}>
                              <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel1a-content" id="panel1a-header">
                                   <Grid container justifyContent="space-between">
                                        <Grid item>
                                             <Typography className={classes.fontStyleTitle}>{el.faq_question}</Typography>
                                        </Grid>
                                        <Grid item direction="row">
                                             <Button
                                                  variant="contained"
                                                  color="error"
                                                  onClick={() => onClickEdit(el.faq_id)}
                                                  sx={{ minWidth: 120, fontSize: 16, borderRadius: 40, height: 40, marginRight: 2 }}
                                             >
                                                  Edit
                                             </Button>
                                             {/* <Button
                                                  variant="outlined"
                                                  color="error"
                                                  onClick={() => onClickDelete(el.faq_type_id)}
                                                  sx={{ minWidth: 120, fontSize: 16, borderRadius: 40, height: 40 }}
                                             >
                                                  Delete
                                             </Button> */}
                                        </Grid>
                                   </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                   {/* {el.array.map((ele, ind) => ( */}
                                   <Grid key={i} className="question-page-body">
                                        <Accordion key={i} defaultExpanded={i === 0}>
                                             {/* <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel1a-content" id="panel1a-header">
                                                       <Typography sx={{ fontSize: 18, color: "#000" }}>
                                                            {ind + 1}. {el.faq_question}
                                                       </Typography>
                                                  </AccordionSummary> */}
                                             <AccordionDetails>
                                                  <Grid key={i} px={2}>
                                                       <div dangerouslySetInnerHTML={{ __html: el.faq_answer }} />
                                                  </Grid>
                                             </AccordionDetails>
                                        </Accordion>
                                   </Grid>
                                   {/* ))} */}
                              </AccordionDetails>
                         </Accordion>
                    ))}
               </Grid>
          </Grid>
     );
};
const useStyles = makeStyles(() => ({
     fontStyleTitle: {
          fontSize: 18,
          fontWeight: 600,
          color: "#000",
     },
     fontStyleDetail: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
     },
}));
export default ListQuestion;
