import { Container, Grid } from "@material-ui/core";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import { StringSet } from "functions/Static";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ListAccountType from "./Components/ListAccountType";
import Sidebar from "./Components/Sidebar";
import { POST, ACCOUNTTYPEAPI, DELETEACCOUNTTYPEAPI, ACCOUNTTYPEUPDATESTATUSAPI } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

class SettingAccountType extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               loading: false,
          };
     }

     componentDidMount() {
          this.getAccountType();
     }

     async getAccountType() {
          try {
               this.setState({ loading: true });
               let account = await POST(ACCOUNTTYPEAPI, {});
               if (account.success) {
                    this.setState({ data: account.result.map((e) => ({ ...e, expand: 0 })) });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
          }
     }

     onClickUpdateStatus = async (account_type_id) => {
          const { data } = this.state;
          const indexObj = data.findIndex(el => (el.account_type_id === account_type_id))
          const status = !data[indexObj].status;
          // console.log("status",Number(status));
          try {
               this.setState({ loading: true });
               let res = await POST(ACCOUNTTYPEUPDATESTATUSAPI, { status: Number(status), account_type_id });
               if (res.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", text: "อัพเดทสถานะบัญชีสำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.getAccountType();
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: "อัพเดทสถานะบัญชีไม่สำเร็จ", icon: "error",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "error",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     onClickEdit = (id) => {
          this.props.history.push(`/setting_manage_account_type/edit/${id}`);
     };


     onClickDelete = async (account_type_id) => {
          try {
               this.setState({ loading: true });
               let deleteAccount = await POST(DELETEACCOUNTTYPEAPI, { account_type_id });
               if (deleteAccount.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", text: "ลบประเภทบัญชีสำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
                    this.getAccountType();
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "สำเร็จ", text: deleteAccount.message, icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "สำเร็จ", text: error, icon: "success",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     updateDataList = (id, show) => {
          const { data } = this.state;
          let newData = data.map(item => {
               return item.account_type_id === id ? { ...item, expand: show === 0 ? 1 : 0 } : item
          })
          this.setState({ data: newData });
     }

     render() {
          const { data, loading, state } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title="Menu" pageName="account_type" data={StringSet.account_type.sidebar} />
                         </Grid>
                         <Grid item container spacing={2} direction="row" xs={9}>
                              <Grid item xs={12}>
                                   <HeaderTypography title={"Account Type"} />
                                   {data &&
                                        <ListAccountType data={data} onClickDelete={this.onClickDelete} onClickEdit={this.onClickEdit} onClickUpdateStatus={this.onClickUpdateStatus} state={state} updateDataList={this.updateDataList} />
                                   }
                              </Grid>
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(SettingAccountType);
