import React from "react";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { headerTable } from "functions/Static";
import { makeStyles } from "@material-ui/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const TableListAdmin = (props) => {
  const { onClickDelete, onClickOpen } = props;
  const count = Math.ceil(props.body.length > 0 && props.body.length / 10);
  const setPage = props.page - 1;
  const dataList = props.body.slice(setPage * 10, props.page * 10);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setId] = React.useState(null);
  const handleClick = (event, id) => {
    setId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid className="table-btn">
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerTable.admin_list.map((el, i) => {
                return (
                  <TableCell key={i} align={el.align || "left"}>
                    <Typography noWrap>{el.label}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((el, i) => {
              return (
                <TableRow
                  hover
                  key={i}
                  className={i % 2 !== 0 ? "bg-table" : ""}
                >
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.admin || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.name || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.lastname || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.phone || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.line_id || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                      {el.role || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      noWrap
                      sx={{ fontSize: 20, fontWeight: 600, color: "#184B8E" }}
                    >
                      <Button
                        onClick={(event) => handleClick(event, el.admin_id)}
                        className={classes.button}
                      >
                        <MoreHorizIcon />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            onClickOpen(id);
                            handleClose();
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            onClickDelete(id);
                            handleClose();
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent="center" mt={5}>
        {dataList.length === 0 && (
          <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
            {props.language === "th"
              ? "ไม่มีข้อมูลในตาราง"
              : "No data available in table"}
          </Typography>
        )}
        {dataList.length > 0 && (
          <Pagination
            count={count}
            page={props.page}
            onChange={props.handleChangePage}
            variant="outlined"
            color="secondary"
          />
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 320,
  },
  button: {
    "&:hover": {
      backgroundColor: "none",
    },
  },
});

export default TableListAdmin;
