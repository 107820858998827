import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { DELETEADMIN, EDITADMIN, GET, GETADMIN, POST } from "service";
import AdminCard from "./Component/AdminCard";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";
class Admin extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data_admin: [],
               open: false,
               loading: false,
               page: 1,
          };
     }

     componentDidMount = () => {
          this.getAdmin();
     };

     getAdmin = async () => {
          try {
               this.setState({ loading: true });
               let getAdmin = await GET(GETADMIN);
               if (getAdmin.success) {
                    this.setState({ data_admin: getAdmin.result });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: getAdmin.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickAdd = () => {
          this.props.history.push("/admin/add");
     };

     onClickDelete = async (id) => {
          try {
               this.setState({ loading: true });
               let delete_admin = await POST(DELETEADMIN, { admin_id: id });
               if (delete_admin.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "ลบผู้ดูแลสำเร็จ", icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: delete_admin.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickEdit = async () => {
          const { name, lastname, phone, line_id, admin_id } = this.state;
          try {
               this.setState({ loading: true });
               let edit_admin = await POST(EDITADMIN, { name, lastname, phone, line_id, admin_id });
               if (edit_admin.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "แก้ไขผู้ดูแลสำเร็จ", icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    window.location.reload();
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: edit_admin.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickOpen = (id) => {
          const { data_admin } = this.state;
          let find_data = data_admin.find((el) => el.admin_id === id);
          this.setState({ open: true, admin_id: id, name: find_data.name, lastname: find_data.lastname, phone: find_data.phone, line_id: find_data.line_id });
     };

     onClickClose = () => {
          this.setState({ open: false });
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };

     render() {
          const language = localStorage.getItem("language");
          const { data_admin, open, loading, page } = this.state;
          return (
               <Container maxWidth="xl">
                    <AdminCard
                         language={language}
                         data_admin={data_admin}
                         onClickAdd={this.onClickAdd}
                         onClickDelete={this.onClickDelete}
                         open={open}
                         onClickOpen={this.onClickOpen}
                         onClickClose={this.onClickClose}
                         onClickEdit={this.onClickEdit}
                         state={this.state}
                         handleChangeText={this.handleChangeText}
                         handleChangePage={this.handleChangePage}
                         page={page}
                    />
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(Admin);
