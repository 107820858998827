import { Card, CardContent, CardMedia, Grid, Typography, Button } from "@material-ui/core";
import { FiberManualRecord as Dot } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { ip } from "service";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const ListPomotion = (props) => {
     const classes = useStyles();
     return props.data.map((el, i) => {
          return (
               <Card sx={{ maxWidth: "100%", marginBottom: 2, padding: 1 }}>
                    <Grid container direction="row">
                         <Grid item xs={2} sx={{ justifyContent: "center", alignSelf: "center", border: "1px solid #E2E2E2", borderRadius: 5, padding: 2, }}>
                              <CardMedia component="img" height="100%" width="auto" image={ip + el.promotion_img} alt={el.promotion_name} />
                         </Grid>
                         <Grid item container xs={10}>
                              <Grid item xs={12}>
                                   <CardContent>
                                        <Typography variant="body1" color="text.primary">
                                             {el.promotion_name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                             {el.promotion_detail}
                                        </Typography>
                                        {el.promotion_array !== undefined ? JSON.parse(el.promotion_array).map((ele, ind) => (
                                             <Grid key={ind} container alignItems="center" px={2} py={1}>
                                                  <Dot fontSize="small" color="error" />
                                                  <Typography className={classes.fontStyleBodyDetail}>{ele}</Typography>
                                             </Grid>
                                        )): null}
                                   </CardContent>
                              </Grid>
                              <Grid container item direction="row" justifyContent="flex-end" xs={12}>
                                   <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={() => props.onClickEdit(el.promotion_id)}
                                   >
                                        Edit
                                   </Button>
                                   <Button
                                        variant="outlined"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                        onClick={() => props.onClickDelete(el.promotion_id)}
                                   >
                                        Delete
                                   </Button>
                              </Grid>
                         </Grid>
                    </Grid>
               </Card>
          );
     });
};
const useStyles = makeStyles(() => ({
     fontStyleBodyDetail: {
          fontSize: 12,
          paddingLeft: 8,
          [theme.breakpoints.up("sm")]: {
               fontSize: 14,
          },
     },
}));

export default withRouter(ListPomotion);
