import { Container, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import Sidebar from "./Components/Sidebar";
import ListPomotion from "./Components/ListPomotion";
import { POST, PROMOTIONAPI, DELETEPORMOTIONAPI } from "service";
import { StringSet } from "functions/Static";
import Loading from "components/Loading/Loading";
import swal from "sweetalert2";

class SettingPromotion extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data: [],
               loading: false,
          };
     }

     componentDidMount() {
          this.getPromotion();
     }

     async getPromotion() {
          try {
               this.setState({ loading: true });
               let promotion = await POST(PROMOTIONAPI, {});
               if (promotion.success) {
                    this.setState({ data: promotion.result, loading: false });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
          }
     }

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     onClickEdit = (id) => {
          this.props.history.push(`/setting_manage_promotion/edit/${id}`);
     };

     onClickDelete = async (promotion_id) => {
          try {
               this.setState({ loading: true });
               let deletePromotion = await POST(DELETEPORMOTIONAPI, { promotion_id });
               if (deletePromotion.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "ลบ Promotion สำเร็จ", icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    this.getPromotion();
               } else {
                    swal.fire({ title: "warning", text: deletePromotion.message, icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "warning", text: error, icon: "success", 
               // timer: 6000, 
               showConfirmButton: true });
               console.log(`error`, error);
          }
     };

     render() {
          const { data, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <Grid container spacing={2}>
                         <Grid item xs={3}>
                              <Sidebar title="Menu" pageName="promotion" data={StringSet.promotion.sidebar} />
                         </Grid>
                         <Grid item container spacing={2} direction="row" xs={9}>
                              <Grid item xs={12}>
                                   <HeaderTypography title="Promotion" />
                                   <ListPomotion data={data} onClickDelete={this.onClickDelete} onClickEdit={this.onClickEdit} />
                              </Grid>
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(SettingPromotion);
