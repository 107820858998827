import { Grid, Button, CardMedia, Typography, TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { GridTextField } from "../../../components";
import HeaderTypography from "../../../components/HeaderTypography/HeaderTypography";
import Sidebar from "./Sidebar";
import TextfielAdd from "./TextfielAdd";
import { Image } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const useStyles = makeStyles(() => ({
     root: {
          "& > *": {
               margin: theme.spacing(1),
          },
     },
     input: {
          display: "none",
     },
}));

const TextfieldPromotion = (props) => {
     const classes = useStyles();
     const {
          textFiled,
          textFiled_image,
          textFiled_back,
          state,
          handleChangeText,
          titleGrid,
          image,
          onClickAdd,
          onClickDelete,
          onChangeFile,
          onChangeFileEN,
          onClickAddPromotion,
          handleChangeTextArray,
          path,
          StringSet,
          onClickEditPromotion,
          image_en,
     } = props;
     return (
          <Grid container spacing={2}>
               <Grid item xs={3}>
                    <Sidebar title="Menu" pageName="promotion" data={StringSet.sidebar} onChangePath={props.onChangePath} />
               </Grid>
               <Grid item container spacing={2} direction="row" xs={9}>
                    <Grid item xs={12}>
                         <HeaderTypography title={path === StringSet.link ? "Add Promotion" : "Edit Promotion"} />
                    </Grid>
                    <GridTextField data={textFiled} state={state} handleChange={handleChangeText} image={image} onChangeFile={onChangeFile} />
                    <Grid container item xs={12} textAlign="center" direction="row" alignItems="center" spacing={2}>
                         <Grid item xs={3}>
                              {image_en ? (
                                   <CardMedia component={"img"} height={"auto"} width="100%" image={image_en} alt={"promotion_image_en"} />
                              ) : (
                                   <Image style={{ fontSize: 100 }} />
                              )}
                         </Grid>
                         <Grid item xs={7} textAlign="left">
                              <Grid item>
                                   <Typography xs={12}>Main Picture</Typography>
                              </Grid>
                              <TextField
                                   // disabled={e.disable ? e.disable : props.disabled}
                                   placeholder={"File"}
                                   className={"textfield-css mt-4"}
                                   name={"promotion_image_en"}
                                   value={state.promotion_image_en}
                                   onChange={handleChangeText}
                                   fullWidth
                                   variant="outlined"
                                   size="small"
                                   autoComplete="off"
                              />
                         </Grid>
                         <Grid item xs={2} marginLeft={-10} marginBottom={-3}>
                              <div className={classes.root}>
                                   <input accept="image/*" className={classes.input} id="contained-button-file2" multiple type="file" onChange={onChangeFileEN} />
                                   <label htmlFor="contained-button-file2">
                                        <Button
                                             variant="contained"
                                             color="error"
                                             sx={{ maxWidth: "100%", fontSize: 12, width: "100%", borderRadius: 2, height: 40 }}
                                             component="span"
                                        >
                                             Choose File
                                        </Button>
                                   </label>
                              </div>
                         </Grid>
                    </Grid>
                    <GridTextField data={textFiled_back} state={state} handleChange={handleChangeText} />
                    <Grid item xs={12}>
                         <Button
                              variant="contained"
                              color="error"
                              className="btn-style"
                              sx={{ maxWidth: 200, fontSize: 10, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                              onClick={onClickAdd}
                         >
                              Add+
                         </Button>
                    </Grid>
                    <TextfielAdd data={titleGrid} state={state} handleChange={handleChangeTextArray} onClickDelete={onClickDelete} />
                    <Grid item xs={12} container justifyContent="center">
                         <Button
                              variant="contained"
                              color="error"
                              className="btn-style"
                              sx={{ maxWidth: 200, fontSize: 12, width: "100%", borderRadius: 40, height: 50, margin: 1 }}
                              onClick={path === StringSet.link ? onClickAddPromotion : onClickEditPromotion}
                         >
                              {path === StringSet.link ? "Add" : "Edit"}
                         </Button>
                    </Grid>
               </Grid>
          </Grid>
     );
};
export default withRouter(TextfieldPromotion);
