import { Grid, InputAdornment, Link, Table, TableContainer, TextField, Typography } from "@material-ui/core";
import { makeStyles, propsToClassKey } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const GridTableIB = (props) => {
     const { symbol } = props;
     const classes = useStyles();
     return (
          <TableContainer>
               <Table className={classes.table} aria-label="caption table">
                    <Grid item container direction={"row"} spacing={2} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px" xs={12} justifyContent={"center"}>
                         <Grid item xs={2}>
                              <Typography className={classes.fontStyleTitle}>No.</Typography>
                         </Grid>
                         {symbol.map((el, i) => (
                              <Grid key={i} item xs={2}>
                                   <Typography className={classes.fontStyleTitle}>
                                        {el.name}
                                   </Typography>
                              </Grid>
                         ))}
                    </Grid>
                    {props.dataSub.map((el, i) => {
                         return (
                              <Grid key={i} item container direction={"row"} spacing={1} borderBottom={"1px solid #EBEBEB"} marginBottom="20px" paddingBottom="20px">
                                   <Grid item xs={2}>
                                        <Typography className={classes.fontStyleDetail} sx={{ fontWeight: 600 }}>
                                             {i + 1}
                                        </Typography>
                                   </Grid>
                                   {symbol.map((ele, ind) => (
                                        <Grid key={ind} item xs={2} container alignContent={"flex-end"}>
                                             <TextField
                                                  className="textfield-profile"
                                                  fullWidth
                                                  size="small"
                                                  type="number"
                                                  placeholder={ele.name}
                                                  name={ele.name + i}
                                                  value={props.state[ele.name + i]}
                                                  disabled={props.edit_disabled}
                                                  inputProps={{
                                                       maxLength: 13,
                                                       step: "1",
                                                  }}
                                                  onChange={(e) => props.handleChangeTextSub(e, i, ele.name)}
                                             />
                                        </Grid>
                                   ))}
                              </Grid>
                         );
                    })}
               </Table>
          </TableContainer>
     );
};
const useStyles = makeStyles(() => ({
     table: {
          minWidth: 400,
     },
     fontStyleTitle: {
          fontSize: 18,
          fontWeight: 600,
          color: "#000",
     },
     fontStyleDetail: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
          marginTop: 20,
     },
     fontStyleDetailnoMargin: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
     },
     fontEdit: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
          marginTop: 20,
          color: "#184B8E",
     },
}));
export default GridTableIB;
