import { Container } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { AtomicBlockUtils, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Component } from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import EditEmail from "./ComponentsEmail/EditEmail";
import { GET, GETEMAIL, GETTYPEEMAIL, POST, SENDMAIL, UPDATEEMAIL } from "service";

class SettingUpdateEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            select_type: '',
            data_select: [],
            editorState_header: EditorState.createEmpty(),
            editorState_footer: EditorState.createEmpty(),
            data_img: { file: null, width: null, height: null },
            select_img: null,
            enabled_img: false,
            editorState_img: null,
        };
    }

    componentDidMount = () => {
        this.getMailById();
        this.getTypeEmail();
        this.sendMailById();
    };

    sendMailById = async () => {
        try {
            this.setState({ loading: true });
            let res = await POST(SENDMAIL, { email_id: 1 });
            if (res.success) {
                this.setState({ loading: false });
            } else {
                Swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            Swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    getMailById = async () => {
        try {
            this.setState({ loading: true });
            let res = await POST(GETEMAIL, { type_id: this.props.match.params.id });
            if (res.success) {
                const { header, footer, id } = res.result;
                if (header && footer) {
                    const rawContentFromStoreHead = convertFromRaw(JSON.parse(header));
                    const editorState_header = EditorState.createWithContent(rawContentFromStoreHead);

                    const rawContentFromStorFooter = convertFromRaw(JSON.parse(footer));
                    const editorState_footer = EditorState.createWithContent(rawContentFromStorFooter);

                    this.setState({ editorState_header, editorState_footer });
                }
                this.setState({ select_type: id });
                this.setState({ loading: false });
            } else {
                Swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            Swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    getTypeEmail = async () => {
        try {
            this.setState({ loading: true });
            let res = await GET(GETTYPEEMAIL);
            if (res.success) {
                this.setState({ data_select: res.result });
                this.setState({ loading: false });
            } else {
                Swal.fire({
                    title: "ไม่สำเร็จ", text: 'ดึงข้อมูลไม่สำเร็จ', icon: "warning",
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            Swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    handleChangeSelect = (e) => {
        this.setState({ select_type: e.target.value });
    }

    handleChangeEditorHeader = (editorState) => {
        this.setState({ editorState_header: editorState })
    };

    handleChangeEditorFooter = (editorState) => {
        this.setState({ editorState_footer: editorState })
    };

    onImageChange = (event, name) => {
        const { data_img } = this.state;
        let file = event.target.files[0];
        if (event.target.files && file) {
            if (file.size < 5e+7) {
                this.convertToBase64(file, name)
                this.setState({ data_img: { ...data_img, file }, select_img: URL.createObjectURL(file) })
            } else {
                Swal.fire({
                    icon: "warning",
                    text: `อัฟโหลดไฟล์ได้ขนาดไม่เกิน 50 MB`,
                    showConfirmButton: true,
                });
            }
        }
    };

    convertToBase64 = (file, name) => {
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            // console.log('called: ', reader.result)
            this.addImage(reader.result, name);
        }
    }

    onLoadImageChange = (e) => {
        const { data_img } = this.state;
        const { naturalWidth, naturalHeight } = e.currentTarget;

        this.setState({
            data_img: { ...data_img, width: naturalWidth, height: naturalHeight }
        });
    };

    addImage = (link, name) => {
        const { data_img } = this.state;

        // CREATE <img /> block
        const entityKey = this.state[name] // from STATE
            .getCurrentContent()
            .createEntity('IMAGE', 'MUTABLE', {
                src: link,
                height: data_img.height * 0.5,
                width: data_img.width * 0.5,
            }).getLastCreatedEntityKey();

        // NEW EDITOR STATE
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            this.state[name],
            entityKey,
            ' '
        );

        // SETSTATE
        this.setState({ [name]: newEditorState })
    }

    handleUpdateEmail = async () => {
        const { editorState_header, editorState_footer, select_type } = this.state;

        if (editorState_header && editorState_footer && select_type) {
            // console.log(select_type, JSON.stringify(convertToRaw(editorState_header.getCurrentContent())), JSON.stringify(convertToRaw(editorState_footer.getCurrentContent())));
            try {
                let res = await POST(UPDATEEMAIL, {
                    type_id: select_type,
                    header: JSON.stringify(convertToRaw(editorState_header.getCurrentContent())),
                    footer: JSON.stringify(convertToRaw(editorState_footer.getCurrentContent()))
                });
                if (res.success) {
                    Swal.fire({
                        title: "สำเร็จ", text: `${res.result.message}`, icon: "success",
                        showConfirmButton: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.assign('/setting_email')
                        }
                    })
                } else {
                    Swal.fire({
                        title: "ไม่สำเร็จ", text: "อัพเดทข้อมูลไม่สำเร็จ", icon: "warning",
                        showConfirmButton: true
                    })
                }
            } catch (error) {
                Swal.fire({
                    title: "Warning", text: error, icon: "warning",
                    showConfirmButton: true
                });
            }
        } else {
            Swal.fire({
                title: "ไม่สำเร็จ", text: "กรุณากรอกข้อมูลให้ครบถ้วน", icon: "warning",
                showConfirmButton: true
            })
        }
    }

    render() {
        const language = localStorage.getItem("language");
        const { loading, data_select, select_type, editorState_header, editorState_footer, select_img } = this.state;
        return (
            <Container maxWidth="xl">
                <Loading open={loading} />
                <EditEmail
                    data_select={data_select}
                    select_type={select_type}
                    editorState_header={editorState_header}
                    editorState_footer={editorState_footer}
                    select_img={select_img}
                    handleChangeSelect={this.handleChangeSelect}
                    handleChangeEditorHeader={this.handleChangeEditorHeader}
                    handleChangeEditorFooter={this.handleChangeEditorFooter}
                    onImageChange={this.onImageChange}
                    onLoadImageChange={this.onLoadImageChange}
                    handleUpdateEmail={this.handleUpdateEmail}
                />
            </Container>
        );
    }
}

export default withRouter(SettingUpdateEmail);
