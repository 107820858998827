import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NETWORK, POST } from "service";
import NetworkuserCard from "./ManageUser/NetworkCard";
import swal from "sweetalert2";

class Networkuser extends Component {
     constructor(props) {
          super(props);

          this.state = {
               data_network: [],
               language: localStorage.getItem("language"),
          };
     }

     componentDidMount = () => {
          this.getNetwork();
     };

     getNetwork = async () => {
          try {
               this.setState({ loading: true });
               let network = await POST(NETWORK, { user_id: this.props.match.params.id });
               if (network.success) {
                    this.setState({ data_network: network.result });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "ไม่สำเร็จ", text: network.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "ไม่สำเร็จ", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     render() {
          const { data_network, language } = this.state;
          return (
               <Container maxWidth="xl">
                    <NetworkuserCard data_network={data_network} language={language} />
               </Container>
          );
     }
}

export default withRouter(Networkuser);
