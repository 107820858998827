import { Container, Button } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TableIB from "../IBList/TableIB/TableIB";
import { POST, EDITREBATESUB, GETIBREBATE, PROFILEBYUSER } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";
import TableRebate from "../IBList/TableIB/TableRebate";
import TableEditRebate from "./TableIB/TableEditRebate";

class IBEditTypeAccoutRebate extends Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: false,

               dataIBRebate: [],
               profile: [],
               ib_status: null,
          };
     }

     componentDidMount = () => {
          this.getProfileById();
     };

     getIBRebate = async () => {
          const { IBRebate, ib_status } = this.state;

          try {
               let IBRebate = await POST(GETIBREBATE, { user_id: this.props.match.params.user_id, sub_user_rebate: this.props.match.params.user_subid });
               if (IBRebate.success) {

                    let sub_ib = IBRebate.result;
                    const updatedSubIb = sub_ib.map(ib => {
                         const isAllNull = ib.rebate.every(value =>
                              value.crypto === null &&
                              value.forex === null &&
                              value.gold === null &&
                              value.index === null &&
                              value.oil === null
                         );

                         if (isAllNull && ib_status === 1 && (ib.account_type_id === 2 || ib.account_type_id === 7)) {
                              //QRS Freedom 2
                              //QRS Freedom MT5 7
                              ib.rebate = [
                                   { forex: 2, gold: 2, crypto: 2, index: 2, oil: 2 }
                              ];
                         }
                         if (isAllNull && ib_status === 1 && (ib.account_type_id === 3 || ib.account_type_id === 6 || ib.account_type_id === 8 || ib.account_type_id === 10)) {
                              //QRS Standard Swap Free 3
                              //QRS CENT 6
                              //QRS Standard Swap MT5 Free 8
                              //QRS CENT MT5 10
                              ib.rebate = [
                                   { forex: 4, gold: 4, crypto: 4, index: 4, oil: 4 }
                              ];
                         }
                         if (isAllNull && ib_status === 1 && (ib.account_type_id !== 2 && ib.account_type_id !== 7 && ib.account_type_id !== 3 && ib.account_type_id !== 6 && ib.account_type_id !== 8 && ib.account_type_id !== 10)) {
                              ib.rebate = [
                                   { forex: 0, gold: 0, crypto: 0, index: 0, oil: 0 }
                              ];
                         }
                         return ib;
                    });

                    this.setState({ dataIBRebate: updatedSubIb });
               }
          } catch (error) {
               // this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               console.log("error", error);
          }
     };

     getProfileById = async () => {
          try {
               let ProfileById = await POST(PROFILEBYUSER, { user_id: this.props.match.params.user_subid });
               if (ProfileById.success) {
                    this.setState({ profile: ProfileById.result, ib_status: ProfileById.result.ib_status });
                    this.setState({ ib_status: ProfileById.result.ib_status }, () => {
                         // console.log(this.state.ib_status, 'ib_status');
                         this.getIBRebate();
                    });
               }
          } catch (error) {
               // this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
               console.log("error", error);
          }
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     handleChangeTextRebate = (e, i, index, id, account_type_id) => {
          console.log("element", e.target.value, "i", i, "index", index, "account_type_id", account_type_id);
          console.log("id", id);
          let { dataIBRebate, dataTabel } = this.state;
          let name = e.target.name;
          let value = Number(e.target.value);

          dataIBRebate.find((el) => el.sub_1_user_id === id && el.account_type_id === account_type_id).rebate[i][name] = value;
          this.setState({ dataIBRebate });
     };

     onClickAddRebate = (id, account_type_id) => {
          let { dataIBRebate } = this.state;
          dataIBRebate.find((el) => el.sub_1_user_id === id && el.account_type_id === account_type_id).rebate.push({ forex: 0, gold: 0, crypto: 0, index: 0, oil: 0 });
          this.setState({ ...dataIBRebate, dataIBRebate: dataIBRebate });
     };

     onClickSaveRebate = async (data) => {
          console.log("data", data);
          console.log("dataIBRebate", this.state.dataIBRebate);
          const { dataIBRebate } = this.state;
          // console.log("dataaa", data.rebate.sub_1_user_id);
          try {
               let index = dataIBRebate.findIndex((x) => x.account_type_id == data);
               let rebate = JSON.stringify(dataIBRebate[index].rebate);
               console.log("rebate", rebate);
               let save_rebate = await POST(EDITREBATESUB, {
                    account_type_id: data,
                    user_id: this.props.match.params.user_id,
                    sub_1_user_id: this.props.match.params.user_subid,
                    rebate,
               });
               if (save_rebate.success) {
                    swal.fire({
                         title: "สำเร็จ", text: "แก้ไข Rebate สำเร็จ", icon: "success",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               } else {
                    swal.fire({
                         title: "ไม่สำเร็จ", text: save_rebate.message, icon: "warning",
                         // timer: 6000,
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     render() {
          const { loading, dataIBRebate } = this.state;
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <Loading open={loading} />
                    <TableEditRebate
                         state={this.state}
                         dataIBRebate={dataIBRebate}
                         handleChangeTextRebate={this.handleChangeTextRebate}
                         onClickAddRebate={this.onClickAddRebate}
                         onClickSaveRebate={this.onClickSaveRebate}
                         id={this.props.match.params.user_id}
                    />
               </Container>
          );
     }
}

export default withRouter(IBEditTypeAccoutRebate);
