import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { headerTable } from "../../../functions/Static";

const TableEmail = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Grid className="table-btn">
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {headerTable.settingEmail.map((el, i) => {
                                    return (
                                        <TableCell key={i} align={el.align || "left"}>
                                            <Typography noWrap>{el.label}</Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.map((el, i) => {
                                return (
                                    <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                        <TableCell>
                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                {el.type}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Link
                                                component="button"
                                                sx={{ fontSize: 16, color: "#184B8E" }}
                                                onClick={() => {
                                                    props.onClickEdit(el.id);
                                                }}
                                            >
                                                Edit
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    table: {
        minWidth: 320,
    },
});

export default withRouter(TableEmail);
