import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { POST, EDITPROMOTION, UPLOADIMAGEPORMOTIONAPI, PROMOTIONBYIDAPI, ip_image } from "service";
import GridEdit from "./components/GridEdit";
export class EditPromotion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotion_data: [],
            page: 1,
            search_text: "",
            loading: false,
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
            promotion_name: "",
            editorState: EditorState.createEmpty(),
            file: "",
            token: localStorage.getItem("token"),
        };
    }

    componentDidMount = () => {
        this.getPromotionById();
    };

    getPromotionById = async () => {

        const { promotion_data } = this.state;

        try {
            this.setState({ loading: true });
            let res = await POST(PROMOTIONBYIDAPI, { promotion_id: this.props.match.params.id });
            if (res.success) {
                const rawContentFromStore = convertFromRaw(JSON.parse(res.result.promotion_detail));
                const editorState = EditorState.createWithContent(rawContentFromStore);
                promotion_data.profile_image = `${ip_image}${res.result.promotion_img}`;
                this.setState({
                    date_start: new Date(res.result.start),
                    date_end: new Date(res.result.expire),
                    promotion_name: res.result.promotion_name,
                    editorState,
                    promotion_data: promotion_data
                });
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    uploadImage = async (e) => {
        const { promotion_data } = this.state;
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                promotion_data.profile_image = reader.result;
                this.setState({ promotion_data: promotion_data });
            }
        };
        reader.readAsDataURL(e.target.files[0]);
        this.setState({ file: e.target.files[0] });
    };

    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };

    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    handleChangeText = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChangeEditor = (editorState) => {
        this.setState({ editorState })
    };

    onClickEditImg = async (promotion_id) => {
        const { file } = this.state;

        try {
            let form_data = new FormData();
            form_data.append("promotion_id", promotion_id)
            form_data.append("promotion_image", file)
            let add = await POST(UPLOADIMAGEPORMOTIONAPI, form_data, true);
            if (add.success) {
                swal.fire({
                    title: "Success",
                    text: "แก้ไขข้อมูลสำเร็จ",
                    icon: "success",
                    // timer: 6000,
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        // this.props.history.push(`/promotion_list`);
                        window.location.replace("/promotion_list");
                    }
                })
                // this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "Warning", text: add.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                // this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            // this.setState({ loading: false });
        }
    };

    onClickEditPromotion = async () => {
        const { promotion_name, editorState, date_start, date_end, file } = this.state;
        try {
            let addPromotion = await POST(EDITPROMOTION, { promotion_id: this.props.match.params.id, promotion_name, promotion_detail: JSON.stringify(convertToRaw(editorState.getCurrentContent())), start: date_start, expire: date_end });
            if (addPromotion.success) {
                if (file) {
                    this.onClickEditImg(this.props.match.params.id);
                } else {
                    swal.fire({
                        title: "Success",
                        text: "แก้ไขข้อมูลสำเร็จ",
                        icon: "success",
                        // timer: 6000,
                        showConfirmButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // this.props.history.push(`/promotion_list`);
                            window.location.replace("/promotion_list");
                        }
                    })
                }
            } else {
                swal.fire({
                    title: "Warning", text: addPromotion.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                // this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "Warning", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            // this.setState({ loading: false });
        }
    };

    render() {
        const language = localStorage.getItem("language");
        const { page, promotion_data, loading } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <GridEdit
                        language={language}
                        data={promotion_data}
                        page={page}
                        state={this.state}
                        uploadImage={this.uploadImage}
                        handleDateStartChange={this.handleDateStartChange}
                        handleDateEndChange={this.handleDateEndChange}
                        handleChangeText={this.handleChangeText}
                        handleChangeEditor={this.handleChangeEditor}
                        onClickEditPromotion={this.onClickEditPromotion}
                    />
                </Grid>
            </Container>
        );
    }
}
export default withRouter(EditPromotion);
