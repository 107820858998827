import { Grid, Tabs, Tab } from "@material-ui/core";
import React from "react";

const DetailTabs = (props) => {
     const [value, setValue] = React.useState(1);
     const handleChange = (event, newValue) => {
          setValue(newValue);
          props.setTap(newValue);
     };
     return (
          <Grid item container justifyContent="center" alignItems="center" marginTop={2}>
               <Grid item xs={12} className="taps-css">
                    <Tabs value={value} onChange={handleChange} centered variant="fullWidth">
                         {/* <Tab value={1} label="IB" /> */}
                         {/* <Tab value={1} label="Rebate" /> */}
                         <Tab value={2} label="Network" />
                         {/* <Tab value={3} label="Account " /> */}
                    </Tabs>
               </Grid>
          </Grid>
     );
};

export default DetailTabs;
