import { Grid, Link, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { headerTable } from "../../../functions/Static";
import { tofieds } from "functions/Functions";

const TableNetwork = (props) => {
     const classes = useStyles();

     const dataNetwork = props.data.data;
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   <TableCell>
                                        <Typography noWrap>Name</Typography>
                                   </TableCell>
                                   <TableCell>
                                        <Typography noWrap>Last Name</Typography>
                                   </TableCell>
                                   <TableCell>
                                        <Typography noWrap>Email</Typography>
                                   </TableCell>
                                   <TableCell>
                                        <Typography noWrap>Wallet</Typography>
                                   </TableCell>
                                   <TableCell>
                                        <Typography noWrap>MT5 Balance</Typography>
                                   </TableCell>
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {dataNetwork
                                   ? dataNetwork.map((el, i) => {
                                          return (
                                               <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                                    <TableCell>
                                                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                              {el.name}
                                                         </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                              {el.lastname}
                                                         </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                              {el.email}
                                                         </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                              {tofieds(el.wallet)}
                                                         </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                              {tofieds(el.balance)}
                                                         </Typography>
                                                    </TableCell>
                                                    {/* <TableCell>
                                                  <Link
                                                       component="button"
                                                       sx={{ fontSize: 16, color: "#184B8E" }}
                                                       onClick={() => {
                                                            props.onClickOpenModal(el.payment_gateway_id, el.payment_name, el.deposit, el.withdraw);
                                                       }}
                                                  >
                                                       Edit
                                                  </Link>
                                             </TableCell> */}
                                               </TableRow>
                                          );
                                     })
                                   : null}
                         </TableBody>
                    </Table>
               </TableContainer>
               <Grid container justifyContent="center" mt={5}>
                    {dataNetwork?dataNetwork.length === 0 && (
                         <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                              {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                         </Typography>
                    ):null}
                    {/* {dataNetwork.length > 0 && <Pagination count={count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />} */}
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
});

export default withRouter(TableNetwork);
