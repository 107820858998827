import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { POST, GETPROBLEMBYID, CHANGESTATUS } from "service";
import CardDetailSupport from "./component/CardDetailSupport";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

class SupportDetail extends Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getProblem();
     };

     getProblem = async () => {
          try {
               this.setState({ loading: true });
               let get_problem = await POST(GETPROBLEMBYID, { ticket_id: this.props.match.params.id });
               if (get_problem.success) {
                    this.setState({ data_poblem: get_problem.result[0] });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: get_problem.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "Warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickChangeStatus = async (type) => {
          let status;
          if (type === "Received") {
               status = 2;
          } else if (type === "Success") {
               status = 1;
          }
          try {
               this.setState({ loading: true });
               let change_status = await POST(CHANGESTATUS, { ticket_id: this.props.match.params.id, status: status });
               if (change_status.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "เปลี่ยนแปลงสถาณะสำเร็จ", icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    this.props.history.push("/support");
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: change_status.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "Warning", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     render() {
          const language = localStorage.getItem("language");
          const { data_poblem, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <CardDetailSupport language={language} onClickChangeStatus={this.onClickChangeStatus} data_poblem={data_poblem} />
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(SupportDetail);
