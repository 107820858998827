import React from "react";
import { Backdrop, Fade, Typography, Modal, Grid, Button, CardMedia, TextareaAutosize, TextField, IconButton, Stack, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import slip from "../../../assets/images/slip.png";
import { ip_image } from "service";
import moment from "moment";
import { createMuiTheme } from "@material-ui/core/styles";
import { GridTextField } from "components";
import { textFiled } from "functions/Static";
import CloseIcon from "@material-ui/icons/Close";
import copy from "copy-to-clipboard";

const theme = createMuiTheme();

const ModalApproveWithdraw = (props) => {
     const classes = useStyles();
     const { open, handleClose, language, data, approveRejectWithdraw, onChange, note_withdraw, stateFinance, state, select_withdraw } = props;
     const menuItem_type_1 = [
          {
               value: "null",
               label: "Select Withdarwal",
               disabled: true,
          },
          {
               value: 1,
               label: "ผ่าน Gateway",
               payment_type: 4,
               withdraw_type: 1,
          },
          {
               value: 2,
               label: "โอนเอง",
          }
     ];
     const menuItem_type_3 = [
          {
               value: "null",
               label: "Select Withdarwal",
               disabled: true,
          },
          {
               value: 1,
               label: "ผ่าน Gateway",
               payment_type: 4,
               withdraw_type: 1,
          },
          {
               value: 2,
               label: "โอนเอง",
          },
          {
               value: 3,
               // label: "Bitcoins",
               label: "USDT",
               payment_type: 5,
               withdraw_type: 3,
          },
     ];
     return (
          <Modal
               aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={open}
               onClose={handleClose}
               closeAfterTransition
               BackdropComponent={Backdrop}
               BackdropProps={{
                    timeout: 500,
               }}
               style={{ overflow: "scroll" }}
          >
               <Fade in={open}>
                    <Grid className={classes.boxStyle}>
                         <Grid container rowSpacing={3} padding={3} justifyContent="center" alignItems="center">
                              <Grid container justifyContent="flex-end" xs={12}>
                                   <Button onClick={() => handleClose()} className={classes.buttonClose}>
                                        <CloseIcon fontSize="small" style={{ color: "#3b3b3b" }} />
                                   </Button>
                              </Grid>
                              <Grid item xs={12} textAlign="center">
                                   <Typography sx={{ fontSize: 24, fontWeight: 600 }}>View slip</Typography>
                              </Grid>
                              {data && (
                                   <Grid item container columnSpacing={1} xs={12}>
                                        <Grid item xs={12} sm={6}>
                                             <Grid marginBottom={4} xs={12}>
                                                  <Typography className={classes.textHeder}>Transaction Number</Typography>
                                                  <Typography className={classes.textContent}>{data.transaction_id || "-"}</Typography>
                                             </Grid>
                                             <Grid marginBottom={4} xs={12}>
                                                  <Typography className={classes.textHeder}>User</Typography>
                                                  <Typography className={classes.textContent}>
                                                       {data.name} {data.lastname}
                                                  </Typography>
                                             </Grid>
                                             <Grid marginBottom={4} xs={12}>
                                                  <Typography className={classes.textHeder}>Create</Typography>
                                                  <Typography className={classes.textContent}>
                                                       {data.created_at ? moment(data.created_at).format("DD/MM/YYYY, HH:mm:ss") : "-"}
                                                  </Typography>
                                             </Grid>
                                             <Grid marginBottom={4} xs={12}>
                                                  <Typography className={classes.textHeder}>Withdrawal (Wallet)</Typography>
                                                  <Typography className={classes.textContent}>{Math.round(Number(data.money) * 100) / 100 || "-"} USD</Typography>
                                             </Grid>
                                             <Grid marginBottom={4} xs={12}>
                                                  <Typography className={classes.textHeder}>Receive</Typography>
                                                  <Typography className={classes.textContent}>{Math.round(Number(data.receive) * 100) / 100 || "-"} {data.withdraw_type === 1 ? 'THB' : data.withdraw_type === 3 ? 'USD' : 'THB'}</Typography>
                                             </Grid>
                                             <Grid marginBottom={4} xs={12}>
                                                  <Typography className={classes.textHeder}>Select Withdrawal</Typography>
                                                  {data.status === 1 ?
                                                       <Typography className={classes.textContent}>{data.withdraw_type === 1 ? "ผ่าน Gateway" : data.withdraw_type === 2 ? "โอนเอง" : "USDT"}</Typography>
                                                       :
                                                       data.status === 2 ?
                                                            <GridTextField data={select_withdraw} state={state} language={language} handleChange={onChange} menuItem={data.withdraw_type === 3 ? menuItem_type_3 : menuItem_type_1} />
                                                            :
                                                            <Typography className={classes.textContent}>Reject</Typography>
                                                  }
                                             </Grid>
                                             <Grid item xs={12}>
                                                  <Typography sx={{ fontSize: 14, color: "#000" }}>Remark</Typography>
                                                  <TextareaAutosize
                                                       aria-label="minimum height"
                                                       minRows={2}
                                                       placeholder="Remark"
                                                       onChange={onChange}
                                                       name="note_withdraw"
                                                       value={data.remark || note_withdraw}
                                                       disabled={data.status !== 2}
                                                  />
                                             </Grid>
                                        </Grid>
                                        <Grid item container xs={12} sm={6}>
                                             <Stack spacing={3}>
                                                  <Grid >
                                                       <Typography className={classes.textHeder}>Bank Name</Typography>
                                                       <Typography className={classes.textContent}>{data.bank_name || "-"}</Typography>
                                                  </Grid>
                                                  <Grid >
                                                       <Typography className={classes.textHeder}>Account Number</Typography>
                                                       <Typography className={classes.textContent}>{data.bank_account || "-"}</Typography>
                                                  </Grid>
                                                  <Grid >
                                                       <Typography className={classes.textHeder}>Account Name</Typography>
                                                       <Typography className={classes.textContent}>{data.account_name || "-"}</Typography>
                                                  </Grid>
                                                  {data.withdraw_type === 3 &&
                                                       <>
                                                            <Grid >
                                                                 <Typography className={classes.textHeder}>Crypto Type</Typography>
                                                                 <Typography className={classes.textContent}>{data.crypto_type || "-"}</Typography>
                                                            </Grid>
                                                            <Grid >
                                                                 <Typography className={classes.textHeder}>Address USDT</Typography>
                                                                 {/* <TextField
                                                                      multiline
                                                                      fullWidth
                                                                      size="small"
                                                                      type="text"
                                                                      placeholder="Code"
                                                                      name="code"
                                                                      value={data.address_usdt}
                                                                      disabled
                                                                      InputProps={{
                                                                           endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                     <IconButton onClick={() => copy(data.address_usdt)}>
                                                                                          <img src="/image/copy.svg" width={25} />
                                                                                     </IconButton >
                                                                                </InputAdornment>
                                                                           ),
                                                                      }}
                                                                 /> */}
                                                                 <Typography className={classes.textContent} sx={{ overflowWrap: "anywhere" }}>
                                                                      <Stack direction="row" spacing={2} width="100%" display="flex" alignItems="center">
                                                                           <Grid>
                                                                                {data.address_usdt || "-"}
                                                                           </Grid>
                                                                           {data.address_usdt &&
                                                                                <IconButton onClick={() => copy(data.address_usdt)}>
                                                                                     <img src="/image/copy.svg" width={25} />
                                                                                </IconButton >
                                                                           }
                                                                      </Stack>
                                                                 </Typography>
                                                            </Grid>
                                                       </>
                                                  }
                                             </Stack>
                                             {/* <Grid container rowSpacing={1} justifyContent="center" sx={{ p: 3, background: "#F8F8F8", width: "100%" }}>
                                                  <Grid item xs={12}>
                                                       <Typography className={classes.textHeder}>Bank Name</Typography>
                                                       <Typography className={classes.textContent}>{data.bank_name || "-"}</Typography>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                       <Typography className={classes.textHeder}>Account Number</Typography>
                                                       <Typography className={classes.textContent}>{data.bank_account || "-"}</Typography>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                       <Typography className={classes.textHeder}>Account Name</Typography>
                                                       <Typography className={classes.textContent}>{data.account_name || "-"}</Typography>
                                                  </Grid>
                                                  <CardMedia
                                                       component="img"
                                                       sx={{ width: "100%", height: "auto", maxHeight: "400px" }}
                                                       alt="slip"
                                                       image={data.bank_path ? stateFinance.image_bank : slip}
                                                  />
                                             </Grid> */}
                                        </Grid>
                                   </Grid>
                              )}
                              {data.status === 2 && (
                                   <Grid item xs={12} container rowSpacing={3} columnSpacing={3}>
                                        <Grid item xs={12} sm={6} className={classes.btnStyleClose}>
                                             <Button
                                                  variant="outlined"
                                                  color="error"
                                                  className={classes.btnStyle}
                                                  onClick={() => {
                                                       approveRejectWithdraw(3, data.transaction_id);
                                                       handleClose();
                                                  }}
                                             >
                                                  {language === "th" ? "ปฏิเสธ" : "reject"}
                                             </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={classes.btnStyleWallet}>
                                             <Button
                                                  variant="contained"
                                                  // color="error"
                                                  style={{ backgroundColor: "#4589C6" }}
                                                  className={classes.btnStyle}
                                                  onClick={() => {
                                                       approveRejectWithdraw(1, data.transaction_id);
                                                       handleClose();
                                                  }}
                                             >
                                                  {language === "th" ? "อนุมัติ" : "Approved"}
                                             </Button>
                                        </Grid>
                                   </Grid>
                              )}
                         </Grid>
                    </Grid>
               </Fade>
          </Modal>
     );
};
const useStyles = makeStyles(() => ({
     boxStyle: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "820px",
          minWidth: "280px",
          bgcolor: "#707070",
          background: "#fff",
          boxShadow: "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
          padding: "40px 18px",
          borderRadius: "8px",
          "@media (max-width: 600px)": {
               marginTop: "50%",
               display: "flex",
          },
          "@media (max-width: 667px  and min-width: 660px)": {
               marginTop: "32%",
               display: "flex",
          },
          "@media (max-width: 897px and min-width: 890px)": {
               marginTop: "25%",
               display: "flex",
          },
     },
     btnStyle: { maxWidth: 200, fontSize: 16, width: "100%", borderRadius: 40 },
     btnStyleClose: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "right",
          },
     },
     btnStyleWallet: {
          textAlign: "center",
          [theme.breakpoints.up("sm")]: {
               textAlign: "left",
          },
     },
     textHeder: {
          fontSize: 14,
          color: "#000",
     },
     textContent: {
          fontSize: 18,
          fontWeight: 600,
          color: "#000",
     },
     buttonClose: {
          backgroundColor: "Transparent",
          backgroundRepeat: "no-repeat",
          border: "none",
          cursor: "pointer",
          overflow: "hidden",
          "&:hover": {
               backgroundColor: "Transparent",
          },
     },
}));
export default ModalApproveWithdraw;
