import React, { forwardRef } from "react";
import { NavLink as RoutLink } from "react-router-dom";
import { Typography, Grid, Link, Container, Menu, MenuItem } from "@material-ui/core";
// import { withRouter } from "react-router-dom";
// import { ip } from "../../service/service";
// import swal from "sweetalert2";
// import moment from "moment";
import { sideBar } from "../../functions/Static";
import { GET, LOGOUT } from "service";

const CustomRouterLink = forwardRef((props, ref) => (
     <Grid ref={ref} style={{ flexGrow: 1 }}>
          <RoutLink {...props} />
     </Grid>
));

const MinorHeader = (props) => {
     //--------------------------------------------------------------------//
     const [anchorEl, setAnchorEl] = React.useState(null);

     const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
     };

     const handleClose = () => {
          setAnchorEl(null);
     };

     const onClickLogout = async () => {
          try {
               let logout = await GET(LOGOUT);
               if (logout.success) {
                    window.location.reload();
               }
          } catch (error) { }
     };
     //--------------------------------------------------------------------//
     const { language } = props;
     let path = window.location.pathname;
     const role = localStorage.getItem("role");
     return (
          <Grid className="minor-header" top={{ xs: 90 }} position={{ xs: "absolute" }} sx={{ display: "block" }}>
               <Container maxWidth="xl">
                    <Grid container columnSpacing={{ xs: 1, sm: 4, md: 4 }} mt={{ xs: 2, sm: "none", md: "none" }} rowSpacing={1} direction="row" justifyContent="center" alignItems="center">
                         {role === "admin"
                              ? sideBar.admin.map((el, i) => (
                                   <Grid key={i} item xs="auto" >
                                        {el.en === "Logout" ? (
                                             <Link className="link-head-minor" component={CustomRouterLink} onClick={onClickLogout} to={el.link}>
                                                  <Typography fontSize={{ xs: "14px", sm: "none", md: "none" }} sx={path.includes(el.link) ? { borderBottom: "2px solid #4589C6", color: "white" } : {}} className="title-menu">
                                                       {language === "th" ? el.th : el.en}
                                                  </Typography>
                                             </Link>
                                        ) : (
                                             <Link className="link-head-minor" component={CustomRouterLink} to={el.link}>
                                                  <Typography fontSize={{ xs: "14px", sm: "none", md: "none" }} sx={path.includes(el.link) ? { borderBottom: "2px solid #4589C6", color: "white" } : {}} className="title-menu">
                                                       {language === "th" ? el.th : el.en}
                                                  </Typography>
                                             </Link>
                                        )}
                                   </Grid>
                              ))
                              : role === "sale"
                                   ? sideBar.sale.map((el, i) => (
                                        <Grid key={i} item xs="auto">
                                             {el.en === "Logout" ? (
                                                  <Link className="link-head-minor" component={CustomRouterLink} onClick={onClickLogout} to={el.link}>
                                                       <Typography sx={path.includes(el.link) ? { borderBottom: "2px solid #4589C6", color: "white" } : {}} className="title-menu">
                                                            {language === "th" ? el.th : el.en}
                                                       </Typography>
                                                  </Link>
                                             ) : (
                                                  <Link className="link-head-minor" component={CustomRouterLink} to={el.link}>
                                                       <Typography sx={path.includes(el.link) ? { borderBottom: "2px solid #4589C6", color: "white" } : {}} className="title-menu">
                                                            {language === "th" ? el.th : el.en}
                                                       </Typography>
                                                  </Link>
                                             )}
                                        </Grid>
                                   ))
                                   : role === "normal"
                                        ? sideBar.normal.map((el, i) => (
                                             <Grid key={i} item xs="auto">
                                                  {el.en === "Logout" ? (
                                                       <Link className="link-head-minor" component={CustomRouterLink} onClick={onClickLogout} to={el.link}>
                                                            <Typography sx={path.includes(el.link) ? { borderBottom: "2px solid ##4589C6", color: "white" } : {}} className="title-menu">
                                                                 {language === "th" ? el.th : el.en}
                                                            </Typography>
                                                       </Link>
                                                  ) : (
                                                       <Link className="link-head-minor" component={CustomRouterLink} to={el.link}>
                                                            <Typography sx={path.includes(el.link) ? { borderBottom: "2px solid ##4589C6", color: "white" } : {}} className="title-menu">
                                                                 {language === "th" ? el.th : el.en}
                                                            </Typography>
                                                       </Link>
                                                  )}
                                             </Grid>
                                        ))
                                        : null}
                    </Grid>
               </Container>
          </Grid>
     );
};

export default MinorHeader;
