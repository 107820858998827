import { Box, Grid, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import '../../../css/index.scss';
const theme = createMuiTheme();

const TapCrypto = (props) => {
    const { language, data } = props;
    const classes = useStyles();

    return (
        <Grid container columns={12} spacing={3} mt={5} mx={2}>
            <Grid item xs={12}>
                <Box className={classes.CardStyle}>
                    <Grid container xs={12} spacing={2} p={{ xs: 2, sm: 4, md: 5 }}>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography className={classes.fontHeader}>Crypto Type</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Typography className={classes.fontLabel}>{data.crypto_type ? data.crypto_type : "N/A"}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography className={classes.fontHeader}>Address USDT</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Typography className={classes.fontLabel}>{data.address_usdt ? data.address_usdt : "N/A"}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    fontHeader: {
        fontSize: 18,
        fontWeight: 700,
        color: "#000",
    },
    fontLabel: {
        fontSize: 16,
        color: "#000",
    },
    CardStyle: {
        border: 1,
        border: "3px dotted #4589C6",
        borderRadius: "20px",
        overflowWrap: "anywhere",
    }
});

export default withRouter(TapCrypto);
