import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import HeaderTypography from "../../components/HeaderTypography/HeaderTypography";
import TableGrid from "./Components/TableGrid";
import SearchGrid from "./Components/searchGrid";
import { GETMT4, POST } from "service";
import Loading from "components/Loading/Loading";
import swal from "sweetalert2";

const dataService = [
     {
          id: 1,
          walletAccount: "Name Account",
          transactionNumber: "TF-WL-WL-20200604214850",
          code: "TF-WL-WL",
          release: "2020-06-04 21:48:50",
          ip: "xxxxxxxxxxxxxxxxxxxxxxx",
          wallet: "11,940 USD",
          tranfer: "11,940 USD",
          tranferBy: "Name Lastname",
          status: "success",
     },
     {
          id: 2,
          walletAccount: "Name Account",
          transactionNumber: "TF-WL-WL-20200604214850",
          code: "TF-WL-WL",
          release: "2020-06-04 21:48:50",
          ip: "xxxxxxxxxxxxxxxxxxxxxxx",
          wallet: "11,940 USD",
          tranfer: "11,940 USD",
          tranferBy: "Name Lastname",
          status: "fail",
     },
     {
          id: 3,
          walletAccount: "Name Account",
          transactionNumber: "TF-WL-WL-20200604214850",
          code: "TF-WL-WL",
          release: "2020-06-04 21:48:50",
          ip: "xxxxxxxxxxxxxxxxxxxxxxx",
          wallet: "11,940 USD",
          tranfer: "11,940 USD",
          tranferBy: "Name Lastname",
          status: "success",
     },
     {
          id: 4,
          walletAccount: "Name Account",
          transactionNumber: "TF-WL-WL-20200604214850",
          code: "TF-WL-WL",
          release: "2020-06-04 21:48:50",
          ip: "xxxxxxxxxxxxxxxxxxxxxxx",
          wallet: "11,940 USD",
          tranfer: "11,940 USD",
          tranferBy: "Name Lastname",
          status: "fail",
     },
     {
          id: 5,
          walletAccount: "Name Account",
          transactionNumber: "TF-WL-WL-20200604214850",
          code: "TF-WL-WL",
          release: "2020-06-04 21:48:50",
          ip: "xxxxxxxxxxxxxxxxxxxxxxx",
          wallet: "11,940 USD",
          tranfer: "11,940 USD",
          tranferBy: "Name Lastname",
          status: "success",
     },
     {
          id: 6,
          walletAccount: "Name Account",
          transactionNumber: "TF-WL-WL-20200604214850",
          code: "TF-WL-WL",
          release: "2020-06-04 21:48:50",
          ip: "xxxxxxxxxxxxxxxxxxxxxxx",
          wallet: "11,940 USD",
          tranfer: "11,940 USD",
          tranferBy: "Name Lastname",
          status: "success",
     },
     {
          id: 7,
          walletAccount: "Name Account",
          transactionNumber: "TF-WL-WL-20200604214850",
          code: "TF-WL-WL",
          release: "2020-06-04 21:48:50",
          ip: "xxxxxxxxxxxxxxxxxxxxxxx",
          wallet: "11,940 USD",
          tranfer: "11,940 USD",
          tranferBy: "Name Lastname",
          status: "fail",
     },
];
export class TradingAccounts extends Component {
     constructor(props) {
          super(props);

          this.state = {
               dataAccount: [],
               loading: false,
               page: 1,
               data_trading_csv: [],
          };
     }

     componentDidMount = () => {
          this.getMT4();
     };
     getMT4 = async () => {
          try {
               this.setState({ loading: true });
               let getMT4 = await POST(GETMT4);
               if (getMT4.success) {
                    let result = getMT4.result.data;
                    let csv = [];
                    result.map((el) => {
                         csv.push({
                              mt4: el.mt4, user_id: el.user_id, namelastname: `${el.name} ${el.lastname}`, email: el.email, balance: el.balance, account_type_name: el.account_type_name
                         });
                    });
                    this.setState({ dataAccount: getMT4.result.data, balance: getMT4.result.mt4_balance, data_trading_csv: csv });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "ไม่สำเร็จ", text: getMT4.message, icon: "warning",
                         // timer: 6000, 
                         showConfirmButton: true
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "ไม่สำเร็จ", text: error, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
               });
          }
     };

     handleChangeText = (event) => {
          this.setState({ [event.target.name]: event.target.value });
     };

     onClickSearch = () => {
          let { dataAccount, search_text } = this.state;
          let data_search = dataAccount.filter(
               (el) =>
                    (search_text ? `${el.name} ${el.lastname}`.toString().toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.name.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.mt4.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.email.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.account_type_name.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                    (search_text ? el.lastname.toLowerCase().includes(search_text.toLowerCase()) : el)
          );
          this.setState({ data_search, page: 1 });
     };
     handleChangePage = (e, value) => {
          this.setState({ page: value });
     };
     render() {
          const { dataAccount, balance, data_search, loading, page, data_trading_csv } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl">
                    <Grid>
                         <HeaderTypography title="Trading Accounts" />
                         <SearchGrid
                              language={language}
                              balance={balance}
                              onClickSearch={this.onClickSearch}
                              state={this.state}
                              handleChangeText={this.handleChangeText}
                              data_trading_csv={data_trading_csv}
                         />
                         <TableGrid body={data_search ? data_search : dataAccount} page={page} handleChangePage={this.handleChangePage} />
                    </Grid>
                    <Loading open={loading} />
               </Container>
          );
     }
}

export default withRouter(TradingAccounts);
