import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import ListMT from "./components/ListMT";
import { DELETEMT, GETMT, POST } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";

export class DeleteMT extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            data_mt: [],
            page: 1,
            search_text: "",
            loading: false,
        };
    }
    componentDidMount = () => {
        this.getMT();
    };

    getMT = async () => {
        try {
            this.setState({ loading: true });
            let mt = await POST(GETMT);
            if (mt.success) {
                this.setState({
                    data: mt.result.data,
                    data_mt: mt.result.data
                });
                this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: mt.message, icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };


    deleteMT = async (user_mt4_id, user_id) => {
        try {
            let res = await POST(DELETEMT, { user_mt4_id, user_id });
            if (res.success) {
                swal.fire({
                    icon: 'success',
                    // text: `${res.message}`,
                    text: `ลบรายการสำเร็จ`,
                    confirmButtonText: 'ตกลง',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            } else {
                swal.fire({
                    icon: 'warning',
                    // text: `${res.message}`,
                    text: `ลบรายการไม่สำเร็จ`,
                    confirmButtonText: 'ตกลง',
                })
            }
        } catch (error) {
            alert("error", error);
        }
    };

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };
    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    submitSearch = () => {
        let { search_text, data_mt } = this.state;
        let data = data_mt.filter(
            (el) =>
                (search_text ? el.name.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                (search_text ? el.lastname.toLowerCase().includes(search_text.toLowerCase()) : el) ||
                (search_text ? `${el.name} ${el.lastname}`.toString().toLowerCase().includes(search_text.toLowerCase()) : el) ||
                (search_text ? el.email.toLowerCase().includes(search_text.toLowerCase()) : el)
        );
        this.setState({ data, page: 1 });
    };

    deleteAlert = (obj) => {
        swal.fire({
            title: `คุณต้องการลบ ${"\n"}${obj.meta_type === "MT5" ? "MT5" : "MT4"} : ${obj.mt4} หรือไม่?`,
            text: `${obj.meta_type === "MT4" ? "ถ้าคุณต้องการลบ กรุณาลบ MT4 นี้ ใน Manager ด้วย" : "ถ้าลบแล้วคุณไม่สามารถกู้คืนได้"}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ลบ',
            confirmButtonColor: "#d33",
            cancelButtonText: 'ไม่ลบ',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteMT(obj.user_mt4_id, obj.user_id);
            } else if (
                result.dismiss === swal.DismissReason.cancel
            ) {
                swal.fire({
                    text: 'ยกเลิกรายการแล้ว',
                    confirmButtonText: 'ตกลง',
                })
            }
        })
    }

    render() {
        const language = localStorage.getItem("language");
        const { page, data, loading } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <ListMT
                        language={language}
                        data={data}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        handleSearch={this.handleSearch}
                        state={this.state}
                        submitSearch={this.submitSearch}
                        deleteAlert={this.deleteAlert}
                    />
                </Grid>
            </Container>
        );
    }
}
// const data = [
//     {
//         id: 1,
//         code: "00000",
//         manager: "Name Lastname",
//         amount: 1000,
//     },
//     {
//         id: 2,
//         code: "00000",
//         manager: "Name Lastname",
//         amount: 1000,
//     },
//     {
//         id: 3,
//         code: "00000",
//         manager: "Name Lastname",
//         amount: 1000,
//     },
//     {
//         id: 4,
//         code: "00000",
//         manager: "Name Lastname",
//         amount: 1000,
//     },
//     {
//         id: 5,
//         code: "00000",
//         manager: "Name Lastname",
//         amount: 1000,
//     },
//     {
//         id: 6,
//         code: "00000",
//         manager: "Name Lastname",
//         amount: 1000,
//     },
// ];
export default withRouter(DeleteMT);
