import React from "react";
import { Route } from "react-router-dom";
// import PropTypes from 'prop-types';

const RouteAndLayout = ({ layout: Layout, component: Component, data_admin, ...rest }) => {
     return (
          <Route
               {...rest}
               render={(matchProps) => (
                    <Layout data_admin={data_admin}>
                         <Component {...matchProps} data_admin={data_admin} />
                    </Layout>
               )}
          />
     );
};
export default RouteAndLayout;
