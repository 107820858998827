import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Typography, Pagination, Link, Button, TextField } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const TableRebate = (props) => {
     const { data, state, handleChangeTextRebate, user_id, accountType, dataIBRebate, onClickAddRebate, onClickSaveRebate } = props;

     const head = [{ label: "Forex" }, { label: "Gold" }, { label: "Crypto" }, { label: "Index" }, { label: "Oil" }];

     const classes = useStyles();
     return (
          <Grid className="table-btn">
               <TableContainer>
                    <Grid item container justifyContent={"center"} mb={2}>
                         <Typography className={classes.fontStyleTitle}>
                              {state.profile.name} {state.profile.lastname}
                         </Typography>
                    </Grid>
                    {dataIBRebate.map((element, index) => {
                         return (
                              <Grid>
                                   <Grid item container justifyContent={"flex-end"} mb={2}>
                                        <Button
                                             variant="contained"
                                             // color="error"
                                             style={{ backgroundColor: "#4589C6" }}
                                             sx={{ borderRadius: 5, width: 100, fontSize: 16 }}
                                             onClick={() => onClickAddRebate(element.sub_1_user_id, element.account_type_id)}
                                        >
                                             Add
                                        </Button>
                                   </Grid>
                                   <Table className={classes.table} key={index}>
                                        <TableHead>
                                             <TableRow>
                                                  <TableCell align="center" colSpan={12}>
                                                       {element.acount_type_name}
                                                  </TableCell>
                                             </TableRow>
                                             <TableRow>
                                                  <TableCell>
                                                       <TableCell>
                                                            <Typography noWrap>Type</Typography>
                                                       </TableCell>
                                                  </TableCell>

                                                  {head.map((e, ind) => {
                                                       return (
                                                            <TableCell key={ind}>
                                                                 <Typography noWrap>{e.label}</Typography>
                                                            </TableCell>
                                                       );
                                                  })}
                                             </TableRow>
                                        </TableHead>
                                        <TableBody>
                                             {element.rebate.map((e, i) => {
                                                  return (
                                                       <TableRow hover className={index % 2 !== 0 ? "bg-table" : ""} key={i}>
                                                            <TableCell>
                                                                 <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                      {i === 0 ? "Rebate IB" : `rebate ชั้นที่ ${i}`}
                                                                 </Typography>
                                                            </TableCell>

                                                            <TableCell>
                                                                 <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                      <Grid container>
                                                                           <TextField
                                                                                className="textfield-profile"
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                placeholder={"Forex"}
                                                                                name={"forex"}
                                                                                value={e.forex}
                                                                                inputProps={{
                                                                                     maxLength: 13,
                                                                                     step: "1",
                                                                                }}
                                                                                onChange={(e) =>
                                                                                     handleChangeTextRebate(e, i, index, element.sub_1_user_id, element.account_type_id)
                                                                                }
                                                                           />
                                                                      </Grid>
                                                                 </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                 <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                      <Grid container>
                                                                           <TextField
                                                                                className="textfield-profile"
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                placeholder={"Gold"}
                                                                                name={"gold"}
                                                                                value={e.gold}
                                                                                inputProps={{
                                                                                     maxLength: 13,
                                                                                     step: "1",
                                                                                }}
                                                                                onChange={(e) =>
                                                                                     handleChangeTextRebate(e, i, index, element.sub_1_user_id, element.account_type_id)
                                                                                }
                                                                           />
                                                                      </Grid>
                                                                 </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                 <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                      <Grid container>
                                                                           <TextField
                                                                                className="textfield-profile"
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                placeholder={"Crypto"}
                                                                                name={"crypto"}
                                                                                value={e.crypto}
                                                                                inputProps={{
                                                                                     maxLength: 13,
                                                                                     step: "1",
                                                                                }}
                                                                                onChange={(e) =>
                                                                                     handleChangeTextRebate(e, i, index, element.sub_1_user_id, element.account_type_id)
                                                                                }
                                                                           />
                                                                      </Grid>
                                                                 </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                 <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                      <Grid container>
                                                                           <TextField
                                                                                className="textfield-profile"
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                placeholder={"Index"}
                                                                                name={"index"}
                                                                                value={e.index}
                                                                                inputProps={{
                                                                                     maxLength: 13,
                                                                                     step: "1",
                                                                                }}
                                                                                onChange={(e) =>
                                                                                     handleChangeTextRebate(e, i, index, element.sub_1_user_id, element.account_type_id)
                                                                                }
                                                                           />
                                                                      </Grid>
                                                                 </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                 <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                                      <Grid container>
                                                                           <TextField
                                                                                className="textfield-profile"
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                placeholder={"Oil"}
                                                                                name={"oil"}
                                                                                value={e.oil}
                                                                                inputProps={{
                                                                                     maxLength: 13,
                                                                                     step: "1",
                                                                                }}
                                                                                onChange={(e) =>
                                                                                     handleChangeTextRebate(e, i, index, element.sub_1_user_id, element.account_type_id)
                                                                                }
                                                                           />
                                                                      </Grid>
                                                                 </Typography>
                                                            </TableCell>
                                                       </TableRow>
                                                  );
                                             })}
                                        </TableBody>
                                   </Table>
                                   <Grid item container justifyContent={"center"} mt={5} mb={5}>
                                        <Button
                                             variant="contained"
                                             // color="error"
                                             style={{ backgroundColor: "#4589C6" }}
                                             sx={{ borderRadius: 5, width: 100, fontSize: 16 }}
                                             onClick={() => onClickSaveRebate(element.account_type_id)}
                                        >
                                             Save
                                        </Button>
                                   </Grid>
                              </Grid>
                         );
                    })}
               </TableContainer>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     fontActive: {
          fontSize: 15,
          color: "#fff",
          padding: 8,
          borderRadius: 10,
     },
});

export default TableRebate;
