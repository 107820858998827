import { Button, Grid, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
const theme = createMuiTheme();

const GridTableIBMemberRebate = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const onClickView = (id) => {
        // this.props.history.push(`/ibdetail/${id}`);
        // props.history.push(`/editrebate/${id}`);
        history.push(`/editrebate/${props.userId}/${id}`);
    };

    const onClickViewNew = (id) => {
        // this.props.history.push(`/ibdetail/${id}`);
        // props.history.push(`/editrebate/${id}`);
        history.push(`/edittypeaccountrebate/${props.userId}/${id}`);
    };

    const role = localStorage.getItem("role");
    const nav_length = props.nav_sub_ib.length;

    const sub_head = [{ label: "Forex" }, { label: "Gold" }, { label: "Crypto" }, { label: "Index" }, { label: "Oil" }];

    return (
        <Grid className="table-btn">
            <Grid item container direction={"row"} borderBottom={"2px solid #EBEBEB"} paddingBottom="10px">
                <Grid item xs={12}>
                    <Stack direction="row" display="flex" alignItems="center" >
                        {props.nav_sub_ib.map((e, i) =>
                            <Typography key={i + 1} className={classes.fontStyleNav} sx={{ cursor: "pointer" }} onClick={() => props.onClickNetworkChildBack(e.user_id)}>
                                {e.name} {e.lastname}&nbsp;{nav_length - (i + 1) > 0 && `>`}&nbsp;
                            </Typography>
                        )}
                    </Stack>
                    <Typography mt={1} className={classes.fontStyleTitle}>Sub IB</Typography>
                </Grid>
            </Grid>
            < TableContainer >
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" rowSpan={2}>
                                <Typography noWrap>Username</Typography>
                            </TableCell>
                            {props.account_type_rebate.head_tbl.map((e, i) => {
                                return (
                                    <>
                                        <TableCell align="center" colSpan={5}>
                                            <Typography noWrap>{e.account_type_name}</Typography>
                                        </TableCell>
                                        <TableCell rowSpan={2} align="center" style={{ minWidth: 100 }}>
                                            <Typography noWrap>Action</Typography>
                                        </TableCell>
                                    </>
                                )
                            })}
                            {/* <TableCell align="center" rowSpan={2}>
                                <Typography noWrap>Action</Typography>
                            </TableCell> */}
                        </TableRow>
                        <TableRow>
                            {Array(props.account_type_rebate.head_tbl.length)
                                .fill({}).map((e, i) =>
                                    sub_head.map((e, ind) => {
                                        return (
                                            <TableCell key={ind} align="left" style={{ minWidth: 100 }}>
                                                <Typography noWrap>{e.label}</Typography>
                                            </TableCell>
                                        );
                                    })
                                )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.body.map((el, ind) => {
                            return (
                                <TableRow hover key={ind} className={ind % 2 !== 0 ? "bg-table" : ""}>
                                    <TableCell >
                                        {el.count_team > 0 ?
                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070", cursor: "pointer" }}
                                                onClick={() => {
                                                    props.onClickNetworkChild(el.user_id, el.name, el.lastname)
                                                    // props.getAccountRebate(el.user_id)
                                                }}>
                                                ({el.count_team}) {el.name} {el.lastname} ({el.email})
                                            </Typography>
                                            :
                                            <Typography noWrap sx={{ fontSize: 16, color: "#707070" }} >
                                                ({el.count_team}) {el.name} {el.lastname} ({el.email})
                                            </Typography>
                                        }
                                    </TableCell>
                                    {el.rebate.map((ele, i) => {
                                        return (
                                            <>
                                                <TableCell >
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Forex"}
                                                                name={"forex"}
                                                                value={ele.forex}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Gold"}
                                                                name={"gold"}
                                                                value={ele.gold}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Crypto"}
                                                                name={"crypto"}
                                                                value={ele.crypto}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Index"}
                                                                name={"index"}
                                                                value={ele.index}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        <Grid container>
                                                            <TextField
                                                                className="textfield-profile"
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                placeholder={"Oil"}
                                                                name={"oil"}
                                                                value={ele.oil}
                                                                inputProps={{
                                                                    maxLength: 13,
                                                                    step: "1",
                                                                }}
                                                                disabled={!ele.edit_rebate}
                                                                onChange={(e) =>
                                                                    props.handleChangeEditRebate(e, i, el.user_id)
                                                                }
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </TableCell>
                                                {(role === "admin" && el.is_head) ?
                                                    <TableCell align="center">
                                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            <Stack direction="row" spacing={2} display="flex" justifyContent="center">
                                                                {ele.edit_rebate ?
                                                                    <>
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() =>
                                                                                props.saveEditRebate({
                                                                                    user_id: el.user_id,
                                                                                    account_type_id: ele.account_type_id,
                                                                                    forex: ele.forex,
                                                                                    gold: ele.gold,
                                                                                    crypto: ele.crypto,
                                                                                    index: ele.index,
                                                                                    oil: ele.oil
                                                                                })}
                                                                        >
                                                                            Save Rebate
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => props.onClickEditAbleRebate(el.user_id, i)}
                                                                        >
                                                                            Cancel Edit
                                                                        </Button>
                                                                    </>
                                                                    :
                                                                    <Button
                                                                        variant="contained"
                                                                        onClick={() => props.onClickEditAbleRebate(el.user_id, i)}
                                                                    // disabled={!ele.edit_rebate}
                                                                    >
                                                                        Edit Rebate
                                                                    </Button>
                                                                }
                                                            </Stack>
                                                        </Typography>
                                                    </TableCell>
                                                    :
                                                    <TableCell></TableCell>
                                                }
                                            </>
                                        )
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
            </Grid>
        </Grid >
    );
};
const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
    fontStyleTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: "#000",
    },
    fontStyleNav: {
        fontSize: 13,
        fontWeight: "bold",
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
    },
    fontStyleDetail: {
        fontSize: 13,
        color: "#000",
        // textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
        marginTop: 10,
        marginBottom: 10,
    },
    fontStyle: {
        fontSize: 13,
        color: "#000",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
        display: "flex",
        justifyContent: "center",
    },
}));
export default GridTableIBMemberRebate;
