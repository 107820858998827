import { Container } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ManagePolicy from "./Components/ManagePolicy";
import ModalManagePolicy from "./Components/ModalManagePolicy";
import { POST, ADDPOLICY, ADDPOLICYTYPE, EDITPOLICYTYPE, POLICYBYID, EDITPOLICY, DELETEPOLICYTYPE, DELETEPOLICY } from "service";
import swal from "sweetalert2";
import Loading from "components/Loading/Loading";
import { sideBar, StringSet } from "functions/Static";

class SettingManagePolicy extends Component {
     constructor(props) {
          super(props);
          this.state = {
               dataArray: [],
               index: 0,
               modalEdit: false,
               loading: false,
          };
     }

     componentDidMount = () => {
          if (window.location.pathname !== "/setting_manage_policy/add") {
               this.getPolicyByID();
          }
     };

     getPolicyByID = async () => {
          let { dataArray, index } = this.state;
          try {
               this.setState({ loading: true });
               let policy = await POST(POLICYBYID, { policy_type_id: this.props.match.params.id });
               if (policy.success) {
                    let { array, policy_type, policy_type_id } = policy.result[0];
                    array.map((el, i) =>
                         dataArray.push({
                              detail_en: el.policy.en,
                              detail_th: el.policy.th,
                              policy_id: el.policy_id,
                              id: i + 1,
                         })
                    );
                    array.map((el) => index++);
                    this.setState({ subject_en: policy_type.en, subject_th: policy_type.th, dataArray, policy_type_id, loading: false, index });
               } else {
                    this.setState({ loading: false });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
          }
     };

     onClickaddDataQuestion = () => {
          let { dataArray, index } = this.state;
          index++;
          dataArray.push({ detail_en: "detail", detail_th: "รายละเอียด", id: index });
          this.setState({ index, dataArray });
     };

     handleChangeText = (e) => {
          this.setState({ [e.target.name]: e.target.value });
     };

     openEdit = (id, detail) => {
          const { dataArray } = this.state;
          if (detail !== "รายละเอียด") {
               dataArray.map((el) => {
                    if (el.id === id) {
                         this.setState({ detail_en: el.detail_en, detail_th: el.detail_th });
                    }
               });
          }
          this.setState({ modalEdit: true, id: id });
     };

     deletePolicy = async (id, policy_id) => {
          let { dataArray } = this.state;
          try {
               if (policy_id) {
                    this.setState({ loading: true });
                    let delete_policy = await POST(DELETEPOLICY, { policy_id });
                    if (delete_policy.success) {
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "ลบ Policy สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: delete_policy.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
          dataArray.splice(
               dataArray.findIndex((el) => el.id === id),
               1
          );
          this.setState({ id: id, dataArray });
     };

     handleClose = () => {
          this.setState({ modalEdit: false, detail_en: null, detail_th: null });
     };

     onClickEditQuestion = () => {
          const { id, dataArray, detail_en, detail_th } = this.state;
          dataArray.find((el) => {
               if (el.id === id) {
                    el.detail_en = detail_en;
                    el.detail_th = detail_th;
               }
          });
          this.setState({ dataArray, modalEdit: false, detail_en: null, detail_th: null });
     };

     onClickAddPolicy = async () => {
          const { subject_en, subject_th, dataArray } = this.state;
          try {
               if (subject_en && subject_th) {
                    this.setState({ loading: true });
                    let addPolicyType = await POST(ADDPOLICYTYPE, { policy_type_th: subject_th, policy_type_en: subject_en });
                    if (addPolicyType.success) {
                         let addPolicy = dataArray.map(async (e) => {
                              const { detail_en, detail_th } = e;
                              if (detail_en && detail_th) {
                                   await POST(ADDPOLICY, { policy_en: detail_en, policy_th: detail_th, policy_type_id: addPolicyType.result.policy_type_id });
                              }
                         });
                         await Promise.all(addPolicy);
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "เพิ่ม Policy สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         window.location.assign("/setting_policy");
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: addPolicyType.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: "กรุณากรอกข้อมูลให้ครบถ้วน", icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };
     onClickEditPolicy = async () => {
          const { subject_en, subject_th, dataArray, policy_type_id } = this.state;
          try {
               if (subject_en && subject_th) {
                    this.setState({ loading: true });
                    let edit_policy_type = await POST(EDITPOLICYTYPE, { policy_type_th: subject_th, policy_type_en: subject_en, policy_type_id: policy_type_id });
                    if (edit_policy_type.success) {
                         let edit_policy = dataArray.map(async (e) => {
                              const { detail_en, detail_th, policy_id } = e;
                              if (detail_en && detail_th && policy_id) {
                                   await POST(EDITPOLICY, { policy_th: detail_th, policy_en: detail_en, policy_id: policy_id, policy_type_id: policy_type_id });
                              } else {
                                   await POST(ADDPOLICY, { policy_en: detail_en, policy_th: detail_th, policy_type_id: policy_type_id });
                              }
                         });
                         await Promise.all(edit_policy);
                         this.setState({ loading: false });
                         swal.fire({ title: "สำเร็จ", text: "แก้ไข Policy สำเร็จ", icon: "success", 
                         // timer: 6000, 
                         showConfirmButton: true });
                         window.location.reload();
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "แจ้งเตือน", text: edit_policy_type.message, icon: "warning", 
                         // timer: 6000, 
                         showConfirmButton: true });
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: "กรุณากรอกข้อมูลให้ครบถ้วน", icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(`error`, error);
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     onClickDeleteTypePolicy = async () => {
          const { policy_type_id } = this.state;
          try {
               this.setState({ loading: true });
               let delete_type_policy = await POST(DELETEPOLICYTYPE, { policy_type_id: policy_type_id });
               if (delete_type_policy.success) {
                    this.setState({ loading: false });
                    swal.fire({ title: "สำเร็จ", text: "ลบ Policy สำเร็จ", icon: "success", 
                    // timer: 6000, 
                    showConfirmButton: true });
                    window.location.assign("/setting_policy");
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "แจ้งเตือน", text: delete_type_policy.message, icon: "warning", 
                    // timer: 6000, 
                    showConfirmButton: true });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({ title: "แจ้งเตือน", text: error, icon: "warning", 
               // timer: 6000, 
               showConfirmButton: true });
          }
     };

     render() {
          const path = window.location.pathname;
          const { modalEdit, loading } = this.state;
          return (
               <Container maxWidth="xl">
                    <Loading open={loading} />
                    <ManagePolicy
                         handleChangeText={this.handleChangeText}
                         state={this.state}
                         onClickadd={this.onClickaddDataQuestion}
                         openEdit={this.openEdit}
                         deletePolicy={this.deletePolicy}
                         onClickAddPolicy={this.onClickAddPolicy}
                         onClickEditPolicy={this.onClickEditPolicy}
                         onClickDeleteTypePolicy={this.onClickDeleteTypePolicy}
                         path={path}
                    />
                    <ModalManagePolicy
                         open={modalEdit}
                         handleClose={this.handleClose}
                         state={this.state}
                         handleChangeText={this.handleChangeText}
                         onClickEditQuestion={this.onClickEditQuestion}
                    />
               </Container>
          );
     }
}

export default withRouter(SettingManagePolicy);
