import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Stack, Button, TextField } from "@material-ui/core";
import { GridTextField } from "../../../components/index";
import { textFiled, headerTable } from "../../../functions/Static";
import GridTable from "./GridTable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DatePicker from "react-date-picker";

const ListCashback = (props) => {
  //  const classes = useStyles();
  const {
    data,
    language,
    page,
    handleChangePage,
    state,
    submitSearch,
    handleDateStartChange,
    handleDateEndChange,
    handleSearchCashback,
  } = props;
  const count = Math.ceil(data.length > 0 && data.length / 10);
  const setPage = page - 1;
  const dataList = data.slice(setPage * 10, page * 10);
  const [copied, setCopied] = useState(false);
  const role = localStorage.getItem("role");
  return (
    <Grid container spacing={5} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            color: "#000",
            borderBottom: "solid 2px #4589C6",
            my: 2,
          }}
        >
          Cashback
        </Typography>
        <Grid container item rowSpacing={2} spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <DatePicker
              className={"date-picker"}
              onChange={handleDateStartChange}
              value={state.date_start}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <DatePicker
              className={"date-picker"}
              onChange={handleDateEndChange}
              value={state.date_end}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <TextField
              id="Ticket"
              label="Ticket"
              variant="outlined"
              value={state.search_cashback}
              state={state}
              onChange={handleSearchCashback}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4589C6" }}
              //  className="btn-style"
              sx={{
                maxWidth: 120,
                fontSize: 16,
                width: "100%",
                borderRadius: 40,
                height: 40,
              }}
              onClick={submitSearch}
            >
              {language === "th" ? "ค้นหา" : "Search"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <GridTable
          head={headerTable.cblist}
          body={dataList}
          count={count}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Grid>
    </Grid>
  );
};
export default withRouter(ListCashback);
