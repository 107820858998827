import { Grid, Button, Typography, Link } from "@material-ui/core";
import { GridTextField } from "components";
import HeaderTypography from "components/HeaderTypography/HeaderTypography";
import { StringSet, textFiled } from "functions/Static";
import Sidebar from "./Sidebar";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const ManagePolicy = (props) => {
     const { state, handleChangeText, onClickadd, openEdit, deletePolicy, onClickAddPolicy, path, onClickEditPolicy, onClickDeleteTypePolicy } = props;
     const classes = useStyles();
     return (
          <Grid Grid container spacing={2}>
               <Grid item xs={3}>
                    <Sidebar title="Menu" pageName="policy" data={StringSet.policy.sidebar} />
               </Grid>
               <Grid item container spacing={2} direction="row" xs={9} alignContent={"flex-start"}>
                    <Grid item xs={12}>
                         <HeaderTypography title={path === "/setting_manage_policy/add" ? "Add Policy" : "Edit Policy"} />
                    </Grid>
                    <GridTextField data={textFiled.policy} state={state} handleChange={handleChangeText} />
                    <Grid item xs={12}>
                         <Button
                              variant="contained"
                              className="btn-style"
                              sx={{ maxWidth: 230, fontSize: 14, borderRadius: 40, height: 50, marginY: 2, background: "#68C171" }}
                              onClick={onClickadd}
                         >
                              + Add Policy
                         </Button>
                    </Grid>
                    <Grid item container xs={12}>
                         <Grid container direction="row" xs={12} borderBottom={"1px solid #EBEBEB"}>
                              <Grid xs={2} item>
                                   <Typography className={classes.fontStyleTitle}>No.</Typography>
                              </Grid>
                              <Grid xs={8} item>
                                   <Typography className={classes.fontStyleTitle}>Policy</Typography>
                              </Grid>
                              <Grid className={classes.fontStyleTitle} xs={1} item>
                                   Edit
                              </Grid>
                              <Grid className={classes.fontStyleTitle} xs={1} item>
                                   Delete
                              </Grid>
                         </Grid>
                         {state.dataArray.map((el, i) => {
                              return (
                                   <Grid item container direction="row" xs={12} borderBottom={"1px solid #EBEBEB"}>
                                        <Grid xs={2} item>
                                             <Typography className={classes.fontStyleDetail}>{i + 1}</Typography>
                                        </Grid>
                                        <Grid xs={4} item>
                                             <Typography className={classes.fontStyleDetail}>{el.detail_en}</Typography>
                                        </Grid>
                                        <Grid xs={4} item>
                                             <Typography className={classes.fontStyleDetail}>{el.detail_th}</Typography>
                                        </Grid>
                                        <Grid className={classes.fontStyleDetail} xs={1} item>
                                             <Link
                                                  component="button"
                                                  sx={{ fontSize: 16, color: "#184B8E" }}
                                                  onClick={() => {
                                                       openEdit(el.id, el.detail_th);
                                                  }}
                                             >
                                                  Edit
                                             </Link>
                                        </Grid>
                                        <Grid className={classes.fontStyleDetail} xs={1} item>
                                             <Link
                                                  component="button"
                                                  sx={{ fontSize: 16, color: "red" }}
                                                  onClick={() => {
                                                       deletePolicy(el.id, el.policy_id);
                                                  }}
                                             >
                                                  Delete
                                             </Link>
                                        </Grid>
                                   </Grid>
                              );
                         })}
                    </Grid>
                    <Grid xs={12} container justifyContent="center" alignContent="flex-end" spacing={2}>
                         {path === "/setting_manage_policy/add" ? (
                              <Grid item xs={12} container justifyContent={"center"}>
                                   <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        sx={{ maxWidth: 230, fontSize: 14, borderRadius: 40, height: 50, marginY: 5 }}
                                        onClick={onClickAddPolicy}
                                   >
                                        Add
                                   </Button>
                              </Grid>
                         ) : (
                              <>
                                   <Grid item xs={6} container justifyContent={"flex-end"}>
                                        <Button
                                             variant="contained"
                                             color="error"
                                             className="btn-style"
                                             sx={{ maxWidth: 230, fontSize: 14, borderRadius: 40, height: 50, marginY: 5 }}
                                             onClick={onClickEditPolicy}
                                        >
                                             Edit
                                        </Button>
                                   </Grid>
                                   <Grid item xs={6}>
                                        <Button
                                             variant="outlined"
                                             color="error"
                                             className="btn-style"
                                             sx={{ maxWidth: 230, fontSize: 14, borderRadius: 40, height: 50, marginY: 5 }}
                                             onClick={onClickDeleteTypePolicy}
                                        >
                                             Delete
                                        </Button>
                                   </Grid>
                              </>
                         )}
                    </Grid>
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles(() => ({
     fontStyleTitle: {
          fontSize: 18,
          fontWeight: 600,
          color: "#000",
     },
     fontStyleDetail: {
          fontSize: 13,
          color: "#000",
          textAlign: "justify",
          [theme.breakpoints.up("md")]: {
               fontSize: 18,
          },
          marginTop: 20,
     },
}));
export default ManagePolicy;
