import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

const useStyles = makeStyles(() => ({
     root: {
          "& > *": {
               margin: theme.spacing(1),
          },
     },
     input: {
          display: "none",
     },
}));

const UploadBtn = (props) => {
     const classes = useStyles();
     return (
          <div className={classes.root}>
               <input accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" onChange={props.onChange} />
               <label htmlFor="contained-button-file">
                    <Button
                         variant="contained"
                         // color="error"
                         style={{ backgroundColor: "#4589C6" }} 
                         sx={{ maxWidth: "100%", fontSize: 12, width: "100%", borderRadius: 2, height: 40 }}
                         component="span"
                         //  onClick={onClick}
                    >
                         Choose File
                    </Button>
               </label>
          </div>
     );
};

export default UploadBtn;
