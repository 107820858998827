import { Grid, TextField, Typography, CardMedia, MenuItem } from "@material-ui/core";

const TextfielAccount = (props) => {
     return props.data.map((e, i) => (
          <Grid key={i} Grid container item xs={12} spacing={2} marginBottom={2}>
               <Grid item xs={6} md={4}>
                    <Typography fontSize={{ xs: 14, sm: 16, md: 16 }}>{e.title}</Typography>
               </Grid>

               <Grid item xs={6} md={8}>
                    {e.type === "text" ? (
                         <TextField
                              placeholder={e.placeholder ? e.placeholder : ""}
                              label={e.label || ""}
                              name={e.name || ""}
                              type={e.type || ""}
                              value={props.state[e.name] || ""}
                              onChange={props.handleChangeText}
                              fullWidth
                              variant="outlined"
                              size="small"
                              onKeyDown={props.onKeyDown}
                              autoComplete="off"
                         />
                    ) : (
                         <TextField
                              disabled={e.disabled ? e.disabled : false}
                              error={props.check && props.check[e.name] ? props.check[e.name].err : false}
                              helperText={props.check && props.check[e.name] && props.check[e.name].err ? props.check[e.name].label : ""}
                              placeholder={e.placeholder ? e.placeholder : ""}
                              className={e.classNameText || ""}
                              label={e.label || ""}
                              name={e.name || ""}
                              type={e.type || ""}
                              value={props.state[e.name] || ""}
                              onChange={props.handleChangeText}
                              select={e.select}
                              fullWidth
                              variant="outlined"
                              size="Normal"
                              onKeyDown={props.onKeyDown}
                              autoComplete="off"
                              InputProps={
                                   e.icon
                                        ? {
                                             startAdornment: (
                                                  <CardMedia
                                                       sx={{ height: 20, width: "auto", objectFit: "contain", mr: 2, mt: -0.25 }}
                                                       component="img"
                                                       alt="Logo"
                                                       image={e.icon}
                                                  />
                                             ),
                                        }
                                        : {}
                              }
                         >
                              {e.select &&
                                   props[e.selectname].map((el) => (
                                        <MenuItem key={el.value} value={el.value} hidden={el.hidden ? el.hidden : false} disabled={el.disabled}>
                                             {el.label}
                                        </MenuItem>
                                   ))}
                         </TextField>
                    )
                    }
               </Grid>


          </Grid>
     ));
};


export default TextfielAccount;
