import { Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { headerTable } from "../../../functions/Static";

const TableWalletControl = (props) => {
     const classes = useStyles();
     const count = Math.ceil(props.data.length > 0 && props.data.length / 10);
     const setPage = props.page - 1;
     const dataList = props.data.slice(setPage * 10, props.page * 10);
     return (
          <Grid item xs={12}>
               <Grid className="table-btn">
                    <TableContainer>
                         <Table className={classes.table}>
                              <TableHead>
                                   <TableRow>
                                        {headerTable.settingWallet.map((el, i) => {
                                             return (
                                                  <TableCell key={i} align={el.align || "left"}>
                                                       <Typography noWrap>{el.label}</Typography>
                                                  </TableCell>
                                             );
                                        })}
                                   </TableRow>
                              </TableHead>
                              <TableBody>
                                   {dataList.map((el, i) => {
                                        return (
                                             <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                                  <TableCell>
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.name} {el.lastname}
                                                       </Typography>
                                                  </TableCell>
                                                  <TableCell>
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.email}
                                                       </Typography>
                                                  </TableCell>
                                                  <TableCell>
                                                       <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {el.wallet}
                                                       </Typography>
                                                  </TableCell>
                                             </TableRow>
                                        );
                                   })}
                              </TableBody>
                         </Table>
                    </TableContainer>
                    <Grid container justifyContent="center" mt={5}>
                         {dataList.length === 0 && (
                              <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                   {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                              </Typography>
                         )}
                         {dataList.length > 0 && <Pagination count={count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />}
                    </Grid>
               </Grid>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
});

export default withRouter(TableWalletControl);
