import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, CardMedia, Typography, Grid, Button } from "@material-ui/core";
import { textFiled } from "../../../functions/Static";
import { GridTextField, CardDetail } from "../../../components/index";
import logo_web from "../../../assets/logo/Logo-color.png";
import swal from "sweetalert2";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme();

export class Register extends Component {
     constructor(props) {
          super(props);

          this.state = {
               inputValue: {},
               checkInput: {},
               step: 1,
          };
     }
     handleChange = (e) => {
          let { checkInput, inputValue } = this.state;
          let name = e.target.name;
          let value = e.target.value;
          checkInput[name] = {
               err: value ? false : true,
               label: "",
          };
          inputValue[name] = value;
          this.setState({ inputValue, checkInput });
     };
     checkInput = () => {
          let state = this.state;
          let { checkInput, step } = this.state;
          let Check = textFiled[step === 1 ? "register" : "login"].map((el) => el.name);
          Check.forEach((e) => {
               checkInput[e] = {
                    err: state.inputValue[e] ? false : true,
                    label: state.inputValue[e] ? "" : "กรุณากรอกข้อมูลให้ครบ",
               };
          });
          this.setState({ checkInput }, async () => {
               let { checkInput } = this.state;
               let input = Object.keys(checkInput).some((e) => checkInput[e].err);
               if (!input) {
                    this.setState({ checkInput: {} });
                    this.nextStep();
               }
          });
     };
     nextStep = () => {
          let { inputValue, step } = this.state;
          if (step === 1) {
               if (inputValue.password === inputValue.confirm_password) {
                    this.setState({ step: 2 });
               } else {
                    swal.fire({ title: "แจ้งเตือน", text: "รหัสผ่านไม่ตรงกัน", icon: "warning", timer: 6000, showConfirmButton: false });
               }
          } else {
               this.setState({ step: 4, inputValue: {}, checkInput: {} });
          }
     };

     render() {
          const { inputValue, checkInput, step } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="sm" sx={{ height: "100%", minHeight: 888, display: "flex", alignItems: "center" }}>
                    <Grid container rowSpacing={4}>
                         <Grid item xs={12}>
                              <LogoTitle language={language} step={step} />
                         </Grid>
                         <Grid item xs={12}>
                              {step === 1 ? (
                                   <Grid container rowSpacing={3}>
                                        <GridTextField
                                             data={textFiled.register}
                                             state={inputValue}
                                             check={checkInput}
                                             handleChange={this.handleChange}
                                             onKeyDown={(e) => {
                                                  if (e.key === "Enter") this.checkInput();
                                             }}
                                        />
                                   </Grid>
                              ) : step === 2 ? (
                                   <Typography sx={{ color: "#fff", fontSize: 28 }}>
                                        Please check your email and click to confirm your email.
                                   </Typography>
                              ) : step === 3 ? (
                                   <Grid container rowSpacing={3}>
                                        <GridTextField
                                             data={textFiled.login}
                                             state={inputValue}
                                             check={checkInput}
                                             handleChange={this.handleChange}
                                             onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                       this.checkInput();
                                                  }
                                             }}
                                        />
                                   </Grid>
                              ) : (
                                   <CardDetail />
                              )}
                         </Grid>
                         <Grid item xs={12}>
                              <Grid container columnSpacing={step === 2 ? 1 : 0} rowSpacing={step === 2 ? 3 : 0}>
                                   {step === 2 && (
                                        <Grid item xs={12} sm={6}>
                                             <Button
                                                  variant="outlined"
                                                  color="error"
                                                  className="btn-style"
                                                  sx={{ maxWidth: 260 }}
                                                  onClick={() => this.setState({ step: 1 })}
                                             >
                                                  {language === "th" ? "ย้อนกลับ" : "Previous"}
                                             </Button>
                                        </Grid>
                                   )}
                                   <Grid item xs={12} sm={step !== 2 ? 12 : 6}>
                                        <Button
                                             variant="contained"
                                             // color="error"
                                             style={{ backgroundColor: "#4589C6" }} 
                                             className="btn-style"
                                             sx={{ maxWidth: 260 }}
                                             onClick={() => {
                                                  let { step } = this.state;
                                                  if (step === 1 || step === 3) this.checkInput();
                                                  if (step === 2) this.setState({ step: 3, inputValue: {}, checkInput: {} });
                                                  if (step === 4) this.props.history.push("/dashboard");
                                             }}
                                        >
                                             {language === "th"
                                                  ? step < 3
                                                       ? "ถัดไป"
                                                       : step > 3
                                                       ? "เข้าร่วม"
                                                       : "เข้าสู่ระบบ"
                                                  : step < 3
                                                  ? "next"
                                                  : step > 3
                                                  ? "join"
                                                  : "login"}
                                        </Button>
                                   </Grid>
                              </Grid>
                         </Grid>
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(Register);

const LogoTitle = ({ language, step }) => {
     const classes = useStyles();
     if (step < 4) {
          return (
               <Grid mb={4}>
                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 4 }}>
                         <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={logo_web} />
                         <Typography className={classes.titleStyle}>ATS-forex</Typography>
                    </Grid>
                    <Typography className={classes.detailStyle}>{language === "th" ? "ลงทะเบียน" : "Register"}</Typography>
               </Grid>
          );
     } else {
          return (
               <Grid mb={4}>
                    <Typography className={classes.titleStyleStep}>Congratulatios!!!</Typography>
               </Grid>
          );
     }
};
const useStyles = makeStyles(() => ({
     imgStyle: {
          height: 50,
          objectFit: "contain",
          width: "auto",
          marginRight: 8,
          [theme.breakpoints.up("sm")]: {
               height: 80,
          },
     },
     titleStyle: {
          color: "#fff",
          fontSize: 26,
          fontWeight: 600,
          [theme.breakpoints.up("sm")]: {
               fontSize: 50,
          },
     },
     detailStyle: {
          color: "#fff",
          fontSize: 25,
          [theme.breakpoints.up("sm")]: {
               fontSize: 35,
          },
     },
     titleStyleStep: {
          color: "#fff",
          fontSize: 30,
          fontWeight: 600,
          [theme.breakpoints.up("sm")]: {
               fontSize: 60,
          },
     },
}));
