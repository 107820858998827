import { Card, CardContent, CardMedia, Grid, Typography, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import HeaderTypography from "../../../components/HeaderTypography/HeaderTypography";
import moment from "moment";
import { ip } from 'service'

const CardNews = (props) => {
     return props.data.map((el, i) => {
          return (
               <Grid item xs={12} md={4} sm={6}>
                    <Card key={i} sx={{ maxWidth: 345 }}>
                         <Typography position="absolute" variant="body2" sx={{ color: "white", backgroundColor: "red", padding: 1 }}>
                              ข่าวสาร
                         </Typography>
                         <CardMedia component="img" height="200" width="auto" image={ip + el.news_img} alt={el.news_name} />
                         <CardContent>
                              <HeaderTypography title={el.news_name} />
                              <Typography variant="body2" color="text.secondary">
                                   {el.news_detail}
                              </Typography>
                              <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
                                   ATS-Forex | {moment(el.create_date).format("YYYY-MM-DD")}
                              </Typography>
                         </CardContent>
                         <Grid container direction="row" justifyContent="flex-end">
                              <Button
                                   variant="contained"
                                   color="error"
                                   className="btn-style"
                                   sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                   onClick={()=>props.onClickEdit(el.news_id)}
                              >
                                   Edit
                              </Button>
                              <Button
                                   variant="outlined"
                                   color="error"
                                   className="btn-style"
                                   sx={{ maxWidth: 100, fontSize: 12, width: "100%", borderRadius: 40, height: 40, margin: 1 }}
                                   onClick={()=>props.onClickDelete(el.news_id)}
                                   // onClick={submitSearch}
                              >
                                   Delete
                              </Button>
                         </Grid>
                    </Card>
               </Grid>
          );
     });
};

export default withRouter(CardNews);
